/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `COMPLETED` - Completed
 * `FAILED` - Failed
 * `REFUNDED` - Refunded
 * `REQUEST_REFUND` - Request Refund
 * `UNKNOWN` - Unknown
 */
export type StatusDisplayEnum = (typeof StatusDisplayEnum)[keyof typeof StatusDisplayEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusDisplayEnum = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  REFUNDED: 'REFUNDED',
  REQUEST_REFUND: 'REQUEST_REFUND',
  UNKNOWN: 'UNKNOWN',
} as const;
