import { toQna } from 'entity-convert/entity/qna';
import { useQnasQnaList } from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';
import { QnasQnaListParams } from 'studyvibes-api/orval/models';

export const useGetQnaList = (params?: QnasQnaListParams) => {
  return useQnasQnaList(params, {
    query: {
      select: (data) => {
        const parsed = data.items.map(toQna);
        return parsed.map((qna) => ({
          title: qna.title,
          content: qna.content,
        }));
      },
    },
  });
};
