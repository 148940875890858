import { TutoringStyle as OrvalTutoringStyle } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const TutoringStyle = zod.object({
  content: zod.string(),
  title: zod.string(),
});

export type TutoringStyle = zod.infer<typeof TutoringStyle>;

export const toTutoringStyle = (tutoringStyle: OrvalTutoringStyle): TutoringStyle => {
  let result;
  try {
    result = TutoringStyle.parse({
      content: tutoringStyle.content,
      title: tutoringStyle.title,
    });
  } catch (e) {
    console.log('Error parsing tutoring style', e);
    throw e;
  }

  return result;
};
