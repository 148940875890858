/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `processing` - Processing
 * `accepted` - Accepted
 * `rejected` - Rejected
 * `canceled` - Canceled
 */
export type StatusCd0Enum = (typeof StatusCd0Enum)[keyof typeof StatusCd0Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusCd0Enum = {
  processing: 'processing',
  accepted: 'accepted',
  rejected: 'rejected',
  canceled: 'canceled',
} as const;
