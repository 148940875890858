/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type SubscriptionRequestCancelListRequestType =
  (typeof SubscriptionRequestCancelListRequestType)[keyof typeof SubscriptionRequestCancelListRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionRequestCancelListRequestType = {
  CANCEL: 'CANCEL',
  REFUND: 'REFUND',
} as const;
