/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `COACH` - Coach
 * `MASTER` - Master
 * `SPECIALIST` - Specialist
 */
export type TutorTypeEnum = (typeof TutorTypeEnum)[keyof typeof TutorTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutorTypeEnum = {
  COACH: 'COACH',
  MASTER: 'MASTER',
  SPECIALIST: 'SPECIALIST',
} as const;
