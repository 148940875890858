/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `Home` - home
 * `QuestionBank` - questionbank
 * `Tutoring` - tutoring
 */
export type CategoryEnum = (typeof CategoryEnum)[keyof typeof CategoryEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryEnum = {
  Home: 'Home',
  QuestionBank: 'QuestionBank',
  Tutoring: 'Tutoring',
} as const;
