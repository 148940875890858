/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `in progress` - In Progress
 * `achieved` - Achieved
 * `cancelled` - Cancelled
 * `on hold` - On hold
 */
export type Status4eeEnum = (typeof Status4eeEnum)[keyof typeof Status4eeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status4eeEnum = {
  in_progress: 'in progress',
  achieved: 'achieved',
  cancelled: 'cancelled',
  on_hold: 'on hold',
} as const;
