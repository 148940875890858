import {
  SubscriptionCancellationRequest as OrvalSubscriptionCancellationRequest,
  RequestType55dEnum,
  ServiceTypeEnum,
  Status715Enum,
} from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const SubscriptionCancellationRequest = zod.object({
  amount: zod.number().optional().nullable(),
  chargeId: zod.string().max(255).nullable().optional(),
  createdAt: zod.date().optional().nullable(),
  reason: zod.string(),
  reasonForRefund: zod.string().nullable().optional(),
  reasonForRejection: zod.string().nullable().optional(),
  requestType: zod.nativeEnum(RequestType55dEnum).optional(),
  serviceType: zod.nativeEnum(ServiceTypeEnum).optional(),
  status: zod.nativeEnum(Status715Enum).optional(),
  subscriptionId: zod.string().max(255).nullable().optional(),
  updatedAt: zod.date().optional().nullable(),
});

export type SubscriptionCancellationRequest = zod.infer<typeof SubscriptionCancellationRequest>;

export const toSubscriptionCancellationRequest = (
  product: OrvalSubscriptionCancellationRequest,
): SubscriptionCancellationRequest => {
  let result;
  try {
    result = SubscriptionCancellationRequest.parse({
      amount: product.amount ?? 0,
      chargeId: product.charge_id,
      createdAt: new Date(product.created_at),
      reason: product.reason,
      reasonForRefund: product.reason_for_refund,
      reasonForRejection: product.reason_for_rejection,
      requestType: product.request_type,
      serviceType: product.service_type,
      status: product.status,
      subscriptionId: product.subscription_id,
      updatedAt: product.updated_at && new Date(product.updated_at),
    });
  } catch (e) {
    console.log('Error parsing subscription cancellation request', e);
    throw e;
  }

  return result;
};

type SubscriptionCancellationRequestParams = Omit<
  OrvalSubscriptionCancellationRequest,
  'amount' | 'created_at' | 'updated_at'
>;

export const toSubscriptionCancellationRequestParams = (
  params: SubscriptionCancellationRequest,
): SubscriptionCancellationRequestParams => {
  const parsed = SubscriptionCancellationRequest.parse(params);
  return {
    charge_id: parsed.chargeId,
    reason: parsed.reason,
    reason_for_refund: parsed.reasonForRefund,
    reason_for_rejection: parsed.reasonForRejection,
    request_type: parsed.requestType,
    service_type: parsed.serviceType,
    status: parsed.status,
    subscription_id: parsed.subscriptionId,
  };
};
