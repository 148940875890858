import { ScheduleRead as OrvalScheduleRead } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const ScheduleRead = zod.object({
  endTime: zod.date(),
  id: zod.number(),
  name: zod.string(),
  notes: zod.string().optional().nullable(),
  startTime: zod.date(),
});

export type ScheduleRead = zod.infer<typeof ScheduleRead>;

export const toScheduleRead = (timeTracking: OrvalScheduleRead): ScheduleRead => {
  let result;
  try {
    result = ScheduleRead.parse({
      id: timeTracking.id,
      name: timeTracking.name,
      startTime: new Date(timeTracking.start_time),
      endTime: new Date(timeTracking.end_time),
      notes: timeTracking.notes,
    });
  } catch (e) {
    console.log('Error parsing schedule read', e);
    throw e;
  }

  return result;
};
