import {
  RecommendTutorsRead as OrvalRecommendTutorsRead,
  Status9a9Enum,
} from 'studyvibes-api/orval/models';
import { toTutorProfileRead, TutorProfileRead } from './tutor-profile-read';

import * as zod from 'zod';
import { CourseTutoringRead, toCourseTutoringRead } from './course-tutoring-read';

export const RecommendTutorsRead = zod.object({
  amountPerHour: zod.number().nullable(),
  course: CourseTutoringRead,
  createdAt: zod.date(),
  expiredAt: zod.date(),
  id: zod.number(),
  quantityForMonth: zod.number().nullable(),
  reasonForRejection: zod.string().nullable().optional(),
  request: zod.number(),
  startDate: zod.date(),
  status: zod.nativeEnum(Status9a9Enum).optional(),
  tutor: TutorProfileRead,
  updatedAt: zod.date(),
});

export type RecommendTutorsRead = zod.infer<typeof RecommendTutorsRead>;

export const toRecommendTutorsRead = (
  recommendTutor: OrvalRecommendTutorsRead,
): RecommendTutorsRead => {
  let result;
  try {
    result = RecommendTutorsRead.parse({
      amountPerHour: recommendTutor.amount_per_hour,
      course: toCourseTutoringRead(recommendTutor.course),
      createdAt: new Date(recommendTutor.created_at),
      expiredAt: new Date(recommendTutor.expired_at),
      id: recommendTutor.id,
      quantityForMonth: recommendTutor.quantity_for_month,
      reasonForRejection: recommendTutor.reason_for_rejection,
      request: Number(recommendTutor.request),
      startDate: new Date(recommendTutor.start_date),
      status: recommendTutor.status,
      tutor: toTutorProfileRead(recommendTutor.tutor),
      updatedAt: new Date(recommendTutor.updated_at),
    });
  } catch (e) {
    console.log('Error parsing recommend tutors read', e);
    throw e;
  }

  return result;
};
