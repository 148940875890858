/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `REFUND` - Refund
 * `CANCEL` - Cancel
 */
export type RequestType55dEnum = (typeof RequestType55dEnum)[keyof typeof RequestType55dEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestType55dEnum = {
  REFUND: 'REFUND',
  CANCEL: 'CANCEL',
} as const;
