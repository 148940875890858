import { SubscriptionQB as OrvalSubscriptionQB, Status95bEnum } from 'studyvibes-api/orval/models';
import { Course, toCourse } from './course';
import { PaymentMethod, toPaymentMethod } from './payment-method';
import { UserAuthDetail, toUserDetail } from './user-auth-detail';
import * as zod from 'zod';
import { SubscriptionProduct, toSubscriptionProduct } from './subscription-product';

export const SubscriptionQB = zod.object({
  cancelAtPeriodEnd: zod.boolean(),
  course: Course,
  createdAt: zod.date(),
  currentPeriodEnd: zod.date(),
  currentPeriodStart: zod.date(),
  id: zod.number(),
  paymentMethod: PaymentMethod.optional(),
  product: SubscriptionProduct.nullable().optional(),
  quantity: zod.number(),
  status: zod.nativeEnum(Status95bEnum),
  user: UserAuthDetail,
});

export type SubscriptionQB = zod.infer<typeof SubscriptionQB>;

export const toSubscriptionQB = (subscription: OrvalSubscriptionQB): SubscriptionQB => {
  let result;
  try {
    result = SubscriptionQB.parse({
      cancelAtPeriodEnd: subscription.cancel_at_period_end,
      course: toCourse(subscription.course),
      createdAt: new Date(subscription.created_at),
      currentPeriodEnd: new Date(subscription.current_period_end),
      currentPeriodStart: new Date(subscription.current_period_start),
      id: subscription.id,
      paymentMethod: subscription.payment_method
        ? toPaymentMethod(subscription.payment_method)
        : undefined,
      product: subscription?.product ? toSubscriptionProduct(subscription.product) : undefined,
      quantity: subscription.quantity,
      status: subscription.status,
      user: toUserDetail(subscription.user),
    });
  } catch (e) {
    console.log('Error parsing subscription question bank', e);
    throw e;
  }

  return result;
};
