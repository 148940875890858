import { Tutoring as OrvalTutoring, Status95bEnum } from 'studyvibes-api/orval/models';

import * as zod from 'zod';

export const Tutoring = zod.object({
  cancelAtPeriodEnd: zod.boolean().optional(),
  createdAt: zod.date(),
  deletedAt: zod.date().nullable().optional(),
  endDate: zod.date().nullable().optional(),
  expiredAt: zod.date().nullable().optional(),
  id: zod.number(),
  previousTutoring: zod.number().nullable().optional(),
  requestTutoring: zod.number(),
  sessionCount: zod.number().optional(),
  startDate: zod.date().nullable().optional(),
  status: zod.nativeEnum(Status95bEnum).optional(),
  totalSessions: zod.number().nullable().optional(),
  tutorRecommendation: zod.number().optional(),
  updated_at: zod.date().nullable().optional(),
});

export type Tutoring = zod.infer<typeof Tutoring>;

export const toTutoring = (tutoring: OrvalTutoring): Tutoring => {
  let result;
  try {
    result = Tutoring.parse({
      cancelAtPeriodEnd: tutoring.cancel_at_period_end,
      createdAt: new Date(tutoring.created_at),
      deletedAt: tutoring?.deleted_at && new Date(tutoring.deleted_at),
      endDate: tutoring.end_date && new Date(tutoring.end_date),
      expiredAt: tutoring.expired_at && new Date(tutoring.expired_at),
      id: tutoring.id,
      previousTutoring: tutoring.previous_tutoring,
      requestTutoring: tutoring.request_tutoring,
      sessionCount: tutoring.session_count,
      startDate: tutoring.start_date && new Date(tutoring.start_date),
      status: tutoring.status,
      totalSessions: tutoring.total_sessions,
      tutorRecommendation: tutoring.tutor_recommendation,
      updatedAt: tutoring.updated_at && new Date(tutoring.updated_at),
    });
  } catch (e) {
    console.log('Error parsing tutoring', e);
    throw e;
  }

  return result;
};
