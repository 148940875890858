import { Tutor as OrvalTutor, RoleEnum } from 'studyvibes-api/orval/models';

import * as zod from 'zod';

export const Tutor = zod.object({
  email: zod.string().optional(),
  firstName: zod.string().optional(),
  id: zod.number().readonly(),
  lastName: zod.string().optional(),
  role: zod.nativeEnum(RoleEnum).optional(),
});

export type Tutor = zod.infer<typeof Tutor>;

export const toTutor = (tutor: OrvalTutor): Tutor => {
  let result;
  try {
    result = Tutor.parse({
      email: tutor.email,
      firstName: tutor.first_name,
      id: tutor.id,
      lastName: tutor.last_name,
      role: tutor.role,
    });
  } catch (e) {
    console.log('Error parsing tutor', e);
    throw e;
  }

  return result;
};
