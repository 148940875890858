import { Course } from 'entity-convert/entity/course';

/**
 * @param price 상품의 기본 금액 (1회 결제 기준)
 * @param sale 할인율 (0~100의 숫자, 기본값: 0)
 * @param cycle 구독 결제 주기 (개월 수, 기본값: 1)
 *
 * @returns
 * - `totalPrice`: 할인 전 전체 결제 금액 (금액 * 주기)
 * - `salePrice`: 할인 적용 후 전체 결제 금액
 * - `monthlyPrice`: 할인 적용 후 월별 결제 금액 (salePrice / 주기)
 */
export const getSalePrice = (price: number, sale: number = 0, cycle: number = 1) => {
  const totalPrice = price * cycle;
  const salePrice = Math.floor(totalPrice * (1 - sale / 100));
  const monthlyPrice = Math.floor(salePrice / cycle);

  return {
    totalPrice,
    salePrice,
    monthlyPrice,
  };
};

export type CourseGroupedByName = Omit<Course, 'level'> & {
  level: { id: number; level: string }[];
};

/**
 * useGetCourseList의 select 함수에서 사용하기 위한 유틸리티 함수입니다.
 *
 * 주어진 Course 배열을 `name` 필드를 기준으로 그룹화하고,
 * 각 그룹의 `level` 정보를 배열로 묶어 반환합니다.
 *
 * 1. 동일한 `name`을 가진 Course들을 그룹화합니다.
 * 2. 그룹 내의 Course들의 `level` 정보를 `{ id: number, level: string }[]` 형태의 배열로 변환합니다.
 * 3. 그룹화된 데이터의 나머지 필드는 동일한 값을 유지합니다.
 * 4. 그룹 내에서 최신 업데이트(`updatedAt`) 날짜를 기준으로 갱신합니다.
 *
 * @param courseList useGetCourseList의 기본 반환값인 Course 배열
 * @returns Course 데이터를 `name`으로 그룹화하여 `CourseGroupedByName[]` 형태로 반환
 */
export const groupCoursesByName = (courseList: Course[]): CourseGroupedByName[] => {
  const grouped: { [key: string]: CourseGroupedByName } = {};

  courseList.forEach((course) => {
    if (!grouped[course.name]) {
      grouped[course.name] = {
        createdAt: course.createdAt,
        updatedAt: course.updatedAt,
        name: course.name,
        level: [],
        inService: course.inService,
        subjectId: course.subjectId,
      };
    }

    grouped[course.name].level.push({ id: course.id, level: course.level });

    if (new Date(course.updatedAt) > new Date(grouped[course.name].updatedAt)) {
      grouped[course.name].updatedAt = course.updatedAt;
    }
  });

  return Object.values(grouped);
};
