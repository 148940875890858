import { toCalendarEvent } from 'entity-convert/entity/calendar-event';
import { toScheduleCalendarEventListParams } from 'entity-convert/entity/schedule-calendar-event-list-params';
import { toTimeTrackingRead } from 'entity-convert/entity/time-tracking-read';
import {
  useScheduleCalendarEventList,
  useScheduleTimeTrackingList,
} from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';

/**
 *
 * @param date YYYY-MM-DD format
 * @returns
 */
export const useGetTimeTrackingList = ({ date }: { date?: string }) => {
  return useScheduleTimeTrackingList(
    { date },
    {
      query: {
        select: (data) => data.items.map(toTimeTrackingRead),
      },
    },
  );
};

export const useGetCalendarEventList = (params: { startDate?: Date; endDate?: Date } = {}) => {
  return useScheduleCalendarEventList(toScheduleCalendarEventListParams(params), {
    query: {
      select: (data) => data.map(toCalendarEvent),
    },
  });
};
