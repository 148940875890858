'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { QnasQnaListCategory } from 'studyvibes-api/orval/models';

import { REVIEW_DATA } from '@/constants';
import { useGetQnaList } from '@/operation/api/qnas/query';
import { useGetGroupedSubjectList } from '@/operation/api/question-bank/query';
import { paths } from '@/routes/paths';
import Accordion from '@/ui/component/accordion';
import Button from '@/ui/component/button';
import SVIcon from '@/ui/component/sv-icon';
import SVImage from '@/ui/component/sv-image';
import { B1, B2, B2b, H1b, H3b, H4, H4b, H5, H5b } from '@/ui/component/typography';
import PaymentCycle from '@/ui/container/payment-cycle';
import PaymentCycleSelectCourse from '@/ui/container/payment-cycle-select-course';
import Review from '@/ui/container/review';
import Timer from '@/ui/container/timer';
import { cnm } from '@/utils/cnm';

const DISCOUNT = {
  1: 0,
  3: 20,
  12: 60,
};

export default function Home() {
  const { data: groupedSubjectList = [] } = useGetGroupedSubjectList(
    { inService: true },
    (data) => data,
  );
  const { data: qnaList = [] } = useGetQnaList({ category: QnasQnaListCategory.Home });

  const firstGroupedSubject = groupedSubjectList?.[0];
  const firstCourseId = groupedSubjectList?.[0]?.courses?.[0]?.details?.[0]?.id;
  const [paymentCycle, setPaymentCycle] = useState({ cycle: 1, sale: 0 });

  const handlePaymentCycleChange = (e: React.FormEvent<HTMLFieldSetElement>) => {
    const target = e.target as HTMLInputElement;
    const newCycle = Number(target.value);
    setPaymentCycle({ cycle: newCycle, sale: DISCOUNT[newCycle] });
  };

  return (
    <div>
      <Container bg="bg-bg-white" className="h-[720px] relative">
        <div className="relative">
          <SVImage name="plus" size="xlg" className="absolute left-[-150px] top-[-100px]" />
          <SVImage name="calculator" size="xxlg" className="absolute right-[-130px] top-[-80px]" />
          <SVImage name="pencil" size="xlg" className="absolute right-[-200px] top-[-150px]" />
          <SVImage name="magnifier" size="xxxlg" className="absolute left-[-130px] top-[50px]" />
          <SVImage name="file" size="xlg" className="absolute left-[-260px] bottom-[-200px]" />
          <SVImage name="target" size="xxxlg" className="absolute right-[-220px] bottom-[-200px]" />
          <H1b> Your key to IB success</H1b>
          <H4b>
            Comprehensive Mathematics Questionbank & <br />
            Expert Tutor Matching Service
          </H4b>
        </div>
        <H4 className="text-content-inverseSecondary">
          Don{"'"}t waste your time! <br />
          Study smarter with StudyVibes
        </H4>
      </Container>

      <Container bg="bg-bg-quaternary">
        <H1b>Questionbank</H1b>
        <div>
          <H4b>Practice IB Exam Style Questions</H4b>
          <B1 className="text-content-inverseSecondary">
            Thousands of exam style questions, filtered by topic, sub-topic & difficulty. <br />
            Detailed and easy to understand mark-schemes and video solutions for all questions.{' '}
            <br />
            Practice & master IB exam style questions.
          </B1>
        </div>
        <div className="w-full flex flex-col gap-5">
          <H5b>Filter and view only the right questions for me.</H5b>
          <div className="w-full py-5 px-10 bg-bg-white shadow-xs">
            <Image
              src="/image/question-bank-control.png"
              width={1073}
              height={68}
              alt="question-bank-control"
              className="mx-auto object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <H5b>Easy to understand even without video solutions.</H5b>
          <Image src="/image/question.png" width={900} height={300} alt="question" />
        </div>
        <div className="flex flex-col items-center gap-5">
          <H5b>Manage your time effectively while solving problems.</H5b>
          <Timer />
        </div>
        <Link
          href={paths.questionBank.root({
            courseId: firstCourseId || 1,
          })}
          passHref
        >
          <Button
            variant="tertiary"
            size="xlg"
            shape="circle"
            trailingIcon={<SVIcon name="ArrowRightMOutlined" />}
          >
            Get started
          </Button>
        </Link>

        <div className="h-[80px]" />

        <div>
          <H3b>Become a StudyViber +</H3b>
          <H5 className="text-content-inverseSecondary">Experience Unlimited Questionbank.</H5>
        </div>
        <div className="flex flex-col gap-5">
          <H5b>1. Pricing</H5b>
          <fieldset
            onChange={handlePaymentCycleChange}
            className="grid grid-cols-3 gap-5 w-[900px]"
          >
            <PaymentCycle cycle={1} defaultChecked />
            <PaymentCycle cycle={3} sale={20} />
            <PaymentCycle cycle={12} sale={60} />
          </fieldset>
        </div>
        <div className="flex flex-col gap-5">
          <H5b>2. Select Course</H5b>
          <div className="flex gap-5">
            <PaymentCycleSelectCourse
              subjectName={firstGroupedSubject?.subjectName}
              courseList={firstGroupedSubject?.courses}
              {...paymentCycle}
              className="w-[360px] mx-auto"
            />
          </div>
        </div>
      </Container>

      <Container bg="bg-bg-secondary">
        <H1b>1:1 Tutoring</H1b>
        <H3b className="text-content-inverseSecondary">
          Tutors from the <br />
          <span className="text-content-inversePrimary">Top 15 universities</span> in the world
        </H3b>
        <Image src="/image/universities.png" width={900} height={300} alt="universities" />
        <B1 className="text-content-inverseSecondary">Invest now for the future.</B1>

        <div className="h-[80px]" />

        <div className="flex gap-10 text-left">
          <SVImage className="w-[360px] h-[240px]" name="goodnote" size="xxxxlg" />
          <div className="flex flex-col gap-10">
            <H3b>
              <div className="text-content-inverseSecondary">
                Access world-class education at home.
              </div>
              Connect with tutors from <br />
              your dream university.
            </H3b>
            <div>
              <B1 className="text-content-inverseSecondary">
                Bloom{"'"}s research demonstrated that students who <br />
                receive 1:1 tutoring outperform 98% of those <br />
                in traditional classroom settings.
              </B1>
              <div className="flex gap-2">
                <B2 className="text-content-inverseSecondary">source</B2>
                <Link
                  href="https://web.mit.edu/5.95/readings/bloom-two-sigma.pdf"
                  target="_blank"
                  className="flex gap-1"
                >
                  <B2b className="text-content-secondary">Bloom{"'"}s 2 sigma problem.</B2b>
                  <SVIcon name="LinkSOutlined" fill="content-secondary" />
                </Link>
              </div>
            </div>
            <Link href={paths.tutoring.root} passHref>
              <Button
                size="xlg"
                shape="circle"
                variant="tertiary"
                trailingIcon={<SVIcon name="ArrowRightLOutlined" />}
              >
                Get started
              </Button>
            </Link>
          </div>
        </div>

        <div className="h-[80px]" />

        <H3b>
          <div className="text-content-inverseSecondary">Success Stories</div>
          Grades go up
        </H3b>
        <div className="flex gap-10">
          {REVIEW_DATA.map((review, idx) => (
            <Review key={idx} {...review} />
          ))}
        </div>
        <B1 className="text-content-inverseSecondary">
          The fastest way to get to the Top universities in the world is <br />
          to take classes from a tutor from that university.
        </B1>
        <Link href={paths.tutoring.root} passHref>
          <Button
            size="xlg"
            shape="circle"
            variant="tertiary"
            trailingIcon={<SVIcon name="ArrowRightLOutlined" />}
          >
            Get started
          </Button>
        </Link>
      </Container>

      <Container bg="bg-bg-inverseTertiary">
        <H1b>Frequently asked questions</H1b>
        <Accordion type="single" list={qnaList} />
      </Container>
    </div>
  );
}

// -------------------------------------------------

interface ContainerProps {
  children: React.ReactNode;
  bg?: 'bg-bg-white' | 'bg-bg-secondary' | 'bg-bg-quaternary' | 'bg-bg-inverseTertiary';
  className?: string;
}

function Container({ children, bg = 'bg-bg-white', className }: ContainerProps) {
  return (
    <div
      className={cnm(
        'flex flex-col py-[80px] gap-10 items-center justify-center text-center',
        bg,
        className,
      )}
    >
      {children}
    </div>
  );
}
