import { TopicCountWithStatus as OrvalTopicCountWithStatus } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import { SubtopicCountWithStatus, toSubtopicCountWithStatus } from './subtopics-count-with-status';

export const TopicCountWithStatus = zod.object({
  id: zod.number(),
  name: zod.string(),
  totalQuestions: zod.number(),
  subtopics: zod.array(SubtopicCountWithStatus).optional(),
});

export type TopicCountWithStatus = zod.infer<typeof TopicCountWithStatus>;

export const toTopicCount = (topicCount: OrvalTopicCountWithStatus): TopicCountWithStatus => {
  let result;

  const subtopics = topicCount.subtopics?.map(toSubtopicCountWithStatus);

  try {
    result = TopicCountWithStatus.parse({
      id: topicCount.id,
      name: topicCount.name,
      totalQuestions: topicCount?.total_questions || 0,
      subtopics,
    });
  } catch (e) {
    console.log('Error parsing topic count with status', e);
    throw e;
  }

  return result;
};
