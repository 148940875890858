/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `PENDING` - Pending
 * `APPROVED` - Approved
 * `REJECTED` - Rejected
 * `REFUNDED` - Refunded
 * `CANCELLED` - Cancelled
 */
export type Status715Enum = (typeof Status715Enum)[keyof typeof Status715Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status715Enum = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REFUNDED: 'REFUNDED',
  CANCELLED: 'CANCELLED',
} as const;
