export const COUNTRY_LIST = [
  { value: 'KR', label: 'Korea' },
  { value: 'US', label: 'United States' },
  { value: 'CN', label: 'China' },
  { value: 'JP', label: 'Japan' },
  { value: 'RU', label: 'Russia' },
  { value: 'DE', label: 'Germany' },
  { value: 'FR', label: 'France' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IN', label: 'India' },
  { value: 'IT', label: 'Italy' },
  { value: 'CA', label: 'Canada' },
  { value: 'AU', label: 'Australia' },
  { value: 'ES', label: 'Spain' },
  { value: 'MX', label: 'Mexico' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'PL', label: 'Poland' },
  { value: 'AR', label: 'Argentina' },
  { value: 'BE', label: 'Belgium' },
  { value: 'NO', label: 'Norway' },
  { value: 'AT', label: 'Austria' },
  { value: 'IR', label: 'Iran' },
  { value: 'UA', label: 'Ukraine' },
];

export const Mathematics = {
  subjectId: 5,
};

// -------------------------------------------------

export const REVIEW_DATA = [
  {
    university: 'National University of Singapore',
    major: '(NUS)',
    profileImage: '/image/student-cho.png',
    firstName: 'Cho',
    lastName: '',
    information: [
      'Completed Course : IB MATH AA SL',
      'Exam Period: May 2024',
      'Final Grade: 7/7',
      'Enrolled University: National University of Singapore (NUS)',
    ],
    rating: 5,
    acceptanceReview: `My tutor's systematic approach to the final exam was invaluable. She created a detailed 3-month study plan that maximised my efficiency. She expertly addressed my weaknesses, identifying and clarifying areas where I struggled. This structured approach eliminated the recurring mistakes I often made previously.\n As the exam drew near, the summary notes she assigned as homework were a lifesaver. They helped me quickly review key concepts and identify areas where I needed further practice. On account of these, I achieved a 7 on the May 2024 exam, with a near-perfect score on Paper 1. This success led to acceptances from six top Korean universities, and I'm now at NUS.\n Her dedication and structured approach, coupled with an abundance of practice problems, guaranteed my success. I highly recommend this lesson to any student aiming for high scores.`,
    finalExamReport: '/image/final-exam-report-cho.png',
    studyNote: '/image/study-note-cho.png',
  },
  {
    university: 'University of Illinois Urbana-Champaign',
    major: '(UIUC)',
    profileImage: '/image/student-kim.png',
    firstName: 'Kim',
    lastName: '',
    information: [
      'Completed Course: IB MATH AA HL',
      'Exam period: May 2024',
      'Final Grade: 7/7',
      'Enrolled University: University of Illinois Urbana-Champaign (UIUC)',
    ],
    rating: 5,
    acceptanceReview: `Before tutoring, I consistently scored around a 5 on my math exams. However, after tutoring, I was able to significantly improve my grades and consistently score a 7 on school exams, and as well as my predicted grades and final exams.\n Not only did my overall score improve, but I also gained the confidence to complete my exams well ahead of time. I consistently had 15 minutes remaining at the end of each math paper, which allowed me to thoroughly review my answers and ensure accuracy. I attribute this success to my tutor's comprehensive teaching methods and personalised guidance.\n I was particularly impressed by my tutor's ability to break down complex concepts into simpler terms. By encouraging me to solve problems independently, I could build a strong foundation and the confidence to tackle challenging problems.`,
    finalExamReport: '/image/final-exam-report-kim.png',
  },
  {
    university: 'N/A',
    major: '(N/A)',
    profileImage: '/image/student-park.png',
    firstName: 'Park',
    lastName: '',
    information: [
      'Completed Course: IB MATH AA HL',
      'Exam period: Nov 2024',
      'Final Grade: 7/7',
      'Enrolled University: N/A',
    ],
    rating: 5,
    acceptanceReview: `Before I started tutoring, I was getting around 5 to 6 on my school math exams. I started tutoring in the winter break before my DP2 year and continued until the final exams. Thanks to my tutor, I was able to significantly improve my academic standing, rising from 13th to 5th in my class, and ultimately achieving a 7 on my final exam.\n Despite my limited study time, she helped me maximise my efficiency. She provided me with a structured study plan and assigned plenty of homework to reinforce the concepts we learned in class. Her commitment to ensuring I completed all assignments helped a lot as well.`,
    finalExamReport: '/image/final-exam-report-park.png',
  },
];
