import { SubtopicCountWithStatus as OrvalSubtopicCountWithStatus } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import {
  QuestionAttemptStatusCounts,
  toQuestionAttemptStatusCounts,
} from './question-attempt-status-counts';

export const SubtopicCountWithStatus = zod.object({
  id: zod.number(),
  name: zod.string(),
  order: zod.number(),
  totalQuestions: zod.number(),
  statusCounts: QuestionAttemptStatusCounts.optional(),
});

export type SubtopicCountWithStatus = zod.infer<typeof SubtopicCountWithStatus>;

export const toSubtopicCountWithStatus = (
  subtopicCount: OrvalSubtopicCountWithStatus,
): SubtopicCountWithStatus => {
  let result;

  const statusCounts = toQuestionAttemptStatusCounts(subtopicCount.status_counts);

  try {
    result = SubtopicCountWithStatus.parse({
      id: subtopicCount.id,
      name: subtopicCount.name,
      order: subtopicCount.order,
      totalQuestions: subtopicCount?.total_questions,
      statusCounts,
    });
  } catch (e) {
    console.log('Error parsing subtopic count with status', e);
    throw e;
  }

  return result;
};
