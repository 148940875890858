import { TimeTrackingWrite as OrvalTimeTrackingWrite } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const TimeTrackingWrite = zod.object({
  courseId: zod.number().optional().nullable(),
  endTime: zod.date(),
  notes: zod.string().optional().nullable(),
  startTime: zod.date(),
});

export type TimeTrackingWrite = zod.infer<typeof TimeTrackingWrite>;

export const toTimeTrackingWrite = (timeTracking: OrvalTimeTrackingWrite): TimeTrackingWrite => {
  let result;
  try {
    result = TimeTrackingWrite.parse({
      courseId: timeTracking.course,
      startTime: new Date(timeTracking.start_time),
      endTime: new Date(timeTracking.end_time),
      notes: timeTracking.notes,
    });
  } catch (e) {
    console.log('Error parsing time tracking write', e);
    throw e;
  }

  return result;
};

export const toTimeTrackingWriteParams = (
  timeTracking: TimeTrackingWrite,
): OrvalTimeTrackingWrite => {
  let parsed = TimeTrackingWrite.parse(timeTracking);

  return {
    course: parsed.courseId,
    start_time: parsed.startTime.toISOString(),
    end_time: parsed.endTime.toISOString(),
    notes: parsed.notes,
  };
};
