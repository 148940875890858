/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type QnasQnaListCategory = (typeof QnasQnaListCategory)[keyof typeof QnasQnaListCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QnasQnaListCategory = {
  Home: 'Home',
  QuestionBank: 'QuestionBank',
  Tutoring: 'Tutoring',
} as const;
