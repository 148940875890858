import { ScheduleCalendarEventListParams as OrvalScheduleCalendarEventListParams } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const ScheduleCalendarEventListParams = zod.object({
  endDate: zod.date().optional(),
  startDate: zod.date().optional(),
});

export type ScheduleCalendarEventListParams = zod.infer<typeof ScheduleCalendarEventListParams>;

export const toScheduleCalendarEventListParams = (
  params: ScheduleCalendarEventListParams,
): OrvalScheduleCalendarEventListParams => {
  let calendarEventParams = ScheduleCalendarEventListParams.parse(params);

  return {
    end_date: calendarEventParams?.endDate?.toISOString(),
    start_date: calendarEventParams?.startDate?.toISOString(),
  };
};
