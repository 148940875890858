import { Qna as OrvalQna, CategoryEnum } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const Qna = zod.object({
  category: zod.nativeEnum(CategoryEnum).optional(),
  content: zod.string(),
  createdAt: zod.date(),
  id: zod.number(),
  title: zod.string().max(255),
  updatedAt: zod.date(),
});

export type Qna = zod.infer<typeof Qna>;

export const toQna = (qna: OrvalQna): Qna => {
  let result;
  try {
    result = Qna.parse({
      category: qna.category,
      content: qna.content,
      createdAt: new Date(qna.created_at),
      id: qna.id,
      title: qna.title,
      updatedAt: new Date(qna.updated_at),
    });
  } catch (e) {
    console.log('Error parsing qna', e);
    throw e;
  }

  return result;
};
