/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `COMPLETED` - Completed
 * `NO_SHOW` - No show
 */
export type TutoringCommentStatusEnum =
  (typeof TutoringCommentStatusEnum)[keyof typeof TutoringCommentStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutoringCommentStatusEnum = {
  COMPLETED: 'COMPLETED',
  NO_SHOW: 'NO_SHOW',
} as const;
