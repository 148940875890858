'use client';

import { GroupedCourse } from 'entity-convert/entity/grouped-course';

import Checkbox from '@/ui/component/checkbox';
import CheckboxGroup from '@/ui/component/checkbox/checkbox-group';
import Tag from '@/ui/component/tag';
import { B1, B2, H5, H5b } from '@/ui/component/typography';
import { COURSE_PRICE } from '@/ui/constant';
import { cnm } from '@/utils/cnm';

import { useSelectedCourse } from './hooks';
import { getSalePrice } from './util';

interface PaymentCycleSelectCourseProps {
  subjectName: string;
  cycle?: number;
  sale?: number;
  className?: string;
  courseList?: GroupedCourse[];
}

export default function PaymentCycleSelectCourse({
  subjectName,
  cycle,
  sale,
  courseList,
  className,
}: PaymentCycleSelectCourseProps) {
  const { selectedCourseIds, onSelectCourse } = useSelectedCourse({
    courseList,
    subjectName,
    sale,
    cycle,
  });

  return (
    <>
      {courseList?.map(({ courseName, details }) => {
        const { totalPrice, salePrice, monthlyPrice } = getSalePrice(COURSE_PRICE, sale, cycle);
        return (
          <div
            key={courseName}
            className={cnm(
              'p-5 bg-bg-white rounded-m outline-solid text-left',
              'group has-[:checked]:!outline has-[:checked]:outline-border-inversePrimary',
              className,
            )}
          >
            <div className="flex flex-col gap-2">
              {Boolean(sale) && (
                <div className="flex justify-between">
                  <Tag color="orange" size="lg" shape="round">
                    SAVE {sale}%
                  </Tag>
                  <B2 className="text-content-inverseTertiary">${monthlyPrice}per 1Month</B2>
                </div>
              )}
              <H5b className="text-content-inversePrimary">{courseName}</H5b>
            </div>

            <div className="h-6 w-full" />

            <div className="flex gap-2">
              <H5b className="text-content-inversePrimary">$ {salePrice} USD</H5b>
              {Boolean(sale) && (
                <H5 className="text-content-inverseTertiary line-through">$ {totalPrice} USD</H5>
              )}
            </div>
            <B1 className="text-content-inverseSecondary">
              Billed each {cycle === 12 ? 'year' : `${cycle}month`}
            </B1>

            <div className="h-4 w-full" />

            <div className="flex flex-col gap-2">
              <B1 className="text-content-inverseSecondary">Select Course</B1>
              <CheckboxGroup
                values={selectedCourseIds}
                onChange={onSelectCourse}
                name={courseName}
                className="flex flex-col gap-2"
              >
                {details?.map((course) => (
                  <Checkbox
                    value={String(course?.id)}
                    name={courseName}
                    key={course?.id}
                    size="m"
                    text={course?.level}
                    outline
                    className="w-full"
                  />
                ))}
              </CheckboxGroup>
            </div>
          </div>
        );
      })}
    </>
  );
}
