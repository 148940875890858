/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `pending` - Pending
 * `in progress` - In Progress
 * `rejected` - Rejected
 * `completed` - Completed
 * `cancelled` - Cancelled
 */
export type StatusDeaEnum = (typeof StatusDeaEnum)[keyof typeof StatusDeaEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusDeaEnum = {
  pending: 'pending',
  in_progress: 'in progress',
  rejected: 'rejected',
  completed: 'completed',
  cancelled: 'cancelled',
} as const;
