import { PatchedScheduleWrite as OrvalPatchedScheduleWrite } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const PatchedScheduleWrite = zod.object({
  endTime: zod.date().optional(),
  name: zod.string().max(255).optional(),
  notes: zod.string().optional().nullable(),
  startTime: zod.date().optional(),
});

export type PatchedScheduleWrite = zod.infer<typeof PatchedScheduleWrite>;

export const toPatchedScheduleWrite = (
  timeTracking: OrvalPatchedScheduleWrite,
): PatchedScheduleWrite => {
  let result;
  try {
    result = PatchedScheduleWrite.parse({
      endTime: timeTracking?.end_time && new Date(timeTracking.end_time),
      name: timeTracking.name,
      notes: timeTracking.notes,
      startTime: timeTracking?.start_time && new Date(timeTracking.start_time),
    });
  } catch (e) {
    console.log('Error parsing patched schedule write', e);
    throw e;
  }

  return result;
};

export const toPatchedScheduleWriteParams = (
  timeTracking: PatchedScheduleWrite,
): OrvalPatchedScheduleWrite => {
  let parsed = PatchedScheduleWrite.parse(timeTracking);

  return {
    end_time: parsed?.endTime?.toISOString(),
    name: parsed.name,
    notes: parsed.notes,
    start_time: parsed?.startTime?.toISOString(),
  };
};
