import axios from 'axios';
import ENV from './env.mjs';

export const axiosInstance = axios.create({
  baseURL: ENV.BASE_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

// ------------------------------------------------------------

const refreshAccessToken = async () => {
  if (typeof window === 'undefined') throw new Error('No window object found');

  const auth = localStorage.getItem('auth');
  if (auth) {
    const { refresh } = JSON.parse(auth);
    try {
      const auth = await axios.post('/account/token/refresh', { refresh });
      localStorage.setItem('auth', JSON.stringify(auth.data));
      return auth.data?.access;
    } catch (error) {
      localStorage.removeItem('auth');
      throw error;
    }
  }
  throw new Error('No refresh token found');
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      if (typeof window !== 'undefined') {
        try {
          const newAccessToken = await refreshAccessToken();
          if (newAccessToken) {
            error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return axiosInstance.request(error.config);
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          localStorage.removeItem('auth');
          // window.location.reload();
          throw refreshError;
        }
      }
    }
    return Promise.reject(error);
  },
);
