/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type UsersTutorProfileListTutorType =
  (typeof UsersTutorProfileListTutorType)[keyof typeof UsersTutorProfileListTutorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersTutorProfileListTutorType = {
  COACH: 'COACH',
  MASTER: 'MASTER',
  SPECIALIST: 'SPECIALIST',
} as const;
