/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `new_session` - New session
 * `change_session` - Change session
 * `cancel_session` - Cancel session
 */
export type RequestTypeEnum = (typeof RequestTypeEnum)[keyof typeof RequestTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestTypeEnum = {
  new_session: 'new_session',
  change_session: 'change_session',
  cancel_session: 'cancel_session',
} as const;
