import Link from 'next/link';

import { paths } from '@/routes/paths';
import Button from '@/ui/component/button';
import SVImage from '@/ui/component/sv-image';
import { B1b, H5b } from '@/ui/component/typography';

interface UnlimitedStudyVibesProps {
  onClose?: () => void;
}

export default function UnlimitedStudyVibes({ onClose }: UnlimitedStudyVibesProps) {
  return (
    <div className="flex flex-col gap-10 items-center">
      <div className="flex flex-col gap-[10px] items-center">
        <SVImage name="graduateBooks" size="xlg" alt="Unlimited Question Bank" />
        <H5b>
          If you become a paid member,
          <br />
          you can use all the features!
        </H5b>
        <div>
          <B1b className="text-content-inverseSecondary list-disc" as="li">
            Flashcards
          </B1b>
          <B1b className="text-content-inverseSecondary list-disc" as="li">
            Track your studytime
          </B1b>
          <B1b className="text-content-inverseSecondary list-disc" as="li">
            Calender
          </B1b>
        </div>
      </div>
      <div className="flex flex-col gap-3 items-center">
        <Link href={paths.signUp.root} passHref>
          <Button onClick={onClose} variant="primary" size="lg" shape="circle">
            Become a StudyViber +
          </Button>
        </Link>
        <Link href={paths.tutoring.root} passHref>
          <Button onClick={onClose} variant="primary" size="lg" shape="circle">
            Become a Tutoring
          </Button>
        </Link>
      </div>
    </div>
  );
}
