/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `QUESTION_BANK` - question_bank
 * `TUTORING` - tutoring
 */
export type ServiceTypeEnum = (typeof ServiceTypeEnum)[keyof typeof ServiceTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceTypeEnum = {
  QUESTION_BANK: 'QUESTION_BANK',
  TUTORING: 'TUTORING',
} as const;
