/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `in progress` - In Progress
 * `achieved` - Achieved
 * `cancelled` - Cancelled
 * `on hold` - On hold
 */
export type TutoringStatusEnum = (typeof TutoringStatusEnum)[keyof typeof TutoringStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutoringStatusEnum = {
  in_progress: 'in progress',
  achieved: 'achieved',
  cancelled: 'cancelled',
  on_hold: 'on hold',
} as const;
