/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `new_session` - New session
 * `change_session` - Change session
 * `cancel_session` - Cancel session
 */
export type RequestType124Enum = (typeof RequestType124Enum)[keyof typeof RequestType124Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestType124Enum = {
  new_session: 'new_session',
  change_session: 'change_session',
  cancel_session: 'cancel_session',
} as const;
