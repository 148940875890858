/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `incomplete` - Incomplete
 * `incomplete_expired` - Incomplete Expired
 * `trailing` - Trailing
 * `active` - Active
 * `past_due` - Past Due
 * `canceled` - Canceled
 * `unpaid` - Unpaid
 * `paused` - Paused
 */
export type Status95bEnum = (typeof Status95bEnum)[keyof typeof Status95bEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status95bEnum = {
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  trailing: 'trailing',
  active: 'active',
  past_due: 'past_due',
  canceled: 'canceled',
  unpaid: 'unpaid',
  paused: 'paused',
} as const;
