/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `Recommended` - recommended
 * `Selected` - selected
 * `Declined` - declined
 * `Rejected` - rejected
 * `Coordinated` - coordinated
 * `Pending` - pending
 * `Confirmed` - confirmed
 * `Expired` - expired
 */
export type Status9a9Enum = (typeof Status9a9Enum)[keyof typeof Status9a9Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status9a9Enum = {
  Recommended: 'Recommended',
  Selected: 'Selected',
  Declined: 'Declined',
  Rejected: 'Rejected',
  Coordinated: 'Coordinated',
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Expired: 'Expired',
} as const;
