import { CourseTutoring as OrvalCourseTutoring, LevelEnum } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const CourseTutoring = zod.object({
  id: zod.number(),
  createdAt: zod.date(),
  level: zod.nativeEnum(LevelEnum),
  name: zod.string().max(255),
  subject: zod.string().optional(),
  updatedAt: zod.date(),
});

export type CourseTutoring = zod.infer<typeof CourseTutoring>;

export const toCourseTutoring = (course: OrvalCourseTutoring): CourseTutoring => {
  let result;
  try {
    result = CourseTutoring.parse({
      id: course.id,
      name: course.name,
      level: course.level,
      subject: course.subject,
      createdAt: new Date(course.created_at),
      updatedAt: new Date(course.updated_at),
    });
  } catch (e) {
    console.log('Error parsing course tutoring', e);
    throw e;
  }

  return result;
};
