import { TimeTrackingRead as OrvalTimeTrackingRead } from 'studyvibes-api/orval/models';
import { CourseTutoring, toCourseTutoring } from './course-tutoring';
import * as zod from 'zod';

export const TimeTrackingRead = zod.object({
  course: CourseTutoring.optional().nullable(),
  endTime: zod.date(),
  id: zod.number(),
  notes: zod.string().optional().nullable(),
  startTime: zod.date(),
});

export type TimeTrackingRead = zod.infer<typeof TimeTrackingRead>;

export const toTimeTrackingRead = (timeTracking: OrvalTimeTrackingRead): TimeTrackingRead => {
  let result;
  try {
    result = TimeTrackingRead.parse({
      id: timeTracking.id,
      course: timeTracking?.course && toCourseTutoring(timeTracking.course),
      startTime: new Date(timeTracking.start_time),
      endTime: new Date(timeTracking.end_time),
      notes: timeTracking.notes,
    });
  } catch (e) {
    console.log('Error parsing time tracking read', e);
    throw e;
  }

  return result;
};
