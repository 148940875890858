/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `TUTOR` - Tutor
 * `STAFF` - Staff
 * `STUDENT` - Student
 */
export type UserAuthDetailRoleEnum =
  (typeof UserAuthDetailRoleEnum)[keyof typeof UserAuthDetailRoleEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAuthDetailRoleEnum = {
  TUTOR: 'TUTOR',
  STAFF: 'STAFF',
  STUDENT: 'STUDENT',
} as const;
