import { CourseTutoringRead as OrvalCourseTutoringRead } from 'studyvibes-api/orval/models';
import * as zod from 'zod';
import { CourseSubject, toCourseSubject } from './course-subject';

export const CourseTutoringRead = zod.object({
  id: zod.number(),
  level: zod.string(),
  name: zod.string(),
  subject: CourseSubject,
});

export type CourseTutoringRead = zod.infer<typeof CourseTutoringRead>;

export const toCourseTutoringRead = (course: OrvalCourseTutoringRead): CourseTutoringRead => {
  let result;
  try {
    result = CourseTutoringRead.parse({
      id: course.id,
      name: course.name,
      level: course.level,
      subject: toCourseSubject(course.subject),
    });
  } catch (e) {
    console.log('Error parsing course tutoring read', e);
    throw e;
  }

  return result;
};
