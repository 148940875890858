import { GroupedCourse } from 'entity-convert/entity/grouped-course';

import { usePaymentQuestionBankStore } from '@/store/payment/use-payment-question-bank-store';
import { COURSE_PRICE } from '@/ui/constant';

import { getSalePrice } from './util';

export const useSelectedCourse = ({
  courseList,
  subjectName,
  sale = 0,
  cycle = 1,
}: {
  subjectName: string;
  courseList: GroupedCourse[];
  sale?: number;
  cycle?: number;
}) => {
  const { selectedCourseList = [], setSelectedCourseList } = usePaymentQuestionBankStore();
  const selectedCourseIds = selectedCourseList?.map(({ course }) => String(course?.id));

  const onSelectCourse = (courseIds: string[]) => {
    const courseId = courseIds.pop();

    let selectedCourse: { id: number; name: string; level: string; price: number } | null = null;

    courseList.forEach((groupedCourse) => {
      groupedCourse.details.forEach((course) => {
        if (course.id === Number(courseId)) {
          const { salePrice } = getSalePrice(COURSE_PRICE, sale, cycle);
          selectedCourse = {
            id: course.id,
            name: groupedCourse.courseName,
            level: course.level,
            price: salePrice,
          };
        }
      });
    });

    setSelectedCourseList([
      {
        subjectName,
        course: selectedCourse,
        cycle,
      },
    ]);
  };

  return { selectedCourseIds, onSelectCourse };
};
