import { cva } from 'class-variance-authority';

export const textAreaStyle = cva(
  [
    'placeholder-content-inverseSecondary grow resize-none hide-scrollbar w-fit px-[14px] py-[10px] bg-bg-white border-solid !border-s border-border-inverseTertiary rounded-s !text-b1 text-content-inverseSecondary',
    'hover:text-content-inversePrimary',
    'focus:outline-none focus:text-content-inversePrimary focus:!border-border-primary focus:shadow-[0_0_0_4px_#FFEAD5]',
    'disabled:text-content-inverseSecondary disabled:bg-bg-inverseTertiary disabled:border-border-inverseTertiary',
  ],
  {
    variants: {
      error: {
        true: ['border-border-error', 'focus:shadow-[0_0_0_4px_#FEE4E2]'],
      },
    },
  },
);
