import Image from 'next/image';

import Button from '@/ui/component/button';
import { Modal, ModalProvider, ModalTrigger } from '@/ui/component/modal';
import SVImage from '@/ui/component/sv-image';
import { B1, H4b, H5b } from '@/ui/component/typography';
import { cnm } from '@/utils/cnm';

interface ReviewProps {
  className?: string;
  university?: string;
  major?: string;
  profileImage?: string;
  firstName?: string;
  lastName?: string;
  information?: string[];
  rating?: number;
  acceptanceReview?: string;
  finalExamReport?: string;
  studyNote?: string;
}

export default function Review({
  university,
  major,
  profileImage,
  firstName,
  lastName,
  information,
  rating = 5,
  finalExamReport,
  acceptanceReview,
  studyNote,
  className,
}: ReviewProps) {
  return (
    <div
      className={cnm(
        `w-[400px] shadow-s flex flex-col relative rounded-lg overflow-hidden`,
        className,
      )}
    >
      <div className="relative w-full bg-red-600 flex flex-col items-center justify-start pt-10 pb-[72px] text-center">
        <div className="flex flex-col items-center justify-start z-1 text-content-white">
          <H5b>{university}</H5b>
          <div>{major}</div>
        </div>
        <Image
          className="absolute rounded-full object-cover h-[80px] w-[80px] bottom-0 translate-y-1/2 z-20"
          width={80}
          height={80}
          alt="profile-image"
          src={profileImage}
        />
      </div>
      <div className="grow rounded-b-5 bg-bg-white flex flex-col items-center justify-center p-4 pt-[48px] gap-8">
        <div>
          <H4b>
            {firstName}
            {lastName}
          </H4b>
          <div className="flex gap-1 mx-auto">
            {Array.from({ length: rating }).map((_, i) => (
              <SVImage name="star" size="xs" key={i} />
            ))}
          </div>
        </div>
        <ul className="*:list-disc *:list-inside *:text-content-inverseSecondary">
          {information?.map((info, idx) => (
            <B1 as="li" className="pl-6 -indent-5 whitespace-pre-wrap text-left" key={idx}>
              {info}
            </B1>
          ))}
        </ul>

        <div className="flex flex-col gap-3 w-full mt-auto">
          <div className="grid grid-cols-2 gap-3">
            <ReviewModal review={acceptanceReview}>
              <Button className="w-full" variant="primary-light" size="m">
                Acceptance Review
              </Button>
            </ReviewModal>
            {studyNote ? (
              <ReviewModal imageUrl={studyNote}>
                <Button className="w-full" variant="primary-light" size="m">
                  Study Note
                </Button>
              </ReviewModal>
            ) : (
              <Button className="w-full" variant="primary-light" size="m">
                Study Note
              </Button>
            )}
          </div>
          <ReviewModal imageUrl={finalExamReport}>
            <Button variant="primary" size="m" className="w-full">
              Final Exam Report
            </Button>
          </ReviewModal>
        </div>
      </div>
    </div>
  );
}

// --------------------------------------------------

interface ReviewModalProps {
  children: React.ReactNode;
  imageUrl?: string;
  review?: string;
}

function ReviewModal({ children, imageUrl, review }: ReviewModalProps) {
  return (
    <ModalProvider>
      <ModalTrigger asChild>{children}</ModalTrigger>
      <Modal closeButton>
        {imageUrl && <img className="object-cover" src={imageUrl} />}
        {review && <B1 className="whitespace-pre-wrap">{review}</B1>}
      </Modal>
    </ModalProvider>
  );
}
