import {
  ChargeHistoryTutoringRead as OrvalChargeHistoryTutoringRead,
  Status0f5Enum,
  StatusDisplayEnum,
} from 'studyvibes-api/orval/models';
import { RecommendTutorsRead, toRecommendTutorsRead } from './recommend-tutors-read';
import { PaymentMethod, toPaymentMethod } from './payment-method';
import { toUserDetail, UserAuthDetail } from './user-auth-detail';
import { SubscriptionProduct, toSubscriptionProduct } from './subscription-product';
import * as zod from 'zod';

export const ChargeHistoryTutoringRead = zod.object({
  chargeId: zod.string(),
  updatedAt: zod.date(),
  currency: zod.string(),
  eventType: zod.string(),
  id: zod.number(),
  paymentIntentId: zod.string(),
  paymentMethod: PaymentMethod.optional(),
  quantity: SubscriptionProduct.optional(),
  receiptUrl: zod.string(),
  recommend: RecommendTutorsRead.optional(),
  status: zod.nativeEnum(Status0f5Enum).optional(),
  statusDisplay: zod.nativeEnum(StatusDisplayEnum),
  totalPrice: zod.number(),
  user: UserAuthDetail,
});

export type ChargeHistoryTutoringRead = zod.infer<typeof ChargeHistoryTutoringRead>;

export const toChargeHistoryTutoringRead = (
  tutoring: OrvalChargeHistoryTutoringRead,
): ChargeHistoryTutoringRead => {
  let result;
  try {
    result = ChargeHistoryTutoringRead.parse({
      chargeId: tutoring.charge_id,
      updatedAt: new Date(tutoring.updated_at),
      currency: tutoring.currency,
      eventType: tutoring.event_type,
      id: tutoring.id,
      paymentIntentId: tutoring.payment_intent_id,
      paymentMethod: tutoring.payment_method ? toPaymentMethod(tutoring.payment_method) : undefined,
      quantity: tutoring.quantity ? toSubscriptionProduct(tutoring.quantity) : undefined,
      receiptUrl: tutoring.receipt_url,
      recommend: tutoring.recommend ? toRecommendTutorsRead(tutoring.recommend) : undefined,
      status: tutoring.status,
      statusDisplay: tutoring.status_display,
      totalPrice: tutoring.total_price,
      user: toUserDetail(tutoring.user),
    });
  } catch (e) {
    console.log('Error parsing ChargeHistoryTutoringRead', e);
    throw e;
  }

  return result;
};
