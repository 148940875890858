'use client';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import {
  getScheduleCalendarEventListQueryOptions,
  getScheduleTimeTrackingListQueryOptions,
} from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';

import { usePostTimeTracking } from '@/operation/api/schedule/mutate';
import Button from '@/ui/component/button';
import TextArea from '@/ui/component/textarea';
import { B1sb } from '@/ui/component/typography';
import { cnm } from '@/utils/cnm';

interface ManualTimeTrackingFormProps {
  disabled?: boolean;
}

export default function ManualTimeTrackingForm({ disabled }: ManualTimeTrackingFormProps) {
  const queryClient = useQueryClient();
  const { mutateAsync: postTimeTracking } = usePostTimeTracking();

  const [startTime, setStart] = useState<Date | undefined>();
  const [endTime, setEnd] = useState<Date | undefined>();
  const [notes, setNotes] = useState('');
  const [isPending, setIsPending] = useState(false);

  const onPostTimeTrack = async () => {
    try {
      if (dayjs(startTime).isAfter(dayjs(endTime))) {
        throw new Error('End time should be after start time');
      }
      setIsPending(true);
      await postTimeTracking({
        startTime,
        endTime,
        notes,
      });
      setStart(undefined);
      setEnd(undefined);
      setNotes('');
      await queryClient.invalidateQueries(getScheduleTimeTrackingListQueryOptions());
      await queryClient.invalidateQueries(getScheduleCalendarEventListQueryOptions());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      toast.error(e.message || 'Failed to save time tracking');
      console.error(e);
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    if (disabled) {
      setStart(undefined);
      setEnd(undefined);
      setNotes('');
    }
  }, [disabled]);

  return (
    <div className="p-3 flex flex-col gap-[10px] border-solid border-s rounded-s border-border-inverseTertiary">
      <B1sb className="text-content-inverseSecondary">From</B1sb>
      <div className="flex">
        {/* @ts-ignore: 이 라이브러리의 타입 선언이 엉켜 있어서 임시로 무시합니다. */}
        <DatePicker
          dateFormat="MMM dd, YYYY   aa hh:mm"
          showTimeSelect
          timeIntervals={30}
          selected={startTime}
          /* @ts-ignore next line: 이 라이브러리의 타입 선언이 엉켜 있어서 임시로 무시합니다. */
          onChange={setStart}
          placeholderText="Select tutoring date"
          className={cnm('w-full', inputClassName?.default, disabled && inputClassName?.disabled)}
          wrapperClassName="grow"
          disabled={disabled}
        />
      </div>
      <B1sb className="text-content-inverseSecondary">To</B1sb>
      <div className="flex">
        {/* @ts-ignore next line: 이 라이브러리의 타입 선언이 엉켜 있어서 임시로 무시합니다. */}
        <DatePicker
          dateFormat="MMM dd, YYYY   aa hh:mm"
          showTimeSelect
          timeIntervals={30}
          selected={endTime}
          /* @ts-ignore next line: 이 라이브러리의 타입 선언이 엉켜 있어서 임시로 무시합니다. */
          onChange={setEnd}
          placeholderText="Select tutoring date"
          className={cnm('w-full', inputClassName?.default, disabled && inputClassName?.disabled)}
          wrapperClassName="grow"
          disabled={disabled}
          minDate={startTime}
        />
      </div>
      <TextArea
        label="Notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        className="w-full"
        placeholder="Enter a description..."
        disabled={disabled}
      />
      <Button
        onClick={onPostTimeTrack}
        disabled={disabled || isPending}
        variant="primary"
        size="s"
        className="self-end"
      >
        Save
      </Button>
    </div>
  );
}

//--------------------------------------------------------------------

const inputClassName = {
  default:
    'cursor-pointer block grow px-[14px] py-[11px] border-solid border-s border-border-inverseTertiary rounded-s text-content-inverseSecondary',
  disabled:
    'cursor-default text-content-inverseSecondary bg-bg-inverseTertiary border-border-inverseTertiary',
};
