'use client';
import { InputHTMLAttributes, useEffect, useRef } from 'react';

import { cnm } from '@/utils/cnm';

import SVIcon from '../sv-icon';
import TextField from '../textfield';
import { B1b } from '../typography';
import { useSearchControl } from './hooks';

interface SearchProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  options: { value: string; label?: React.ReactNode }[];
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
}

const OPTION_HEIGHT = 44; // 각 항목의 높이

export default function SearchCourse({
  placeholder,
  options,
  value,
  onChange,
  className,
  disabled,
  ...props
}: SearchProps) {
  const { inputValue, open, onSelectOption, onChangeInput } = useSearchControl({ value, onChange });
  const optionRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (open && optionRef.current) {
      const hasInputValue = inputValue.length - 1 >= 0;

      if (!hasInputValue) {
        optionRef.current.scrollTop = 0;
        return;
      }

      const index = options.findIndex(
        (
          option, // 첫 글자로 시작하는 항목을 찾음
        ) => option.value.toLowerCase().startsWith(inputValue.toLowerCase()),
      );

      let scrollTo = index * OPTION_HEIGHT;
      if (index === -1) {
        const includeIndex = options.findIndex(
          (option) => option.value.toLowerCase().includes(inputValue.toLowerCase()), // 포함하는 항목을 찾음
        );
        if (includeIndex !== -1) {
          scrollTo = includeIndex * OPTION_HEIGHT;
          optionRef.current.scrollTop = scrollTo;
        }
        return;
      }

      optionRef.current.scrollTop = scrollTo;
    }
  }, [inputValue, open, options]);

  return (
    <div className="relative w-full">
      <TextField
        placeholder={placeholder}
        value={inputValue}
        onChange={onChangeInput}
        disabled={disabled}
        className={className}
        {...props}
      />
      {open && (
        <div className="absolute z-dropdown top-full translate-y-3 w-full">
          <ul
            ref={optionRef}
            className={cnm(
              'bg-bg-white rounded-s border-solid border-s border-border-inverseQuaternary shadow-s',
              'custom-scrollbar max-h-[264px] w-full overflow-y-auto',
              'mb-10',
            )}
          >
            {options.map(({ value, label }) => (
              <li
                onClick={() => onSelectOption(value)}
                className="flex justify-between items-center px-[14px] py-[11px] cursor-pointer hover:bg-gray-100"
                key={value}
              >
                <B1b>{label || value}</B1b>
                {inputValue === value && <SVIcon name="CheckSFilled" />}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
