/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type SubscriptionProductsListSubscriptionType =
  (typeof SubscriptionProductsListSubscriptionType)[keyof typeof SubscriptionProductsListSubscriptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionProductsListSubscriptionType = {
  QUESTION_BANK: 'QUESTION_BANK',
  TUTORING: 'TUTORING',
} as const;
