import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import { cnm } from '@/utils/cnm';

import SVIcon from '../sv-icon';

interface ModalProps extends Dialog.DialogContentProps {
  closeButton?: boolean;
  onClose?: () => void;
}

export function Modal({ children, closeButton, className, onClose, ...props }: ModalProps) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 bg-bg-zIndex-popup z-modal" onPointerUp={onClose} />
      <Dialog.Title />
      <Dialog.Description />
      <Dialog.Content
        className={cnm(
          'z-modal fixed flex flex-col left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-bg-white px-10 py-8 focus:outline-none',
          'max-h-[90%] hide-scrollbar',
          className,
        )}
        {...props}
      >
        {closeButton && (
          <Dialog.Close className="self-end">
            <SVIcon name="XLOutlined" />
          </Dialog.Close>
        )}
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
}

export default Object.assign(Modal, {
  Title: Dialog.Title,
  Description: Dialog.Description,
  Close: Dialog.Close,
});

export const ModalProvider = Dialog.Root;
export const ModalTrigger = Dialog.Trigger;
