/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type TutoringTutoringUpcomingListStatus =
  (typeof TutoringTutoringUpcomingListStatus)[keyof typeof TutoringTutoringUpcomingListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutoringTutoringUpcomingListStatus = {
  active: 'active',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  past_due: 'past_due',
  paused: 'paused',
  trailing: 'trailing',
  unpaid: 'unpaid',
} as const;
