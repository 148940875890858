import { Mathematics } from '@/constants';

export const getCourseName = (
  course: { name?: string; level?: string; subjectId?: number },
  subject?: { name?: string; id?: number },
) => {
  if (course?.subjectId) {
    return `${(course?.subjectId ?? subject?.id) === Mathematics?.subjectId ? 'Mathematics ' : ''}${course.name} ${course.level}`;
  }
  if (subject?.name) {
    return `${subject?.name === 'Mathematics' ? 'Mathematics ' : ''}${course.name} ${course.level}`;
  }
  return `${course.name} ${course.level}`;
};
