import { LevelEnum, Course as OrvalCourse } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const Course = zod.object({
  id: zod.number(),
  name: zod.string(),
  level: zod.nativeEnum(LevelEnum),
  subjectId: zod.number().nullable().optional(),
  createdAt: zod.date(),
  updatedAt: zod.date(),
  inService: zod.boolean().optional(),
  unlocked: zod.boolean(),
});

export type Course = zod.infer<typeof Course>;

export const toCourse = (course: OrvalCourse): Course => {
  let result;
  try {
    result = Course.parse({
      id: course.id,
      name: course.name,
      level: course.level,
      subjectId: course.subject,
      createdAt: new Date(course.created_at),
      updatedAt: new Date(course.updated_at),
      inService: course.in_service,
      unlocked: course?.unlocked ?? false,
    });
  } catch (e) {
    console.log('Error parsing course', e);
    throw e;
  }

  return result;
};
