import { GroupedCourseDetail as OrvalGroupedCourseDetail } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const GroupedCourseDetail = zod.object({
  id: zod.number(),
  inService: zod.boolean(),
  level: zod.string(),
  unlocked: zod.boolean(),
});

export type GroupedCourseDetail = zod.infer<typeof GroupedCourseDetail>;

export const toGroupedCourseDetail = (detail: OrvalGroupedCourseDetail): GroupedCourseDetail => {
  let result;
  try {
    result = GroupedCourseDetail.parse({
      id: detail.id,
      inService: detail.in_service,
      level: detail.level,
      unlocked: detail?.unlocked ?? false,
    });
  } catch (e) {
    console.log('Error parsing grouped course detail', e);
    throw e;
  }

  return result;
};
