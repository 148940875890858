import Tag from '@/ui/component/tag';
import { B1, H5b } from '@/ui/component/typography';
import { cnm } from '@/utils/cnm';

interface PaymentCycleProps {
  name?: string;
  cycle: number;
  sale?: number;
  defaultChecked?: boolean;
}

export default function PaymentCycle({
  name = 'pricing',
  cycle,
  sale,
  defaultChecked,
}: PaymentCycleProps) {
  return (
    <label
      className={cnm(
        'cursor-pointer p-5 flex flex-col items-start gap-1 border-solid border-s border-border-inverseTertiary bg-bg-white rounded-lg',
        'group has-[:checked]:!border-lg has-[:checked]:border-border-inversePrimary',
      )}
    >
      <input
        name={name}
        type="radio"
        className="sr-only"
        value={cycle}
        defaultChecked={defaultChecked}
      />
      <div className="flex justify-between w-full">
        <H5b className="text-content-inverseTertiary group-has-[:checked]:text-content-inversePrimary">
          {cycle === 12 ? 'Yearly' : `${cycle} Months`}
        </H5b>
        {sale && (
          <Tag color="orange" size="lg" shape="round">
            SAVE {sale}%
          </Tag>
        )}
      </div>
      <B1 className="text-content-inverseTertiary group-has-[:checked]:text-content-inverseSecondary">
        {`Billed each ${cycle === 12 ? 'year' : `${cycle}months`}`}
      </B1>
    </label>
  );
}
