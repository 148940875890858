'use client';

import { useEffect, useState } from 'react';

interface UseSearchControlProps {
  value?: string; // for controlled 컴포넌트
  onChange?: (value: string) => void; // for controlled 컴포넌트
}

export const useSearchControl = ({ value, onChange }: UseSearchControlProps) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);

  const onSelectOption = (option: string) => {
    if (onChange) {
      onChange(option);
    } else {
      setInputValue(option);
    }
    setOpen(false);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (input.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (onChange) {
      onChange(input); // for controlled component
    } else {
      setInputValue(input); // for uncontrolled component
    }
  };

  useEffect(() => {
    const clickDropdownOutside = () => {
      if (open) {
        setOpen(false);
      }
    };

    document.addEventListener('click', clickDropdownOutside);

    return () => document.removeEventListener('click', clickDropdownOutside);
  }, [open, setOpen]);

  return {
    inputValue: value || inputValue,
    open,
    onSelectOption,
    onChangeInput,
  };
};
