/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `REFUND` - Refund
 * `CANCEL` - Cancel
 */
export type SubscriptionCancellationRequestRequestTypeEnum =
  (typeof SubscriptionCancellationRequestRequestTypeEnum)[keyof typeof SubscriptionCancellationRequestRequestTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionCancellationRequestRequestTypeEnum = {
  REFUND: 'REFUND',
  CANCEL: 'CANCEL',
} as const;
