import {
  ChargeHistoryQBRead as OrvalChargeHistoryQBRead,
  Status0f5Enum,
  StatusDisplayEnum,
} from 'studyvibes-api/orval/models';
import { Course, toCourse } from './course';
import { PaymentMethod, toPaymentMethod } from './payment-method';
import { SubscriptionProduct, toSubscriptionProduct } from './subscription-product';
import { toUserDetail, UserAuthDetail } from './user-auth-detail';
import * as zod from 'zod';

export const ChargeHistoryQBRead = zod.object({
  chargeId: zod.string(),
  course: Course,
  updatedAt: zod.date(),
  currency: zod.string(),
  eventType: zod.string(),
  id: zod.number(),
  paymentIntentId: zod.string(),
  paymentMethod: PaymentMethod.optional(),
  quantity: SubscriptionProduct.optional(),
  receiptUrl: zod.string(),
  status: zod.nativeEnum(Status0f5Enum).optional(),
  statusDisplay: zod.nativeEnum(StatusDisplayEnum),
  totalPrice: zod.number(),
  user: UserAuthDetail,
});

export type ChargeHistoryQBRead = zod.infer<typeof ChargeHistoryQBRead>;

export const toChargeHistoryQBRead = (course: OrvalChargeHistoryQBRead): ChargeHistoryQBRead => {
  let result;
  try {
    result = ChargeHistoryQBRead.parse({
      chargeId: course.charge_id,
      course: toCourse(course.course),
      updatedAt: new Date(course.updated_at),
      currency: course.currency,
      eventType: course.event_type,
      id: course.id,
      paymentIntentId: course.payment_intent_id,
      paymentMethod: course.payment_method ? toPaymentMethod(course.payment_method) : undefined,
      quantity: course.quantity ? toSubscriptionProduct(course.quantity) : undefined,
      receiptUrl: course.receipt_url,
      status: course.status,
      statusDisplay: course.status_display,
      totalPrice: course.total_price,
      user: toUserDetail(course.user),
    });
  } catch (e) {
    console.log('Error parsing ChargeHistoryQBRead', e);
    throw e;
  }

  return result;
};
