import { CalendarEvent as OrvalCalendarEvent, EventTypeEnum } from 'studyvibes-api/orval/models';

import * as zod from 'zod';

export const CalendarEvent = zod.object({
  description: zod.string().optional().nullable(),
  end: zod.date(),
  eventType: zod.nativeEnum(EventTypeEnum),
  externalId: zod.number(),
  start: zod.date(),
  title: zod.string(),
});

export type CalendarEvent = zod.infer<typeof CalendarEvent>;

export const toCalendarEvent = (tutor: OrvalCalendarEvent): CalendarEvent => {
  let result;
  try {
    result = CalendarEvent.parse({
      description: tutor.description,
      end: tutor.end && new Date(tutor.end),
      eventType: tutor.event_type,
      externalId: tutor.external_id,
      start: tutor.start && new Date(tutor.start),
      title: tutor.title,
    });
  } catch (e) {
    console.log('Error parsing calendar event', e);
    throw e;
  }

  return result;
};
