/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type UsersStudentListRole = (typeof UsersStudentListRole)[keyof typeof UsersStudentListRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersStudentListRole = {
  STAFF: 'STAFF',
  STUDENT: 'STUDENT',
  TUTOR: 'TUTOR',
} as const;
