/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `EXAM` - Exam
 * `ASSIGNMENT` - Assignment
 */
export type SemesterTypeEnum = (typeof SemesterTypeEnum)[keyof typeof SemesterTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SemesterTypeEnum = {
  EXAM: 'EXAM',
  ASSIGNMENT: 'ASSIGNMENT',
} as const;
