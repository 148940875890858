/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `processing` - Processing
 * `accepted` - Accepted
 * `rejected` - Rejected
 * `cancelled` - Cancelled
 */
export type Status53dEnum = (typeof Status53dEnum)[keyof typeof Status53dEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status53dEnum = {
  processing: 'processing',
  accepted: 'accepted',
  rejected: 'rejected',
  cancelled: 'cancelled',
} as const;
