import {
  TutorProfileRead as OrvalTutorProfileRead,
  TutorTypeEnum,
} from 'studyvibes-api/orval/models';
import { CourseRead, toCourseRead } from './course-read';
import { toTutor, Tutor } from './tutor';
import { toTutoringStyle, TutoringStyle } from './tutoring-style';
import * as zod from 'zod';

export const TutorProfileRead = zod.object({
  bankAccountNumber: zod.string().nullable().optional(),
  bankName: zod.string().nullable().optional(),
  careerOrStrength: zod.array(zod.string()).nullable().optional(),
  country: zod.string().nullable().optional(),
  courses: zod.array(CourseRead),
  gender: zod.string().optional(),
  introduction: zod.string().nullable().optional(),
  kakaoTalk: zod.string().nullable().optional(),
  major: zod.string().nullable().optional(),
  picture: zod.string().nullable().optional(),
  simpleIntroduction: zod.string().nullable().optional(),
  tutor: Tutor,
  tutorType: zod.nativeEnum(TutorTypeEnum).optional(),
  tutoringStyle: zod.array(TutoringStyle).nullable().optional(),
  university: zod.string().nullable().optional(),
  whatsapp: zod.string().nullable().optional(),
  recommendStudents: zod.array(zod.string()).nullable().optional(),
});

export type TutorProfileRead = zod.infer<typeof TutorProfileRead>;

export const toTutorProfileRead = (tutorProfile: OrvalTutorProfileRead): TutorProfileRead => {
  let result;

  try {
    result = TutorProfileRead.parse({
      bankAccountNumber: tutorProfile.bank_account_number,
      bankName: tutorProfile.bank_name,
      careerOrStrength: tutorProfile.career_or_strength,
      country: tutorProfile.country,
      courses: tutorProfile.courses.map(toCourseRead),
      gender: tutorProfile.gender,
      introduction: tutorProfile.introduction,
      kakaoTalk: tutorProfile.kakao_talk,
      major: tutorProfile.major,
      picture: tutorProfile.picture,
      simpleIntroduction: tutorProfile.simple_introduction,
      tutor: toTutor(tutorProfile.tutor),
      tutorType: tutorProfile.tutor_type,
      tutoringStyle: tutorProfile.tutoring_style?.map(toTutoringStyle),
      university: tutorProfile.university,
      whatsapp: tutorProfile.whatsapp,
      recommendStudents: tutorProfile.recommend_students,
    });
  } catch (e) {
    console.log('Error parsing tutor profile read', e);
    throw e;
  }

  return result;
};
