/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `TIME_TRACKING` - time_tracking
 * `SCHEDULE` - schedule
 */
export type EventTypeEnum = (typeof EventTypeEnum)[keyof typeof EventTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventTypeEnum = {
  TIME_TRACKING: 'TIME_TRACKING',
  SCHEDULE: 'SCHEDULE',
} as const;
