import {
  SubscriptionTutoring as OrvalSubscriptionTutoring,
  Status95bEnum,
} from 'studyvibes-api/orval/models';
import { Tutoring, toTutoring } from './tutoring';
import { PaymentMethod, toPaymentMethod } from './payment-method';
import { UserAuthDetail, toUserDetail } from './user-auth-detail';
import * as zod from 'zod';
import { SubscriptionProduct, toSubscriptionProduct } from './subscription-product';

export const SubscriptionTutoring = zod.object({
  cancelAtPeriodEnd: zod.boolean(),
  createdAt: zod.date(),
  currentPeriodEnd: zod.date(),
  currentPeriodStart: zod.date(),
  id: zod.number(),
  paymentMethod: PaymentMethod.optional(),
  product: SubscriptionProduct.nullable().optional(),
  quantity: zod.number(),
  status: zod.nativeEnum(Status95bEnum),
  tutoring: Tutoring,
  user: UserAuthDetail,
});

export type SubscriptionTutoring = zod.infer<typeof SubscriptionTutoring>;

export const toSubscriptionTutoring = (
  subscription: OrvalSubscriptionTutoring,
): SubscriptionTutoring => {
  let result;
  try {
    result = SubscriptionTutoring.parse({
      cancelAtPeriodEnd: subscription.cancel_at_period_end,
      createdAt: new Date(subscription.created_at),
      tutoring: toTutoring(subscription.tutoring),
      currentPeriodEnd: new Date(subscription.current_period_end),
      currentPeriodStart: new Date(subscription.current_period_start),
      id: subscription.id,
      status: subscription.status,
      paymentMethod: subscription.payment_method
        ? toPaymentMethod(subscription.payment_method)
        : undefined,
      quantity: subscription.quantity,
      product: subscription.product ? toSubscriptionProduct(subscription.product) : undefined,
      user: toUserDetail(subscription.user),
    });
  } catch (e) {
    console.log('Error parsing subscription tutoring', e);
    throw e;
  }

  return result;
};
