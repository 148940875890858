import { PatchedTimeTrackingWrite as OrvalPatchedTimeTrackingWrite } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const PatchedTimeTrackingWrite = zod.object({
  courseId: zod.number().optional().nullable(),
  endTime: zod.date(),
  notes: zod.string(),
  startTime: zod.date(),
});

export type PatchedTimeTrackingWrite = zod.infer<typeof PatchedTimeTrackingWrite>;

export const toTimeTrackingWrite = (
  timeTracking: OrvalPatchedTimeTrackingWrite,
): PatchedTimeTrackingWrite => {
  let result;
  try {
    result = PatchedTimeTrackingWrite.parse({
      courseId: timeTracking.course,
      startTime: timeTracking?.start_time && new Date(timeTracking.start_time),
      endTime: timeTracking?.end_time && new Date(timeTracking.end_time),
      notes: timeTracking.notes,
    });
  } catch (e) {
    console.log('Error parsing patched time tracking write', e);
    throw e;
  }

  return result;
};

export const toPatchedTimeTrackingWriteParams = (
  timeTracking: PatchedTimeTrackingWrite,
): OrvalPatchedTimeTrackingWrite => {
  let parsed = PatchedTimeTrackingWrite.parse(timeTracking);

  return {
    course: parsed.courseId,
    start_time: parsed.startTime.toISOString(),
    end_time: parsed.endTime.toISOString(),
    notes: parsed.notes,
  };
};
