import { QuestionAttemptStatusCounts as OrvalQuestionAttemptStatusCounts } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const QuestionAttemptStatusCounts = zod.object({
  isCorrect: zod.number(),
  isMarked: zod.number(),
  isSolved: zod.number(),
});

export type QuestionAttemptStatusCounts = zod.infer<typeof QuestionAttemptStatusCounts>;

export const toQuestionAttemptStatusCounts = (
  statusCounts: OrvalQuestionAttemptStatusCounts,
): QuestionAttemptStatusCounts => {
  let result;
  try {
    result = QuestionAttemptStatusCounts.parse({
      isCorrect: statusCounts?.is_correct,
      isMarked: statusCounts?.is_marked,
      isSolved: statusCounts?.is_solved,
    });
  } catch (e) {
    console.log('Error parsing question attempt status counts', e);
    throw e;
  }

  return result;
};
