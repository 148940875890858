/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `Recommended` - recommended
 * `Selected` - selected
 * `Declined` - declined
 * `Coordinated` - coordinated
 * `Pending` - pending
 * `Confirmed` - confirmed
 * `Expired` - expired
 */
export type StatusAd0Enum = (typeof StatusAd0Enum)[keyof typeof StatusAd0Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusAd0Enum = {
  Recommended: 'Recommended',
  Selected: 'Selected',
  Declined: 'Declined',
  Coordinated: 'Coordinated',
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Expired: 'Expired',
} as const;
