import { ScheduleWrite as OrvalScheduleWrite } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const ScheduleWrite = zod.object({
  endTime: zod.date(),
  name: zod.string(),
  notes: zod.string().optional().nullable(),
  startTime: zod.date(),
});

export type ScheduleWrite = zod.infer<typeof ScheduleWrite>;

export const toScheduleWrite = (schedule: OrvalScheduleWrite): ScheduleWrite => {
  let result;
  try {
    result = ScheduleWrite.parse({
      name: schedule.name,
      startTime: new Date(schedule.start_time),
      endTime: new Date(schedule.end_time),
      notes: schedule.notes,
    });
  } catch (e) {
    console.log('Error parsing schedule write', e);
    throw e;
  }

  return result;
};

export const toScheduleWriteParams = (schedule: ScheduleWrite): OrvalScheduleWrite => {
  let params = ScheduleWrite.parse(schedule);

  return {
    end_time: params.endTime.toISOString(),
    name: params.name,
    notes: params.notes,
    start_time: params.startTime.toISOString(),
  };
};
