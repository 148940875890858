/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `PENDING` - Pending
 * `APPROVED` - Approved
 * `REJECTED` - Rejected
 * `REFUNDED` - Refunded
 * `CANCELLED` - Cancelled
 */
export type SubscriptionCancellationRequestStatusEnum =
  (typeof SubscriptionCancellationRequestStatusEnum)[keyof typeof SubscriptionCancellationRequestStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionCancellationRequestStatusEnum = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REFUNDED: 'REFUNDED',
  CANCELLED: 'CANCELLED',
} as const;
