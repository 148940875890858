'use client';

import { useMemo, useState } from 'react';

import { useGetCourseList } from '@/operation/api/question-bank/query';
import { getCourseName } from '@/utils/course-name';

export const useSearchCourse = () => {
  const { data: courseList = [] } = useGetCourseList({}, (data) =>
    data?.map((course) => ({
      value: getCourseName(course),
      id: course.id,
    })),
  );
  const [search, setSearch] = useState('');

  const course = useMemo(
    () => courseList.find((course) => course.value === search),
    [courseList, search],
  );

  return {
    courseList,
    search,
    setSearch,
    course,
  };
};
