/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `month` - month
 * `year` - year
 */
export type IntervalEnum = (typeof IntervalEnum)[keyof typeof IntervalEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntervalEnum = {
  month: 'month',
  year: 'year',
} as const;
