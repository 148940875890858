/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

export type SubscriptionRequestCancelListStatus =
  (typeof SubscriptionRequestCancelListStatus)[keyof typeof SubscriptionRequestCancelListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionRequestCancelListStatus = {
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
  REJECTED: 'REJECTED',
} as const;
