/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `INCOMPLETE` - incomplete
 * `INCOMPLETE_EXPIRED` - incomplete_expired
 * `TRAILING` - trailing
 * `ACTIVE` - active
 * `PAST_DUE` - past_due
 * `CANCELED` - canceled
 * `UNPAID` - unpaid
 * `PAUSED` - paused
 */
export type SubscriptionStatusEnum =
  (typeof SubscriptionStatusEnum)[keyof typeof SubscriptionStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatusEnum = {
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  TRAILING: 'TRAILING',
  ACTIVE: 'ACTIVE',
  PAST_DUE: 'PAST_DUE',
  CANCELED: 'CANCELED',
  UNPAID: 'UNPAID',
  PAUSED: 'PAUSED',
} as const;
