import { CourseSubject as OrvalCourseSubject } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const CourseSubject = zod.object({
  id: zod.number(),
  name: zod.string(),
});

export type CourseSubject = zod.infer<typeof CourseSubject>;
export const toCourseSubject = (subject: OrvalCourseSubject): CourseSubject => {
  let result;

  try {
    result = CourseSubject.parse({
      id: subject.id,
      name: subject.name,
    });
  } catch (e) {
    console.log('Error parsing course subject', e);
    throw e;
  }

  return result;
};
