import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface Course {
  name: string;
  level: string;
  id: number;
  price: number;
}

interface CourseItem {
  subjectName: string;
  course: Course;
  cycle?: number;
}

interface UsePaymentQuestionBankStore {
  selectedCourseList: CourseItem[];
  setSelectedCourseList: (
    courseList: CourseItem[] | ((prev: CourseItem[]) => CourseItem[]),
  ) => void;
  resetSelectedCourseList: () => void;
}

export const usePaymentQuestionBankStore = create<UsePaymentQuestionBankStore>()(
  devtools(
    (set) => ({
      selectedCourseList: [],
      setSelectedCourseList: (courseList) =>
        set((state) => ({
          selectedCourseList:
            typeof courseList === 'function' ? courseList(state.selectedCourseList) : courseList,
        })),
      resetSelectedCourseList: () =>
        set(() => ({
          selectedCourseList: [],
        })),
    }),
    { name: 'usePaymentQuestionBankStore' },
  ),
);
