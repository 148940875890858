/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AccountGoogleCallbackRetrieveParams,
  AssignmentRead,
  AssignmentWrite,
  CalendarEvent,
  ChargeHistoryQBRead,
  ChargeHistoryTutoringRead,
  CheckoutResponse,
  Course,
  CourseSelection,
  CourseSelectionUpdate,
  CourseTutoring,
  EmailLogin,
  ExamInfoRead,
  ExamInfoWrite,
  FlashcardFlashcardListParams,
  FlashcardRead,
  FlashcardSubjectOverview,
  FlashcardWrite,
  GoogleLogin,
  GradeAssignmentListParams,
  GradeExamInfoGroupByCourseListParams,
  GradeExamInfoListParams,
  GradeSemesterExamListParams,
  GradeSemesterListParams,
  GradeSemesterSubjectListParams,
  GroupedSubject,
  JWTSerializerWithUserInfo,
  PaginatedAssignmentListList,
  PaginatedChargeHistoryQBReadList,
  PaginatedChargeHistoryTutoringReadList,
  PaginatedExamInfoGroupedCourseList,
  PaginatedExamInfoListList,
  PaginatedFlashcardReadList,
  PaginatedQnaList,
  PaginatedQuestionAttemptList,
  PaginatedQuestionReadList,
  PaginatedQuestionReportListList,
  PaginatedRecommendTutorsGroupedByCourseList,
  PaginatedRecommendTutorsReadList,
  PaginatedRequestTutoringReadList,
  PaginatedScheduleReadList,
  PaginatedSemesterExamList,
  PaginatedSemesterList,
  PaginatedSemesterSubjectList,
  PaginatedStudentList,
  PaginatedSubscriptionCancellationRequestList,
  PaginatedSubscriptionQBList,
  PaginatedSubscriptionTutoringList,
  PaginatedTimeTrackingReadList,
  PaginatedTutorList,
  PaginatedTutorProfileReadList,
  PaginatedTutoringBookingList,
  PaginatedTutoringCommentList,
  PaginatedTutoringGroupedDataList,
  PaginatedTutoringListList,
  PaginatedUserAuthDetailList,
  PatchedAssignmentWrite,
  PatchedCourse,
  PatchedCourseSelectionUpdate,
  PatchedExamInfoWrite,
  PatchedFlashcardWrite,
  PatchedPaymentMethodUpdate,
  PatchedQna,
  PatchedQuestion,
  PatchedQuestionAttemptUpdate,
  PatchedRecommendTutorsUpdate,
  PatchedRequestTutoringUpdate,
  PatchedScheduleWrite,
  PatchedSemester,
  PatchedSemesterExam,
  PatchedSemesterSubject,
  PatchedStudent,
  PatchedSubjectWithCourse,
  PatchedSubscriptionCancellationRequestUpdate,
  PatchedSubscriptionProduct,
  PatchedSubtopic,
  PatchedTimeTrackingWrite,
  PatchedTopic,
  PatchedTutorProfileUpdate,
  PatchedTutorUpdate,
  PatchedTutoring,
  PatchedTutoringBookingUpdate,
  PatchedTutoringComment,
  PatchedTutoringPrice,
  PaymentMethod,
  Qna,
  QnasQnaListParams,
  Question,
  QuestionAttempt,
  QuestionAttemptCreate,
  QuestionBankAttemptListParams,
  QuestionBankCourseListParams,
  QuestionBankGroupedSubjectsListParams,
  QuestionBankGroupedSubjectsRetrieveParams,
  QuestionBankQuestionListParams,
  QuestionBankReportListParams,
  QuestionBankSubjectListParams,
  QuestionBankSubjectPartialUpdateParams,
  QuestionBankSubjectRetrieveParams,
  QuestionBankSubtopicListParams,
  QuestionBankTopicListParams,
  QuestionReport,
  QuestionSubjectOverview,
  RecommendTutorsCreate,
  RecommendTutorsRead,
  RequestTutoringCreate,
  RequestTutoringRead,
  ScheduleCalendarEventListParams,
  ScheduleRead,
  ScheduleScheduleListParams,
  ScheduleTimeTrackingListParams,
  ScheduleWrite,
  Semester,
  SemesterExam,
  SemesterSubject,
  Session,
  Student,
  SubjectWithCourse,
  SubscriptionCancellationRequest,
  SubscriptionPaymentMethodListParams,
  SubscriptionPaymentQuestionBankAmountRetrieve200,
  SubscriptionPaymentQuestionBankAmountRetrieveParams,
  SubscriptionPaymentQuestionBankListParams,
  SubscriptionPaymentTutoringAmountRetrieve200,
  SubscriptionPaymentTutoringAmountRetrieveParams,
  SubscriptionPaymentTutoringListParams,
  SubscriptionProduct,
  SubscriptionProductsListParams,
  SubscriptionQB,
  SubscriptionQuestionBankListParams,
  SubscriptionRequestCancelListParams,
  SubscriptionTutoring,
  SubscriptionTutoringListParams,
  Subtopic,
  TimeTrackingRead,
  TimeTrackingWrite,
  Token,
  TokenRefresh,
  Topic,
  Tutor,
  TutorCreate,
  TutorProfileCount,
  TutorProfileRead,
  TutoringBooking,
  TutoringBookingCreate,
  TutoringBookingListParams,
  TutoringBookingUpdate,
  TutoringComment,
  TutoringCommentListParams,
  TutoringCreate,
  TutoringHistory,
  TutoringList,
  TutoringPrice,
  TutoringRecommendGroupByCourseListParams,
  TutoringRecommendListParams,
  TutoringRequestListParams,
  TutoringTime,
  TutoringTimeRetrieveParams,
  TutoringTutoringGroupedByUserListParams,
  TutoringTutoringHistoryListParams,
  TutoringTutoringListParams,
  TutoringTutoringTutorStudentCountRetrieve200,
  TutoringTutoringTutorStudentCountRetrieveParams,
  TutoringTutoringUpcomingListParams,
  UsersStudentListParams,
  UsersTutorBookingsListParams,
  UsersTutorListParams,
  UsersTutorProfileListParams,
  UsersTutorProfilePartialUpdateBodyTwo,
} from '../models';
import { customInstance } from '../../customInstance';
import type { ErrorType, BodyType } from '../../customInstance';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> =
  (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * 이메일 로그인
 * @summary 이메일 로그인
 */
export const accountEmailLoginCreate = (
  emailLogin: BodyType<EmailLogin>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<JWTSerializerWithUserInfo>(
    {
      url: `/account/email_login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: emailLogin,
      signal,
    },
    options,
  );
};

export const getAccountEmailLoginCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof accountEmailLoginCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<EmailLogin> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['accountEmailLoginCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountEmailLoginCreate>>,
    { data: BodyType<EmailLogin> }
  > = (props) => {
    const { data } = props ?? {};

    return accountEmailLoginCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<EmailLogin> },
    TContext
  >;
};

export type AccountEmailLoginCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountEmailLoginCreate>>
>;
export type AccountEmailLoginCreateMutationBody = BodyType<EmailLogin>;
export type AccountEmailLoginCreateMutationError = ErrorType<unknown>;

/**
 * @summary 이메일 로그인
 */
export const useAccountEmailLoginCreate = <
  TData = Awaited<ReturnType<typeof accountEmailLoginCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<EmailLogin> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<EmailLogin> }, TContext> => {
  const mutationOptions = getAccountEmailLoginCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 구글 로그인
 * @summary 구글 로그인
 */
export const accountGoogleCreate = (
  googleLogin: BodyType<GoogleLogin>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<JWTSerializerWithUserInfo>(
    {
      url: `/account/google`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: googleLogin,
      signal,
    },
    options,
  );
};

export const getAccountGoogleCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof accountGoogleCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<GoogleLogin> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['accountGoogleCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountGoogleCreate>>,
    { data: BodyType<GoogleLogin> }
  > = (props) => {
    const { data } = props ?? {};

    return accountGoogleCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<GoogleLogin> },
    TContext
  >;
};

export type AccountGoogleCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountGoogleCreate>>
>;
export type AccountGoogleCreateMutationBody = BodyType<GoogleLogin>;
export type AccountGoogleCreateMutationError = ErrorType<unknown>;

/**
 * @summary 구글 로그인
 */
export const useAccountGoogleCreate = <
  TData = Awaited<ReturnType<typeof accountGoogleCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<GoogleLogin> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<GoogleLogin> }, TContext> => {
  const mutationOptions = getAccountGoogleCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 구글 콜백 로그인
 * @summary 구글 콜백 로그인
 */
export const accountGoogleCallbackRetrieve = (
  params: AccountGoogleCallbackRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Token>(
    { url: `/account/google/callback`, method: 'GET', params, signal },
    options,
  );
};

export const getAccountGoogleCallbackRetrieveQueryKey = (
  params: AccountGoogleCallbackRetrieveParams,
) => {
  return [`/account/google/callback`, ...(params ? [params] : [])] as const;
};

export const getAccountGoogleCallbackRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAccountGoogleCallbackRetrieveQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>> = ({
    signal,
  }) => accountGoogleCallbackRetrieve(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type AccountGoogleCallbackRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>
>;
export type AccountGoogleCallbackRetrieveQueryError = ErrorType<unknown>;

export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 구글 콜백 로그인
 */

export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getAccountGoogleCallbackRetrieveQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const accountTokenRefreshCreate = (
  tokenRefresh: BodyType<NonReadonly<TokenRefresh>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TokenRefresh>(
    {
      url: `/account/token/refresh`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tokenRefresh,
      signal,
    },
    options,
  );
};

export const getAccountTokenRefreshCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TokenRefresh>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['accountTokenRefreshCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
    { data: BodyType<NonReadonly<TokenRefresh>> }
  > = (props) => {
    const { data } = props ?? {};

    return accountTokenRefreshCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TokenRefresh>> },
    TContext
  >;
};

export type AccountTokenRefreshCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountTokenRefreshCreate>>
>;
export type AccountTokenRefreshCreateMutationBody = BodyType<NonReadonly<TokenRefresh>>;
export type AccountTokenRefreshCreateMutationError = ErrorType<unknown>;

export const useAccountTokenRefreshCreate = <
  TData = Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TokenRefresh>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<TokenRefresh>> }, TContext> => {
  const mutationOptions = getAccountTokenRefreshCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const flashcardFlashcardList = (
  params?: FlashcardFlashcardListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedFlashcardReadList>(
    { url: `/flashcard/flashcard`, method: 'GET', params, signal },
    options,
  );
};

export const getFlashcardFlashcardListQueryKey = (params?: FlashcardFlashcardListParams) => {
  return [`/flashcard/flashcard`, ...(params ? [params] : [])] as const;
};

export const getFlashcardFlashcardListQueryOptions = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardList>>,
  TError = ErrorType<unknown>,
>(
  params?: FlashcardFlashcardListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFlashcardFlashcardListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof flashcardFlashcardList>>> = ({ signal }) =>
    flashcardFlashcardList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof flashcardFlashcardList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type FlashcardFlashcardListQueryResult = NonNullable<
  Awaited<ReturnType<typeof flashcardFlashcardList>>
>;
export type FlashcardFlashcardListQueryError = ErrorType<unknown>;

export function useFlashcardFlashcardList<
  TData = Awaited<ReturnType<typeof flashcardFlashcardList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | FlashcardFlashcardListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof flashcardFlashcardList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useFlashcardFlashcardList<
  TData = Awaited<ReturnType<typeof flashcardFlashcardList>>,
  TError = ErrorType<unknown>,
>(
  params?: FlashcardFlashcardListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof flashcardFlashcardList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useFlashcardFlashcardList<
  TData = Awaited<ReturnType<typeof flashcardFlashcardList>>,
  TError = ErrorType<unknown>,
>(
  params?: FlashcardFlashcardListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useFlashcardFlashcardList<
  TData = Awaited<ReturnType<typeof flashcardFlashcardList>>,
  TError = ErrorType<unknown>,
>(
  params?: FlashcardFlashcardListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getFlashcardFlashcardListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const flashcardFlashcardCreate = (
  flashcardWrite: BodyType<FlashcardWrite>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<FlashcardRead>(
    {
      url: `/flashcard/flashcard`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: flashcardWrite,
      signal,
    },
    options,
  );
};

export const getFlashcardFlashcardCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<FlashcardWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['flashcardFlashcardCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof flashcardFlashcardCreate>>,
    { data: BodyType<FlashcardWrite> }
  > = (props) => {
    const { data } = props ?? {};

    return flashcardFlashcardCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<FlashcardWrite> },
    TContext
  >;
};

export type FlashcardFlashcardCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof flashcardFlashcardCreate>>
>;
export type FlashcardFlashcardCreateMutationBody = BodyType<FlashcardWrite>;
export type FlashcardFlashcardCreateMutationError = ErrorType<unknown>;

export const useFlashcardFlashcardCreate = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<FlashcardWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<FlashcardWrite> }, TContext> => {
  const mutationOptions = getFlashcardFlashcardCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const flashcardFlashcardRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<FlashcardRead>(
    { url: `/flashcard/flashcard/${id}`, method: 'GET', signal },
    options,
  );
};

export const getFlashcardFlashcardRetrieveQueryKey = (id: number) => {
  return [`/flashcard/flashcard/${id}`] as const;
};

export const getFlashcardFlashcardRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFlashcardFlashcardRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>> = ({
    signal,
  }) => flashcardFlashcardRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type FlashcardFlashcardRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>
>;
export type FlashcardFlashcardRetrieveQueryError = ErrorType<unknown>;

export function useFlashcardFlashcardRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useFlashcardFlashcardRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useFlashcardFlashcardRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useFlashcardFlashcardRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof flashcardFlashcardRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getFlashcardFlashcardRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const flashcardFlashcardPartialUpdate = (
  id: number,
  patchedFlashcardWrite: BodyType<PatchedFlashcardWrite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlashcardRead>(
    {
      url: `/flashcard/flashcard/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedFlashcardWrite,
    },
    options,
  );
};

export const getFlashcardFlashcardPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedFlashcardWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['flashcardFlashcardPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof flashcardFlashcardPartialUpdate>>,
    { id: number; data: BodyType<PatchedFlashcardWrite> }
  > = (props) => {
    const { id, data } = props ?? {};

    return flashcardFlashcardPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedFlashcardWrite> },
    TContext
  >;
};

export type FlashcardFlashcardPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof flashcardFlashcardPartialUpdate>>
>;
export type FlashcardFlashcardPartialUpdateMutationBody = BodyType<PatchedFlashcardWrite>;
export type FlashcardFlashcardPartialUpdateMutationError = ErrorType<unknown>;

export const useFlashcardFlashcardPartialUpdate = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedFlashcardWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedFlashcardWrite> },
  TContext
> => {
  const mutationOptions = getFlashcardFlashcardPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const flashcardFlashcardDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/flashcard/flashcard/${id}`, method: 'DELETE' }, options);
};

export const getFlashcardFlashcardDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['flashcardFlashcardDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof flashcardFlashcardDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return flashcardFlashcardDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type FlashcardFlashcardDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof flashcardFlashcardDestroy>>
>;

export type FlashcardFlashcardDestroyMutationError = ErrorType<unknown>;

export const useFlashcardFlashcardDestroy = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getFlashcardFlashcardDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Course 별 총 플래시 카드 수 (topic, subtopic)
 * @summary 총 플래시 카드 수 확인
 */
export const flashcardFlashcardStatusCountRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<FlashcardSubjectOverview>(
    { url: `/flashcard/flashcard/${id}/status-count`, method: 'GET', signal },
    options,
  );
};

export const getFlashcardFlashcardStatusCountRetrieveQueryKey = (id: number) => {
  return [`/flashcard/flashcard/${id}/status-count`] as const;
};

export const getFlashcardFlashcardStatusCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFlashcardFlashcardStatusCountRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>
  > = ({ signal }) => flashcardFlashcardStatusCountRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type FlashcardFlashcardStatusCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>
>;
export type FlashcardFlashcardStatusCountRetrieveQueryError = ErrorType<unknown>;

export function useFlashcardFlashcardStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useFlashcardFlashcardStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useFlashcardFlashcardStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 총 플래시 카드 수 확인
 */

export function useFlashcardFlashcardStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof flashcardFlashcardStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getFlashcardFlashcardStatusCountRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeAssignmentList = (
  params?: GradeAssignmentListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedAssignmentListList>(
    { url: `/grade/assignment`, method: 'GET', params, signal },
    options,
  );
};

export const getGradeAssignmentListQueryKey = (params?: GradeAssignmentListParams) => {
  return [`/grade/assignment`, ...(params ? [params] : [])] as const;
};

export const getGradeAssignmentListQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeAssignmentList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeAssignmentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeAssignmentListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeAssignmentList>>> = ({ signal }) =>
    gradeAssignmentList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeAssignmentList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeAssignmentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeAssignmentList>>
>;
export type GradeAssignmentListQueryError = ErrorType<unknown>;

export function useGradeAssignmentList<
  TData = Awaited<ReturnType<typeof gradeAssignmentList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GradeAssignmentListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeAssignmentList<
  TData = Awaited<ReturnType<typeof gradeAssignmentList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeAssignmentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeAssignmentList<
  TData = Awaited<ReturnType<typeof gradeAssignmentList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeAssignmentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeAssignmentList<
  TData = Awaited<ReturnType<typeof gradeAssignmentList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeAssignmentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeAssignmentListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeAssignmentCreate = (
  assignmentWrite: BodyType<AssignmentWrite>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AssignmentRead>(
    {
      url: `/grade/assignment`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentWrite,
      signal,
    },
    options,
  );
};

export const getGradeAssignmentCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeAssignmentCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<AssignmentWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeAssignmentCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeAssignmentCreate>>,
    { data: BodyType<AssignmentWrite> }
  > = (props) => {
    const { data } = props ?? {};

    return gradeAssignmentCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<AssignmentWrite> },
    TContext
  >;
};

export type GradeAssignmentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeAssignmentCreate>>
>;
export type GradeAssignmentCreateMutationBody = BodyType<AssignmentWrite>;
export type GradeAssignmentCreateMutationError = ErrorType<unknown>;

export const useGradeAssignmentCreate = <
  TData = Awaited<ReturnType<typeof gradeAssignmentCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<AssignmentWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<AssignmentWrite> }, TContext> => {
  const mutationOptions = getGradeAssignmentCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeAssignmentRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AssignmentRead>(
    { url: `/grade/assignment/${id}`, method: 'GET', signal },
    options,
  );
};

export const getGradeAssignmentRetrieveQueryKey = (id: number) => {
  return [`/grade/assignment/${id}`] as const;
};

export const getGradeAssignmentRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeAssignmentRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeAssignmentRetrieve>>> = ({
    signal,
  }) => gradeAssignmentRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeAssignmentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeAssignmentRetrieve>>
>;
export type GradeAssignmentRetrieveQueryError = ErrorType<unknown>;

export function useGradeAssignmentRetrieve<
  TData = Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeAssignmentRetrieve<
  TData = Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeAssignmentRetrieve<
  TData = Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeAssignmentRetrieve<
  TData = Awaited<ReturnType<typeof gradeAssignmentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeAssignmentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeAssignmentRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeAssignmentPartialUpdate = (
  id: number,
  patchedAssignmentWrite: BodyType<PatchedAssignmentWrite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AssignmentRead>(
    {
      url: `/grade/assignment/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAssignmentWrite,
    },
    options,
  );
};

export const getGradeAssignmentPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeAssignmentPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedAssignmentWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeAssignmentPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeAssignmentPartialUpdate>>,
    { id: number; data: BodyType<PatchedAssignmentWrite> }
  > = (props) => {
    const { id, data } = props ?? {};

    return gradeAssignmentPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedAssignmentWrite> },
    TContext
  >;
};

export type GradeAssignmentPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeAssignmentPartialUpdate>>
>;
export type GradeAssignmentPartialUpdateMutationBody = BodyType<PatchedAssignmentWrite>;
export type GradeAssignmentPartialUpdateMutationError = ErrorType<unknown>;

export const useGradeAssignmentPartialUpdate = <
  TData = Awaited<ReturnType<typeof gradeAssignmentPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedAssignmentWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedAssignmentWrite> },
  TContext
> => {
  const mutationOptions = getGradeAssignmentPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeAssignmentDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/grade/assignment/${id}`, method: 'DELETE' }, options);
};

export const getGradeAssignmentDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeAssignmentDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeAssignmentDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeAssignmentDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return gradeAssignmentDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type GradeAssignmentDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeAssignmentDestroy>>
>;

export type GradeAssignmentDestroyMutationError = ErrorType<unknown>;

export const useGradeAssignmentDestroy = <
  TData = Awaited<ReturnType<typeof gradeAssignmentDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getGradeAssignmentDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeExamInfoList = (
  params?: GradeExamInfoListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedExamInfoListList>(
    { url: `/grade/exam_info`, method: 'GET', params, signal },
    options,
  );
};

export const getGradeExamInfoListQueryKey = (params?: GradeExamInfoListParams) => {
  return [`/grade/exam_info`, ...(params ? [params] : [])] as const;
};

export const getGradeExamInfoListQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeExamInfoList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeExamInfoListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeExamInfoList>>> = ({ signal }) =>
    gradeExamInfoList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeExamInfoList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeExamInfoListQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeExamInfoList>>
>;
export type GradeExamInfoListQueryError = ErrorType<unknown>;

export function useGradeExamInfoList<
  TData = Awaited<ReturnType<typeof gradeExamInfoList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GradeExamInfoListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeExamInfoList<
  TData = Awaited<ReturnType<typeof gradeExamInfoList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeExamInfoList<
  TData = Awaited<ReturnType<typeof gradeExamInfoList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeExamInfoList<
  TData = Awaited<ReturnType<typeof gradeExamInfoList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeExamInfoListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeExamInfoCreate = (
  examInfoWrite: BodyType<ExamInfoWrite>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ExamInfoRead>(
    {
      url: `/grade/exam_info`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: examInfoWrite,
      signal,
    },
    options,
  );
};

export const getGradeExamInfoCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeExamInfoCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<ExamInfoWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeExamInfoCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeExamInfoCreate>>,
    { data: BodyType<ExamInfoWrite> }
  > = (props) => {
    const { data } = props ?? {};

    return gradeExamInfoCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<ExamInfoWrite> },
    TContext
  >;
};

export type GradeExamInfoCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeExamInfoCreate>>
>;
export type GradeExamInfoCreateMutationBody = BodyType<ExamInfoWrite>;
export type GradeExamInfoCreateMutationError = ErrorType<unknown>;

export const useGradeExamInfoCreate = <
  TData = Awaited<ReturnType<typeof gradeExamInfoCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<ExamInfoWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<ExamInfoWrite> }, TContext> => {
  const mutationOptions = getGradeExamInfoCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeExamInfoRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ExamInfoRead>(
    { url: `/grade/exam_info/${id}`, method: 'GET', signal },
    options,
  );
};

export const getGradeExamInfoRetrieveQueryKey = (id: number) => {
  return [`/grade/exam_info/${id}`] as const;
};

export const getGradeExamInfoRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeExamInfoRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>> = ({ signal }) =>
    gradeExamInfoRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeExamInfoRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeExamInfoRetrieve>>
>;
export type GradeExamInfoRetrieveQueryError = ErrorType<unknown>;

export function useGradeExamInfoRetrieve<
  TData = Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeExamInfoRetrieve<
  TData = Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeExamInfoRetrieve<
  TData = Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeExamInfoRetrieve<
  TData = Awaited<ReturnType<typeof gradeExamInfoRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeExamInfoRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeExamInfoPartialUpdate = (
  id: number,
  patchedExamInfoWrite: BodyType<PatchedExamInfoWrite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ExamInfoRead>(
    {
      url: `/grade/exam_info/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedExamInfoWrite,
    },
    options,
  );
};

export const getGradeExamInfoPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeExamInfoPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedExamInfoWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeExamInfoPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeExamInfoPartialUpdate>>,
    { id: number; data: BodyType<PatchedExamInfoWrite> }
  > = (props) => {
    const { id, data } = props ?? {};

    return gradeExamInfoPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedExamInfoWrite> },
    TContext
  >;
};

export type GradeExamInfoPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeExamInfoPartialUpdate>>
>;
export type GradeExamInfoPartialUpdateMutationBody = BodyType<PatchedExamInfoWrite>;
export type GradeExamInfoPartialUpdateMutationError = ErrorType<unknown>;

export const useGradeExamInfoPartialUpdate = <
  TData = Awaited<ReturnType<typeof gradeExamInfoPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedExamInfoWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedExamInfoWrite> },
  TContext
> => {
  const mutationOptions = getGradeExamInfoPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeExamInfoDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/grade/exam_info/${id}`, method: 'DELETE' }, options);
};

export const getGradeExamInfoDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeExamInfoDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeExamInfoDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeExamInfoDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return gradeExamInfoDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type GradeExamInfoDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeExamInfoDestroy>>
>;

export type GradeExamInfoDestroyMutationError = ErrorType<unknown>;

export const useGradeExamInfoDestroy = <
  TData = Awaited<ReturnType<typeof gradeExamInfoDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getGradeExamInfoDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeExamInfoGroupByCourseList = (
  params?: GradeExamInfoGroupByCourseListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedExamInfoGroupedCourseList>(
    { url: `/grade/exam_info/group-by-course`, method: 'GET', params, signal },
    options,
  );
};

export const getGradeExamInfoGroupByCourseListQueryKey = (
  params?: GradeExamInfoGroupByCourseListParams,
) => {
  return [`/grade/exam_info/group-by-course`, ...(params ? [params] : [])] as const;
};

export const getGradeExamInfoGroupByCourseListQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeExamInfoGroupByCourseListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>> = ({
    signal,
  }) => gradeExamInfoGroupByCourseList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeExamInfoGroupByCourseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>
>;
export type GradeExamInfoGroupByCourseListQueryError = ErrorType<unknown>;

export function useGradeExamInfoGroupByCourseList<
  TData = Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GradeExamInfoGroupByCourseListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeExamInfoGroupByCourseList<
  TData = Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeExamInfoGroupByCourseList<
  TData = Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeExamInfoGroupByCourseList<
  TData = Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeExamInfoGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeExamInfoGroupByCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeExamInfoGroupByCourseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterList = (
  params?: GradeSemesterListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedSemesterList>(
    { url: `/grade/semester`, method: 'GET', params, signal },
    options,
  );
};

export const getGradeSemesterListQueryKey = (params?: GradeSemesterListParams) => {
  return [`/grade/semester`, ...(params ? [params] : [])] as const;
};

export const getGradeSemesterListQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterList>>> = ({ signal }) =>
    gradeSemesterList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterListQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterList>>
>;
export type GradeSemesterListQueryError = ErrorType<unknown>;

export function useGradeSemesterList<
  TData = Awaited<ReturnType<typeof gradeSemesterList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GradeSemesterListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterList<
  TData = Awaited<ReturnType<typeof gradeSemesterList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterList<
  TData = Awaited<ReturnType<typeof gradeSemesterList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterList<
  TData = Awaited<ReturnType<typeof gradeSemesterList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterCreate = (
  semester: BodyType<NonReadonly<Semester>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Semester>(
    {
      url: `/grade/semester`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: semester,
      signal,
    },
    options,
  );
};

export const getGradeSemesterCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Semester>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterCreate>>,
    { data: BodyType<NonReadonly<Semester>> }
  > = (props) => {
    const { data } = props ?? {};

    return gradeSemesterCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<Semester>> },
    TContext
  >;
};

export type GradeSemesterCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterCreate>>
>;
export type GradeSemesterCreateMutationBody = BodyType<NonReadonly<Semester>>;
export type GradeSemesterCreateMutationError = ErrorType<unknown>;

export const useGradeSemesterCreate = <
  TData = Awaited<ReturnType<typeof gradeSemesterCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Semester>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<Semester>> }, TContext> => {
  const mutationOptions = getGradeSemesterCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Semester>({ url: `/grade/semester/${id}`, method: 'GET', signal }, options);
};

export const getGradeSemesterRetrieveQueryKey = (id: number) => {
  return [`/grade/semester/${id}`] as const;
};

export const getGradeSemesterRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterRetrieve>>> = ({ signal }) =>
    gradeSemesterRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterRetrieve>>
>;
export type GradeSemesterRetrieveQueryError = ErrorType<unknown>;

export function useGradeSemesterRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof gradeSemesterRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterPartialUpdate = (
  id: number,
  patchedSemester: BodyType<NonReadonly<PatchedSemester>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Semester>(
    {
      url: `/grade/semester/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSemester,
    },
    options,
  );
};

export const getGradeSemesterPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemester>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedSemester>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return gradeSemesterPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemester>> },
    TContext
  >;
};

export type GradeSemesterPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterPartialUpdate>>
>;
export type GradeSemesterPartialUpdateMutationBody = BodyType<NonReadonly<PatchedSemester>>;
export type GradeSemesterPartialUpdateMutationError = ErrorType<unknown>;

export const useGradeSemesterPartialUpdate = <
  TData = Awaited<ReturnType<typeof gradeSemesterPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemester>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSemester>> },
  TContext
> => {
  const mutationOptions = getGradeSemesterPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/grade/semester/${id}`, method: 'DELETE' }, options);
};

export const getGradeSemesterDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return gradeSemesterDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type GradeSemesterDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterDestroy>>
>;

export type GradeSemesterDestroyMutationError = ErrorType<unknown>;

export const useGradeSemesterDestroy = <
  TData = Awaited<ReturnType<typeof gradeSemesterDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getGradeSemesterDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterCoursesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Semester>(
    { url: `/grade/semester/${id}/courses`, method: 'GET', signal },
    options,
  );
};

export const getGradeSemesterCoursesRetrieveQueryKey = (id: number) => {
  return [`/grade/semester/${id}/courses`] as const;
};

export const getGradeSemesterCoursesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterCoursesRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>> = ({
    signal,
  }) => gradeSemesterCoursesRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterCoursesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>
>;
export type GradeSemesterCoursesRetrieveQueryError = ErrorType<unknown>;

export function useGradeSemesterCoursesRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterCoursesRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterCoursesRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterCoursesRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterCoursesRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterCoursesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterExamList = (
  params?: GradeSemesterExamListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedSemesterExamList>(
    { url: `/grade/semester_exam`, method: 'GET', params, signal },
    options,
  );
};

export const getGradeSemesterExamListQueryKey = (params?: GradeSemesterExamListParams) => {
  return [`/grade/semester_exam`, ...(params ? [params] : [])] as const;
};

export const getGradeSemesterExamListQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterExamListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterExamListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterExamList>>> = ({ signal }) =>
    gradeSemesterExamList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterExamList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterExamListQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterExamList>>
>;
export type GradeSemesterExamListQueryError = ErrorType<unknown>;

export function useGradeSemesterExamList<
  TData = Awaited<ReturnType<typeof gradeSemesterExamList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GradeSemesterExamListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof gradeSemesterExamList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterExamList<
  TData = Awaited<ReturnType<typeof gradeSemesterExamList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterExamListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterExamList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterExamList<
  TData = Awaited<ReturnType<typeof gradeSemesterExamList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterExamListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterExamList<
  TData = Awaited<ReturnType<typeof gradeSemesterExamList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterExamListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterExamListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterExamCreate = (
  semesterExam: BodyType<NonReadonly<SemesterExam>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SemesterExam>(
    {
      url: `/grade/semester_exam`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: semesterExam,
      signal,
    },
    options,
  );
};

export const getGradeSemesterExamCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SemesterExam>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterExamCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterExamCreate>>,
    { data: BodyType<NonReadonly<SemesterExam>> }
  > = (props) => {
    const { data } = props ?? {};

    return gradeSemesterExamCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SemesterExam>> },
    TContext
  >;
};

export type GradeSemesterExamCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterExamCreate>>
>;
export type GradeSemesterExamCreateMutationBody = BodyType<NonReadonly<SemesterExam>>;
export type GradeSemesterExamCreateMutationError = ErrorType<unknown>;

export const useGradeSemesterExamCreate = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SemesterExam>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<SemesterExam>> }, TContext> => {
  const mutationOptions = getGradeSemesterExamCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterExamRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SemesterExam>(
    { url: `/grade/semester_exam/${id}`, method: 'GET', signal },
    options,
  );
};

export const getGradeSemesterExamRetrieveQueryKey = (id: number) => {
  return [`/grade/semester_exam/${id}`] as const;
};

export const getGradeSemesterExamRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterExamRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>> = ({
    signal,
  }) => gradeSemesterExamRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterExamRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>
>;
export type GradeSemesterExamRetrieveQueryError = ErrorType<unknown>;

export function useGradeSemesterExamRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterExamRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterExamRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterExamRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterExamRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterExamRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterExamPartialUpdate = (
  id: number,
  patchedSemesterExam: BodyType<NonReadonly<PatchedSemesterExam>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SemesterExam>(
    {
      url: `/grade/semester_exam/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSemesterExam,
    },
    options,
  );
};

export const getGradeSemesterExamPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterExam>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterExamPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterExamPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterExam>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return gradeSemesterExamPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterExam>> },
    TContext
  >;
};

export type GradeSemesterExamPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterExamPartialUpdate>>
>;
export type GradeSemesterExamPartialUpdateMutationBody = BodyType<NonReadonly<PatchedSemesterExam>>;
export type GradeSemesterExamPartialUpdateMutationError = ErrorType<unknown>;

export const useGradeSemesterExamPartialUpdate = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterExam>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSemesterExam>> },
  TContext
> => {
  const mutationOptions = getGradeSemesterExamPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterExamDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/grade/semester_exam/${id}`, method: 'DELETE' }, options);
};

export const getGradeSemesterExamDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterExamDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterExamDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return gradeSemesterExamDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type GradeSemesterExamDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterExamDestroy>>
>;

export type GradeSemesterExamDestroyMutationError = ErrorType<unknown>;

export const useGradeSemesterExamDestroy = <
  TData = Awaited<ReturnType<typeof gradeSemesterExamDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getGradeSemesterExamDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterSubjectList = (
  params?: GradeSemesterSubjectListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedSemesterSubjectList>(
    { url: `/grade/semester_subject`, method: 'GET', params, signal },
    options,
  );
};

export const getGradeSemesterSubjectListQueryKey = (params?: GradeSemesterSubjectListParams) => {
  return [`/grade/semester_subject`, ...(params ? [params] : [])] as const;
};

export const getGradeSemesterSubjectListQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterSubjectListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterSubjectList>>> = ({
    signal,
  }) => gradeSemesterSubjectList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterSubjectListQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterSubjectList>>
>;
export type GradeSemesterSubjectListQueryError = ErrorType<unknown>;

export function useGradeSemesterSubjectList<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GradeSemesterSubjectListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterSubjectList<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterSubjectList<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterSubjectList<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: GradeSemesterSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterSubjectListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterSubjectCreate = (
  semesterSubject: BodyType<NonReadonly<SemesterSubject>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SemesterSubject>(
    {
      url: `/grade/semester_subject`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: semesterSubject,
      signal,
    },
    options,
  );
};

export const getGradeSemesterSubjectCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SemesterSubject>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterSubjectCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterSubjectCreate>>,
    { data: BodyType<NonReadonly<SemesterSubject>> }
  > = (props) => {
    const { data } = props ?? {};

    return gradeSemesterSubjectCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SemesterSubject>> },
    TContext
  >;
};

export type GradeSemesterSubjectCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterSubjectCreate>>
>;
export type GradeSemesterSubjectCreateMutationBody = BodyType<NonReadonly<SemesterSubject>>;
export type GradeSemesterSubjectCreateMutationError = ErrorType<unknown>;

export const useGradeSemesterSubjectCreate = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SemesterSubject>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<NonReadonly<SemesterSubject>> },
  TContext
> => {
  const mutationOptions = getGradeSemesterSubjectCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterSubjectRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SemesterSubject>(
    { url: `/grade/semester_subject/${id}`, method: 'GET', signal },
    options,
  );
};

export const getGradeSemesterSubjectRetrieveQueryKey = (id: number) => {
  return [`/grade/semester_subject/${id}`] as const;
};

export const getGradeSemesterSubjectRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGradeSemesterSubjectRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>> = ({
    signal,
  }) => gradeSemesterSubjectRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GradeSemesterSubjectRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>
>;
export type GradeSemesterSubjectRetrieveQueryError = ErrorType<unknown>;

export function useGradeSemesterSubjectRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterSubjectRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGradeSemesterSubjectRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useGradeSemesterSubjectRetrieve<
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof gradeSemesterSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGradeSemesterSubjectRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const gradeSemesterSubjectPartialUpdate = (
  id: number,
  patchedSemesterSubject: BodyType<NonReadonly<PatchedSemesterSubject>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SemesterSubject>(
    {
      url: `/grade/semester_subject/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSemesterSubject,
    },
    options,
  );
};

export const getGradeSemesterSubjectPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterSubject>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterSubjectPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterSubjectPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterSubject>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return gradeSemesterSubjectPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterSubject>> },
    TContext
  >;
};

export type GradeSemesterSubjectPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterSubjectPartialUpdate>>
>;
export type GradeSemesterSubjectPartialUpdateMutationBody = BodyType<
  NonReadonly<PatchedSemesterSubject>
>;
export type GradeSemesterSubjectPartialUpdateMutationError = ErrorType<unknown>;

export const useGradeSemesterSubjectPartialUpdate = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSemesterSubject>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSemesterSubject>> },
  TContext
> => {
  const mutationOptions = getGradeSemesterSubjectPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const gradeSemesterSubjectDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/grade/semester_subject/${id}`, method: 'DELETE' }, options);
};

export const getGradeSemesterSubjectDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['gradeSemesterSubjectDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof gradeSemesterSubjectDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return gradeSemesterSubjectDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type GradeSemesterSubjectDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof gradeSemesterSubjectDestroy>>
>;

export type GradeSemesterSubjectDestroyMutationError = ErrorType<unknown>;

export const useGradeSemesterSubjectDestroy = <
  TData = Awaited<ReturnType<typeof gradeSemesterSubjectDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getGradeSemesterSubjectDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const qnasQnaList = (
  params?: QnasQnaListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQnaList>(
    { url: `/qnas/qna`, method: 'GET', params, signal },
    options,
  );
};

export const getQnasQnaListQueryKey = (params?: QnasQnaListParams) => {
  return [`/qnas/qna`, ...(params ? [params] : [])] as const;
};

export const getQnasQnaListQueryOptions = <
  TData = Awaited<ReturnType<typeof qnasQnaList>>,
  TError = ErrorType<unknown>,
>(
  params?: QnasQnaListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQnasQnaListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof qnasQnaList>>> = ({ signal }) =>
    qnasQnaList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof qnasQnaList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QnasQnaListQueryResult = NonNullable<Awaited<ReturnType<typeof qnasQnaList>>>;
export type QnasQnaListQueryError = ErrorType<unknown>;

export function useQnasQnaList<
  TData = Awaited<ReturnType<typeof qnasQnaList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QnasQnaListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQnasQnaList<
  TData = Awaited<ReturnType<typeof qnasQnaList>>,
  TError = ErrorType<unknown>,
>(
  params?: QnasQnaListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQnasQnaList<
  TData = Awaited<ReturnType<typeof qnasQnaList>>,
  TError = ErrorType<unknown>,
>(
  params?: QnasQnaListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQnasQnaList<
  TData = Awaited<ReturnType<typeof qnasQnaList>>,
  TError = ErrorType<unknown>,
>(
  params?: QnasQnaListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQnasQnaListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const qnasQnaCreate = (
  qna: BodyType<NonReadonly<Qna>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Qna>(
    {
      url: `/qnas/qna`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: qna,
      signal,
    },
    options,
  );
};

export const getQnasQnaCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof qnasQnaCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Qna>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['qnasQnaCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof qnasQnaCreate>>,
    { data: BodyType<NonReadonly<Qna>> }
  > = (props) => {
    const { data } = props ?? {};

    return qnasQnaCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<Qna>> },
    TContext
  >;
};

export type QnasQnaCreateMutationResult = NonNullable<Awaited<ReturnType<typeof qnasQnaCreate>>>;
export type QnasQnaCreateMutationBody = BodyType<NonReadonly<Qna>>;
export type QnasQnaCreateMutationError = ErrorType<unknown>;

export const useQnasQnaCreate = <
  TData = Awaited<ReturnType<typeof qnasQnaCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Qna>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<Qna>> }, TContext> => {
  const mutationOptions = getQnasQnaCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const qnasQnaRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Qna>({ url: `/qnas/qna/${id}`, method: 'GET', signal }, options);
};

export const getQnasQnaRetrieveQueryKey = (id: number) => {
  return [`/qnas/qna/${id}`] as const;
};

export const getQnasQnaRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof qnasQnaRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQnasQnaRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof qnasQnaRetrieve>>> = ({ signal }) =>
    qnasQnaRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof qnasQnaRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QnasQnaRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof qnasQnaRetrieve>>>;
export type QnasQnaRetrieveQueryError = ErrorType<unknown>;

export function useQnasQnaRetrieve<
  TData = Awaited<ReturnType<typeof qnasQnaRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQnasQnaRetrieve<
  TData = Awaited<ReturnType<typeof qnasQnaRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQnasQnaRetrieve<
  TData = Awaited<ReturnType<typeof qnasQnaRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQnasQnaRetrieve<
  TData = Awaited<ReturnType<typeof qnasQnaRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof qnasQnaRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQnasQnaRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const qnasQnaPartialUpdate = (
  id: number,
  patchedQna: BodyType<NonReadonly<PatchedQna>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Qna>(
    {
      url: `/qnas/qna/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedQna,
    },
    options,
  );
};

export const getQnasQnaPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof qnasQnaPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQna>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['qnasQnaPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof qnasQnaPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedQna>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return qnasQnaPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQna>> },
    TContext
  >;
};

export type QnasQnaPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof qnasQnaPartialUpdate>>
>;
export type QnasQnaPartialUpdateMutationBody = BodyType<NonReadonly<PatchedQna>>;
export type QnasQnaPartialUpdateMutationError = ErrorType<unknown>;

export const useQnasQnaPartialUpdate = <
  TData = Awaited<ReturnType<typeof qnasQnaPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQna>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedQna>> },
  TContext
> => {
  const mutationOptions = getQnasQnaPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const qnasQnaDestroy = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/qnas/qna/${id}`, method: 'DELETE' }, options);
};

export const getQnasQnaDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof qnasQnaDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['qnasQnaDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof qnasQnaDestroy>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {};

    return qnasQnaDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type QnasQnaDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof qnasQnaDestroy>>>;

export type QnasQnaDestroyMutationError = ErrorType<unknown>;

export const useQnasQnaDestroy = <
  TData = Awaited<ReturnType<typeof qnasQnaDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getQnasQnaDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankAttemptList = (
  params?: QuestionBankAttemptListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQuestionAttemptList>(
    { url: `/question_bank/attempt`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankAttemptListQueryKey = (params?: QuestionBankAttemptListParams) => {
  return [`/question_bank/attempt`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankAttemptListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankAttemptListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankAttemptList>>> = ({
    signal,
  }) => questionBankAttemptList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankAttemptList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankAttemptListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptList>>
>;
export type QuestionBankAttemptListQueryError = ErrorType<unknown>;

export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankAttemptListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankAttemptListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 새로운 문제 시도 생성
 */
export const questionBankAttemptCreate = (
  questionAttemptCreate: BodyType<QuestionAttemptCreate>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionAttempt>(
    {
      url: `/question_bank/attempt`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: questionAttemptCreate,
      signal,
    },
    options,
  );
};

export const getQuestionBankAttemptCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankAttemptCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<QuestionAttemptCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankAttemptCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankAttemptCreate>>,
    { data: BodyType<QuestionAttemptCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankAttemptCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<QuestionAttemptCreate> },
    TContext
  >;
};

export type QuestionBankAttemptCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptCreate>>
>;
export type QuestionBankAttemptCreateMutationBody = BodyType<QuestionAttemptCreate>;
export type QuestionBankAttemptCreateMutationError = ErrorType<unknown>;

export const useQuestionBankAttemptCreate = <
  TData = Awaited<ReturnType<typeof questionBankAttemptCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<QuestionAttemptCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<QuestionAttemptCreate> }, TContext> => {
  const mutationOptions = getQuestionBankAttemptCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankAttemptRetrieve = (
  questionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionAttempt>(
    { url: `/question_bank/attempt/${questionId}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankAttemptRetrieveQueryKey = (questionId: number) => {
  return [`/question_bank/attempt/${questionId}`] as const;
};

export const getQuestionBankAttemptRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankAttemptRetrieveQueryKey(questionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>> = ({
    signal,
  }) => questionBankAttemptRetrieve(questionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!questionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankAttemptRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptRetrieve>>
>;
export type QuestionBankAttemptRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankAttemptRetrieveQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 기존 시도 업데이트
 */
export const questionBankAttemptPartialUpdate = (
  questionId: number,
  patchedQuestionAttemptUpdate: BodyType<PatchedQuestionAttemptUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<QuestionAttempt>(
    {
      url: `/question_bank/attempt/${questionId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedQuestionAttemptUpdate,
    },
    options,
  );
};

export const getQuestionBankAttemptPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankAttemptPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> }
  > = (props) => {
    const { questionId, data } = props ?? {};

    return questionBankAttemptPartialUpdate(questionId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
    TContext
  >;
};

export type QuestionBankAttemptPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>
>;
export type QuestionBankAttemptPartialUpdateMutationBody = BodyType<PatchedQuestionAttemptUpdate>;
export type QuestionBankAttemptPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankAttemptPartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
  TContext
> => {
  const mutationOptions = getQuestionBankAttemptPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankCourseList = (
  params?: QuestionBankCourseListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Course[]>(
    { url: `/question_bank/course`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankCourseListQueryKey = (params?: QuestionBankCourseListParams) => {
  return [`/question_bank/course`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankCourseListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankCourseListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankCourseList>>> = ({ signal }) =>
    questionBankCourseList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankCourseList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankCourseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankCourseList>>
>;
export type QuestionBankCourseListQueryError = ErrorType<unknown>;

export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankCourseListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankCourseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankCourseRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Course>(
    { url: `/question_bank/course/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankCourseRetrieveQueryKey = (id: number) => {
  return [`/question_bank/course/${id}`] as const;
};

export const getQuestionBankCourseRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankCourseRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankCourseRetrieve>>> = ({
    signal,
  }) => questionBankCourseRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankCourseRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankCourseRetrieve>>
>;
export type QuestionBankCourseRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankCourseRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankCoursePartialUpdate = (
  id: number,
  patchedCourse: BodyType<NonReadonly<PatchedCourse>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Course>(
    {
      url: `/question_bank/course/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedCourse,
    },
    options,
  );
};

export const getQuestionBankCoursePartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankCoursePartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankCoursePartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
    TContext
  >;
};

export type QuestionBankCoursePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>
>;
export type QuestionBankCoursePartialUpdateMutationBody = BodyType<NonReadonly<PatchedCourse>>;
export type QuestionBankCoursePartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankCoursePartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
  TContext
> => {
  const mutationOptions = getQuestionBankCoursePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Subject 별 course_name 기준 그룹화 리스트
 */
export const questionBankGroupedSubjectsList = (
  params?: QuestionBankGroupedSubjectsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<GroupedSubject[]>(
    { url: `/question_bank/grouped_subjects`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankGroupedSubjectsListQueryKey = (
  params?: QuestionBankGroupedSubjectsListParams,
) => {
  return [`/question_bank/grouped_subjects`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankGroupedSubjectsListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankGroupedSubjectsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>> = ({
    signal,
  }) => questionBankGroupedSubjectsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankGroupedSubjectsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>
>;
export type QuestionBankGroupedSubjectsListQueryError = ErrorType<unknown>;

export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankGroupedSubjectsListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Subject 별 course_name 기준 그룹화 리스트
 */

export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankGroupedSubjectsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Subject 별 course_name 기준 그룹화
 */
export const questionBankGroupedSubjectsRetrieve = (
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<GroupedSubject>(
    { url: `/question_bank/grouped_subjects/${id}`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankGroupedSubjectsRetrieveQueryKey = (
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
) => {
  return [`/question_bank/grouped_subjects/${id}`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankGroupedSubjectsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getQuestionBankGroupedSubjectsRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>> = ({
    signal,
  }) => questionBankGroupedSubjectsRetrieve(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankGroupedSubjectsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>
>;
export type QuestionBankGroupedSubjectsRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: undefined | QuestionBankGroupedSubjectsRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Subject 별 course_name 기준 그룹화
 */

export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankGroupedSubjectsRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 문제 리스트
 */
export const questionBankQuestionList = (
  params?: QuestionBankQuestionListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQuestionReadList>(
    { url: `/question_bank/question`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankQuestionListQueryKey = (params?: QuestionBankQuestionListParams) => {
  return [`/question_bank/question`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankQuestionListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankQuestionListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankQuestionList>>> = ({
    signal,
  }) => questionBankQuestionList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankQuestionList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankQuestionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionList>>
>;
export type QuestionBankQuestionListQueryError = ErrorType<unknown>;

export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankQuestionListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankQuestionListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankQuestionCreate = (
  question: BodyType<NonReadonly<Question>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Question>(
    {
      url: `/question_bank/question`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: question,
      signal,
    },
    options,
  );
};

export const getQuestionBankQuestionCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Question>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankQuestionCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankQuestionCreate>>,
    { data: BodyType<NonReadonly<Question>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankQuestionCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<Question>> },
    TContext
  >;
};

export type QuestionBankQuestionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionCreate>>
>;
export type QuestionBankQuestionCreateMutationBody = BodyType<NonReadonly<Question>>;
export type QuestionBankQuestionCreateMutationError = ErrorType<unknown>;

export const useQuestionBankQuestionCreate = <
  TData = Awaited<ReturnType<typeof questionBankQuestionCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Question>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<Question>> }, TContext> => {
  const mutationOptions = getQuestionBankQuestionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankQuestionRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Question>(
    { url: `/question_bank/question/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankQuestionRetrieveQueryKey = (id: number) => {
  return [`/question_bank/question/${id}`] as const;
};

export const getQuestionBankQuestionRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankQuestionRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>> = ({
    signal,
  }) => questionBankQuestionRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankQuestionRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionRetrieve>>
>;
export type QuestionBankQuestionRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankQuestionRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankQuestionPartialUpdate = (
  id: number,
  patchedQuestion: BodyType<NonReadonly<PatchedQuestion>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Question>(
    {
      url: `/question_bank/question/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedQuestion,
    },
    options,
  );
};

export const getQuestionBankQuestionPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankQuestionPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankQuestionPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
    TContext
  >;
};

export type QuestionBankQuestionPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>
>;
export type QuestionBankQuestionPartialUpdateMutationBody = BodyType<NonReadonly<PatchedQuestion>>;
export type QuestionBankQuestionPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankQuestionPartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
  TContext
> => {
  const mutationOptions = getQuestionBankQuestionPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankQuestionDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/question/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankQuestionDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankQuestionDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankQuestionDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type QuestionBankQuestionDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionDestroy>>
>;

export type QuestionBankQuestionDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankQuestionDestroy = <
  TData = Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getQuestionBankQuestionDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Course 별 총 문제 수 (topic, subtopic), 내가 푼 문제 수
 * @summary 총 문제 수 확인
 */
export const questionBankQuestionStatusCountRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionSubjectOverview>(
    { url: `/question_bank/question/${id}/status-count`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankQuestionStatusCountRetrieveQueryKey = (id: number) => {
  return [`/question_bank/question/${id}/status-count`] as const;
};

export const getQuestionBankQuestionStatusCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankQuestionStatusCountRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>
  > = ({ signal }) => questionBankQuestionStatusCountRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankQuestionStatusCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>
>;
export type QuestionBankQuestionStatusCountRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 총 문제 수 확인
 */

export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankQuestionStatusCountRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 문제 오류 신고 목록 조회 (관리자)
 */
export const questionBankReportList = (
  params?: QuestionBankReportListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQuestionReportListList>(
    { url: `/question_bank/report`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankReportListQueryKey = (params?: QuestionBankReportListParams) => {
  return [`/question_bank/report`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankReportListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankReportListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankReportList>>> = ({ signal }) =>
    questionBankReportList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankReportList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankReportListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankReportList>>
>;
export type QuestionBankReportListQueryError = ErrorType<unknown>;

export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankReportListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankReportList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankReportList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 문제 오류 신고 목록 조회 (관리자)
 */

export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankReportListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 문제 오류 신고 생성 (사용자)
 */
export const questionBankReportCreate = (
  questionReport: BodyType<NonReadonly<QuestionReport>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionReport>(
    {
      url: `/question_bank/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: questionReport,
      signal,
    },
    options,
  );
};

export const getQuestionBankReportCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankReportCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<QuestionReport>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankReportCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankReportCreate>>,
    { data: BodyType<NonReadonly<QuestionReport>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankReportCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<QuestionReport>> },
    TContext
  >;
};

export type QuestionBankReportCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankReportCreate>>
>;
export type QuestionBankReportCreateMutationBody = BodyType<NonReadonly<QuestionReport>>;
export type QuestionBankReportCreateMutationError = ErrorType<unknown>;

/**
 * @summary 문제 오류 신고 생성 (사용자)
 */
export const useQuestionBankReportCreate = <
  TData = Awaited<ReturnType<typeof questionBankReportCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<QuestionReport>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<QuestionReport>> }, TContext> => {
  const mutationOptions = getQuestionBankReportCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankReportDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/report/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankReportDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankReportDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankReportDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankReportDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankReportDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type QuestionBankReportDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankReportDestroy>>
>;

export type QuestionBankReportDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankReportDestroy = <
  TData = Awaited<ReturnType<typeof questionBankReportDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getQuestionBankReportDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 선택된 course 리스트 (프론트엔드 내 로직 필요)
 * @summary 선택된 course 리스트
 */
export const questionBankSelectedCourseList = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CourseSelection[]>(
    { url: `/question_bank/selected_course`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankSelectedCourseListQueryKey = () => {
  return [`/question_bank/selected_course`] as const;
};

export const getQuestionBankSelectedCourseListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSelectedCourseListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSelectedCourseList>>> = ({
    signal,
  }) => questionBankSelectedCourseList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankSelectedCourseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSelectedCourseList>>
>;
export type QuestionBankSelectedCourseListQueryError = ErrorType<unknown>;

export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 선택된 course 리스트
 */

export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankSelectedCourseListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * course 선택 유무 업데이트 (프론트엔드 내 로직 필요)
 * @summary course 선택 유무
 */
export const questionBankSelectedCourseSelectPartialUpdate = (
  patchedCourseSelectionUpdate: BodyType<PatchedCourseSelectionUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CourseSelectionUpdate>(
    {
      url: `/question_bank/selected_course/select`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedCourseSelectionUpdate,
    },
    options,
  );
};

export const getQuestionBankSelectedCourseSelectPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<PatchedCourseSelectionUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankSelectedCourseSelectPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
    { data: BodyType<PatchedCourseSelectionUpdate> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankSelectedCourseSelectPartialUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<PatchedCourseSelectionUpdate> },
    TContext
  >;
};

export type QuestionBankSelectedCourseSelectPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>
>;
export type QuestionBankSelectedCourseSelectPartialUpdateMutationBody =
  BodyType<PatchedCourseSelectionUpdate>;
export type QuestionBankSelectedCourseSelectPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary course 선택 유무
 */
export const useQuestionBankSelectedCourseSelectPartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<PatchedCourseSelectionUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<PatchedCourseSelectionUpdate> },
  TContext
> => {
  const mutationOptions = getQuestionBankSelectedCourseSelectPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubjectList = (
  params?: QuestionBankSubjectListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubjectWithCourse[]>(
    { url: `/question_bank/subject`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankSubjectListQueryKey = (params?: QuestionBankSubjectListParams) => {
  return [`/question_bank/subject`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankSubjectListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubjectListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubjectList>>> = ({
    signal,
  }) => questionBankSubjectList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubjectList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankSubjectListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubjectList>>
>;
export type QuestionBankSubjectListQueryError = ErrorType<unknown>;

export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankSubjectListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankSubjectListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubjectRetrieve = (
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubjectWithCourse>(
    { url: `/question_bank/subject/${id}`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankSubjectRetrieveQueryKey = (
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
) => {
  return [`/question_bank/subject/${id}`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankSubjectRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubjectRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>> = ({
    signal,
  }) => questionBankSubjectRetrieve(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankSubjectRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubjectRetrieve>>
>;
export type QuestionBankSubjectRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: undefined | QuestionBankSubjectRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankSubjectRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubjectPartialUpdate = (
  id: number,
  patchedSubjectWithCourse: BodyType<NonReadonly<PatchedSubjectWithCourse>>,
  params?: QuestionBankSubjectPartialUpdateParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SubjectWithCourse>(
    {
      url: `/question_bank/subject/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSubjectWithCourse,
      params,
    },
    options,
  );
};

export const getQuestionBankSubjectPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubjectPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      id: number;
      data: BodyType<NonReadonly<PatchedSubjectWithCourse>>;
      params?: QuestionBankSubjectPartialUpdateParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankSubjectPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubjectPartialUpdate>>,
    {
      id: number;
      data: BodyType<NonReadonly<PatchedSubjectWithCourse>>;
      params?: QuestionBankSubjectPartialUpdateParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return questionBankSubjectPartialUpdate(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      id: number;
      data: BodyType<NonReadonly<PatchedSubjectWithCourse>>;
      params?: QuestionBankSubjectPartialUpdateParams;
    },
    TContext
  >;
};

export type QuestionBankSubjectPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubjectPartialUpdate>>
>;
export type QuestionBankSubjectPartialUpdateMutationBody = BodyType<
  NonReadonly<PatchedSubjectWithCourse>
>;
export type QuestionBankSubjectPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankSubjectPartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankSubjectPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      id: number;
      data: BodyType<NonReadonly<PatchedSubjectWithCourse>>;
      params?: QuestionBankSubjectPartialUpdateParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    id: number;
    data: BodyType<NonReadonly<PatchedSubjectWithCourse>>;
    params?: QuestionBankSubjectPartialUpdateParams;
  },
  TContext
> => {
  const mutationOptions = getQuestionBankSubjectPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubtopicList = (
  params?: QuestionBankSubtopicListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subtopic[]>(
    { url: `/question_bank/subtopic`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankSubtopicListQueryKey = (params?: QuestionBankSubtopicListParams) => {
  return [`/question_bank/subtopic`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankSubtopicListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubtopicListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubtopicList>>> = ({
    signal,
  }) => questionBankSubtopicList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubtopicList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankSubtopicListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicList>>
>;
export type QuestionBankSubtopicListQueryError = ErrorType<unknown>;

export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankSubtopicListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankSubtopicListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubtopicCreate = (
  subtopic: BodyType<NonReadonly<Subtopic>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subtopic>(
    {
      url: `/question_bank/subtopic`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subtopic,
      signal,
    },
    options,
  );
};

export const getQuestionBankSubtopicCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Subtopic>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankSubtopicCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
    { data: BodyType<NonReadonly<Subtopic>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankSubtopicCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<Subtopic>> },
    TContext
  >;
};

export type QuestionBankSubtopicCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicCreate>>
>;
export type QuestionBankSubtopicCreateMutationBody = BodyType<NonReadonly<Subtopic>>;
export type QuestionBankSubtopicCreateMutationError = ErrorType<unknown>;

export const useQuestionBankSubtopicCreate = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Subtopic>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<Subtopic>> }, TContext> => {
  const mutationOptions = getQuestionBankSubtopicCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubtopicRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subtopic>(
    { url: `/question_bank/subtopic/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankSubtopicRetrieveQueryKey = (id: number) => {
  return [`/question_bank/subtopic/${id}`] as const;
};

export const getQuestionBankSubtopicRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubtopicRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>> = ({
    signal,
  }) => questionBankSubtopicRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankSubtopicRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>
>;
export type QuestionBankSubtopicRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankSubtopicRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubtopicPartialUpdate = (
  id: number,
  patchedSubtopic: BodyType<NonReadonly<PatchedSubtopic>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Subtopic>(
    {
      url: `/question_bank/subtopic/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSubtopic,
    },
    options,
  );
};

export const getQuestionBankSubtopicPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankSubtopicPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankSubtopicPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
    TContext
  >;
};

export type QuestionBankSubtopicPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>
>;
export type QuestionBankSubtopicPartialUpdateMutationBody = BodyType<NonReadonly<PatchedSubtopic>>;
export type QuestionBankSubtopicPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankSubtopicPartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
  TContext
> => {
  const mutationOptions = getQuestionBankSubtopicPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubtopicDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/subtopic/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankSubtopicDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankSubtopicDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankSubtopicDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type QuestionBankSubtopicDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicDestroy>>
>;

export type QuestionBankSubtopicDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankSubtopicDestroy = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getQuestionBankSubtopicDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankTopicList = (
  params?: QuestionBankTopicListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Topic[]>(
    { url: `/question_bank/topic`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankTopicListQueryKey = (params?: QuestionBankTopicListParams) => {
  return [`/question_bank/topic`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankTopicListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankTopicListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankTopicList>>> = ({ signal }) =>
    questionBankTopicList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankTopicList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankTopicListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicList>>
>;
export type QuestionBankTopicListQueryError = ErrorType<unknown>;

export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankTopicListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankTopicList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankTopicListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankTopicCreate = (
  topic: BodyType<NonReadonly<Topic>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Topic>(
    {
      url: `/question_bank/topic`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: topic,
      signal,
    },
    options,
  );
};

export const getQuestionBankTopicCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Topic>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankTopicCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankTopicCreate>>,
    { data: BodyType<NonReadonly<Topic>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankTopicCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<Topic>> },
    TContext
  >;
};

export type QuestionBankTopicCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicCreate>>
>;
export type QuestionBankTopicCreateMutationBody = BodyType<NonReadonly<Topic>>;
export type QuestionBankTopicCreateMutationError = ErrorType<unknown>;

export const useQuestionBankTopicCreate = <
  TData = Awaited<ReturnType<typeof questionBankTopicCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<NonReadonly<Topic>> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<NonReadonly<Topic>> }, TContext> => {
  const mutationOptions = getQuestionBankTopicCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankTopicRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Topic>(
    { url: `/question_bank/topic/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankTopicRetrieveQueryKey = (id: number) => {
  return [`/question_bank/topic/${id}`] as const;
};

export const getQuestionBankTopicRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankTopicRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankTopicRetrieve>>> = ({
    signal,
  }) => questionBankTopicRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type QuestionBankTopicRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicRetrieve>>
>;
export type QuestionBankTopicRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getQuestionBankTopicRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankTopicPartialUpdate = (
  id: number,
  patchedTopic: BodyType<NonReadonly<PatchedTopic>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Topic>(
    {
      url: `/question_bank/topic/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTopic,
    },
    options,
  );
};

export const getQuestionBankTopicPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankTopicPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankTopicPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
    TContext
  >;
};

export type QuestionBankTopicPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>
>;
export type QuestionBankTopicPartialUpdateMutationBody = BodyType<NonReadonly<PatchedTopic>>;
export type QuestionBankTopicPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankTopicPartialUpdate = <
  TData = Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
  TContext
> => {
  const mutationOptions = getQuestionBankTopicPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankTopicDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/topic/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankTopicDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['questionBankTopicDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankTopicDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankTopicDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type QuestionBankTopicDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicDestroy>>
>;

export type QuestionBankTopicDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankTopicDestroy = <
  TData = Awaited<ReturnType<typeof questionBankTopicDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getQuestionBankTopicDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 캘린더 이벤트 목록 조회 (schedule + timetracking)
 */
export const scheduleCalendarEventList = (
  params?: ScheduleCalendarEventListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CalendarEvent[]>(
    { url: `/schedule/calendar_event`, method: 'GET', params, signal },
    options,
  );
};

export const getScheduleCalendarEventListQueryKey = (params?: ScheduleCalendarEventListParams) => {
  return [`/schedule/calendar_event`, ...(params ? [params] : [])] as const;
};

export const getScheduleCalendarEventListQueryOptions = <
  TData = Awaited<ReturnType<typeof scheduleCalendarEventList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleCalendarEventListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleCalendarEventList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getScheduleCalendarEventListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scheduleCalendarEventList>>> = ({
    signal,
  }) => scheduleCalendarEventList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scheduleCalendarEventList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type ScheduleCalendarEventListQueryResult = NonNullable<
  Awaited<ReturnType<typeof scheduleCalendarEventList>>
>;
export type ScheduleCalendarEventListQueryError = ErrorType<unknown>;

export function useScheduleCalendarEventList<
  TData = Awaited<ReturnType<typeof scheduleCalendarEventList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | ScheduleCalendarEventListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleCalendarEventList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleCalendarEventList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleCalendarEventList<
  TData = Awaited<ReturnType<typeof scheduleCalendarEventList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleCalendarEventListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleCalendarEventList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleCalendarEventList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleCalendarEventList<
  TData = Awaited<ReturnType<typeof scheduleCalendarEventList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleCalendarEventListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleCalendarEventList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 캘린더 이벤트 목록 조회 (schedule + timetracking)
 */

export function useScheduleCalendarEventList<
  TData = Awaited<ReturnType<typeof scheduleCalendarEventList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleCalendarEventListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleCalendarEventList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getScheduleCalendarEventListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const scheduleScheduleList = (
  params?: ScheduleScheduleListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedScheduleReadList>(
    { url: `/schedule/schedule`, method: 'GET', params, signal },
    options,
  );
};

export const getScheduleScheduleListQueryKey = (params?: ScheduleScheduleListParams) => {
  return [`/schedule/schedule`, ...(params ? [params] : [])] as const;
};

export const getScheduleScheduleListQueryOptions = <
  TData = Awaited<ReturnType<typeof scheduleScheduleList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleScheduleListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getScheduleScheduleListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scheduleScheduleList>>> = ({ signal }) =>
    scheduleScheduleList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scheduleScheduleList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type ScheduleScheduleListQueryResult = NonNullable<
  Awaited<ReturnType<typeof scheduleScheduleList>>
>;
export type ScheduleScheduleListQueryError = ErrorType<unknown>;

export function useScheduleScheduleList<
  TData = Awaited<ReturnType<typeof scheduleScheduleList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | ScheduleScheduleListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof scheduleScheduleList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleScheduleList<
  TData = Awaited<ReturnType<typeof scheduleScheduleList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleScheduleListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleScheduleList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleScheduleList<
  TData = Awaited<ReturnType<typeof scheduleScheduleList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleScheduleListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useScheduleScheduleList<
  TData = Awaited<ReturnType<typeof scheduleScheduleList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleScheduleListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getScheduleScheduleListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const scheduleScheduleCreate = (
  scheduleWrite: BodyType<ScheduleWrite>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ScheduleRead>(
    {
      url: `/schedule/schedule`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: scheduleWrite,
      signal,
    },
    options,
  );
};

export const getScheduleScheduleCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof scheduleScheduleCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<ScheduleWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['scheduleScheduleCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleScheduleCreate>>,
    { data: BodyType<ScheduleWrite> }
  > = (props) => {
    const { data } = props ?? {};

    return scheduleScheduleCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<ScheduleWrite> },
    TContext
  >;
};

export type ScheduleScheduleCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleScheduleCreate>>
>;
export type ScheduleScheduleCreateMutationBody = BodyType<ScheduleWrite>;
export type ScheduleScheduleCreateMutationError = ErrorType<unknown>;

export const useScheduleScheduleCreate = <
  TData = Awaited<ReturnType<typeof scheduleScheduleCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<ScheduleWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<ScheduleWrite> }, TContext> => {
  const mutationOptions = getScheduleScheduleCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const scheduleScheduleRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ScheduleRead>(
    { url: `/schedule/schedule/${id}`, method: 'GET', signal },
    options,
  );
};

export const getScheduleScheduleRetrieveQueryKey = (id: number) => {
  return [`/schedule/schedule/${id}`] as const;
};

export const getScheduleScheduleRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getScheduleScheduleRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scheduleScheduleRetrieve>>> = ({
    signal,
  }) => scheduleScheduleRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type ScheduleScheduleRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof scheduleScheduleRetrieve>>
>;
export type ScheduleScheduleRetrieveQueryError = ErrorType<unknown>;

export function useScheduleScheduleRetrieve<
  TData = Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleScheduleRetrieve<
  TData = Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleScheduleRetrieve<
  TData = Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useScheduleScheduleRetrieve<
  TData = Awaited<ReturnType<typeof scheduleScheduleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleScheduleRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getScheduleScheduleRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const scheduleSchedulePartialUpdate = (
  id: number,
  patchedScheduleWrite: BodyType<PatchedScheduleWrite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ScheduleRead>(
    {
      url: `/schedule/schedule/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedScheduleWrite,
    },
    options,
  );
};

export const getScheduleSchedulePartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof scheduleSchedulePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedScheduleWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['scheduleSchedulePartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleSchedulePartialUpdate>>,
    { id: number; data: BodyType<PatchedScheduleWrite> }
  > = (props) => {
    const { id, data } = props ?? {};

    return scheduleSchedulePartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedScheduleWrite> },
    TContext
  >;
};

export type ScheduleSchedulePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleSchedulePartialUpdate>>
>;
export type ScheduleSchedulePartialUpdateMutationBody = BodyType<PatchedScheduleWrite>;
export type ScheduleSchedulePartialUpdateMutationError = ErrorType<unknown>;

export const useScheduleSchedulePartialUpdate = <
  TData = Awaited<ReturnType<typeof scheduleSchedulePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedScheduleWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedScheduleWrite> },
  TContext
> => {
  const mutationOptions = getScheduleSchedulePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const scheduleScheduleDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/schedule/schedule/${id}`, method: 'DELETE' }, options);
};

export const getScheduleScheduleDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof scheduleScheduleDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['scheduleScheduleDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleScheduleDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return scheduleScheduleDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type ScheduleScheduleDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleScheduleDestroy>>
>;

export type ScheduleScheduleDestroyMutationError = ErrorType<unknown>;

export const useScheduleScheduleDestroy = <
  TData = Awaited<ReturnType<typeof scheduleScheduleDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getScheduleScheduleDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const scheduleTimeTrackingList = (
  params?: ScheduleTimeTrackingListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTimeTrackingReadList>(
    { url: `/schedule/time_tracking`, method: 'GET', params, signal },
    options,
  );
};

export const getScheduleTimeTrackingListQueryKey = (params?: ScheduleTimeTrackingListParams) => {
  return [`/schedule/time_tracking`, ...(params ? [params] : [])] as const;
};

export const getScheduleTimeTrackingListQueryOptions = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleTimeTrackingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getScheduleTimeTrackingListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scheduleTimeTrackingList>>> = ({
    signal,
  }) => scheduleTimeTrackingList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type ScheduleTimeTrackingListQueryResult = NonNullable<
  Awaited<ReturnType<typeof scheduleTimeTrackingList>>
>;
export type ScheduleTimeTrackingListQueryError = ErrorType<unknown>;

export function useScheduleTimeTrackingList<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | ScheduleTimeTrackingListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleTimeTrackingList<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleTimeTrackingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleTimeTrackingList<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleTimeTrackingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useScheduleTimeTrackingList<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingList>>,
  TError = ErrorType<unknown>,
>(
  params?: ScheduleTimeTrackingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getScheduleTimeTrackingListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const scheduleTimeTrackingCreate = (
  timeTrackingWrite: BodyType<TimeTrackingWrite>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TimeTrackingRead>(
    {
      url: `/schedule/time_tracking`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: timeTrackingWrite,
      signal,
    },
    options,
  );
};

export const getScheduleTimeTrackingCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<TimeTrackingWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['scheduleTimeTrackingCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleTimeTrackingCreate>>,
    { data: BodyType<TimeTrackingWrite> }
  > = (props) => {
    const { data } = props ?? {};

    return scheduleTimeTrackingCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<TimeTrackingWrite> },
    TContext
  >;
};

export type ScheduleTimeTrackingCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleTimeTrackingCreate>>
>;
export type ScheduleTimeTrackingCreateMutationBody = BodyType<TimeTrackingWrite>;
export type ScheduleTimeTrackingCreateMutationError = ErrorType<unknown>;

export const useScheduleTimeTrackingCreate = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<TimeTrackingWrite> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<TimeTrackingWrite> }, TContext> => {
  const mutationOptions = getScheduleTimeTrackingCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const scheduleTimeTrackingRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TimeTrackingRead>(
    { url: `/schedule/time_tracking/${id}`, method: 'GET', signal },
    options,
  );
};

export const getScheduleTimeTrackingRetrieveQueryKey = (id: number) => {
  return [`/schedule/time_tracking/${id}`] as const;
};

export const getScheduleTimeTrackingRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getScheduleTimeTrackingRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>> = ({
    signal,
  }) => scheduleTimeTrackingRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type ScheduleTimeTrackingRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>
>;
export type ScheduleTimeTrackingRetrieveQueryError = ErrorType<unknown>;

export function useScheduleTimeTrackingRetrieve<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleTimeTrackingRetrieve<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useScheduleTimeTrackingRetrieve<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useScheduleTimeTrackingRetrieve<
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof scheduleTimeTrackingRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getScheduleTimeTrackingRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const scheduleTimeTrackingPartialUpdate = (
  id: number,
  patchedTimeTrackingWrite: BodyType<PatchedTimeTrackingWrite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TimeTrackingRead>(
    {
      url: `/schedule/time_tracking/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTimeTrackingWrite,
    },
    options,
  );
};

export const getScheduleTimeTrackingPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTimeTrackingWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['scheduleTimeTrackingPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleTimeTrackingPartialUpdate>>,
    { id: number; data: BodyType<PatchedTimeTrackingWrite> }
  > = (props) => {
    const { id, data } = props ?? {};

    return scheduleTimeTrackingPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTimeTrackingWrite> },
    TContext
  >;
};

export type ScheduleTimeTrackingPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleTimeTrackingPartialUpdate>>
>;
export type ScheduleTimeTrackingPartialUpdateMutationBody = BodyType<PatchedTimeTrackingWrite>;
export type ScheduleTimeTrackingPartialUpdateMutationError = ErrorType<unknown>;

export const useScheduleTimeTrackingPartialUpdate = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTimeTrackingWrite> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedTimeTrackingWrite> },
  TContext
> => {
  const mutationOptions = getScheduleTimeTrackingPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const scheduleTimeTrackingDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/schedule/time_tracking/${id}`, method: 'DELETE' }, options);
};

export const getScheduleTimeTrackingDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['scheduleTimeTrackingDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof scheduleTimeTrackingDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return scheduleTimeTrackingDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type ScheduleTimeTrackingDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof scheduleTimeTrackingDestroy>>
>;

export type ScheduleTimeTrackingDestroyMutationError = ErrorType<unknown>;

export const useScheduleTimeTrackingDestroy = <
  TData = Awaited<ReturnType<typeof scheduleTimeTrackingDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getScheduleTimeTrackingDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionPaymentQuestionBankList = (
  params?: SubscriptionPaymentQuestionBankListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedChargeHistoryQBReadList>(
    { url: `/subscription/payment/question_bank`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionPaymentQuestionBankListQueryKey = (
  params?: SubscriptionPaymentQuestionBankListParams,
) => {
  return [`/subscription/payment/question_bank`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionPaymentQuestionBankListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionPaymentQuestionBankListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>> = ({
    signal,
  }) => subscriptionPaymentQuestionBankList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentQuestionBankListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>
>;
export type SubscriptionPaymentQuestionBankListQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionPaymentQuestionBankListParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentQuestionBankListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentQuestionBankRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ChargeHistoryQBRead>(
    { url: `/subscription/payment/question_bank/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionPaymentQuestionBankRetrieveQueryKey = (id: number) => {
  return [`/subscription/payment/question_bank/${id}`] as const;
};

export const getSubscriptionPaymentQuestionBankRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionPaymentQuestionBankRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>
  > = ({ signal }) => subscriptionPaymentQuestionBankRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentQuestionBankRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>
>;
export type SubscriptionPaymentQuestionBankRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentQuestionBankRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentQuestionBankAmountRetrieve = (
  params?: SubscriptionPaymentQuestionBankAmountRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionPaymentQuestionBankAmountRetrieve200>(
    { url: `/subscription/payment/question_bank/amount`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionPaymentQuestionBankAmountRetrieveQueryKey = (
  params?: SubscriptionPaymentQuestionBankAmountRetrieveParams,
) => {
  return [`/subscription/payment/question_bank/amount`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionPaymentQuestionBankAmountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSubscriptionPaymentQuestionBankAmountRetrieveQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>
  > = ({ signal }) => subscriptionPaymentQuestionBankAmountRetrieve(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentQuestionBankAmountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>
>;
export type SubscriptionPaymentQuestionBankAmountRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentQuestionBankAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionPaymentQuestionBankAmountRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentQuestionBankAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentQuestionBankAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentQuestionBankAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentQuestionBankAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentQuestionBankAmountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentQuestionBankAmountRetrieveQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentTutoringList = (
  params?: SubscriptionPaymentTutoringListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedChargeHistoryTutoringReadList>(
    { url: `/subscription/payment/tutoring`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionPaymentTutoringListQueryKey = (
  params?: SubscriptionPaymentTutoringListParams,
) => {
  return [`/subscription/payment/tutoring`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionPaymentTutoringListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionPaymentTutoringListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>> = ({
    signal,
  }) => subscriptionPaymentTutoringList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentTutoringListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>
>;
export type SubscriptionPaymentTutoringListQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionPaymentTutoringListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentTutoringListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentTutoringRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ChargeHistoryTutoringRead>(
    { url: `/subscription/payment/tutoring/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionPaymentTutoringRetrieveQueryKey = (id: number) => {
  return [`/subscription/payment/tutoring/${id}`] as const;
};

export const getSubscriptionPaymentTutoringRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionPaymentTutoringRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>> = ({
    signal,
  }) => subscriptionPaymentTutoringRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentTutoringRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>
>;
export type SubscriptionPaymentTutoringRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentTutoringRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentTutoringAmountRetrieve = (
  params?: SubscriptionPaymentTutoringAmountRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionPaymentTutoringAmountRetrieve200>(
    { url: `/subscription/payment/tutoring/amount`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionPaymentTutoringAmountRetrieveQueryKey = (
  params?: SubscriptionPaymentTutoringAmountRetrieveParams,
) => {
  return [`/subscription/payment/tutoring/amount`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionPaymentTutoringAmountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSubscriptionPaymentTutoringAmountRetrieveQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>
  > = ({ signal }) => subscriptionPaymentTutoringAmountRetrieve(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentTutoringAmountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>
>;
export type SubscriptionPaymentTutoringAmountRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentTutoringAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionPaymentTutoringAmountRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentTutoringAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentTutoringAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentTutoringAmountRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentTutoringAmountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof subscriptionPaymentTutoringAmountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentTutoringAmountRetrieveQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentMethodList = (
  params?: SubscriptionPaymentMethodListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentMethod[]>(
    { url: `/subscription/payment_method`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionPaymentMethodListQueryKey = (
  params?: SubscriptionPaymentMethodListParams,
) => {
  return [`/subscription/payment_method`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionPaymentMethodListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentMethodListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionPaymentMethodListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionPaymentMethodList>>> = ({
    signal,
  }) => subscriptionPaymentMethodList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentMethodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentMethodList>>
>;
export type SubscriptionPaymentMethodListQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentMethodList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionPaymentMethodListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentMethodList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentMethodListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentMethodList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentMethodListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentMethodList<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionPaymentMethodListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentMethodListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionPaymentMethodRetrieve = (
  paymentMethodId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaymentMethod>(
    { url: `/subscription/payment_method/${paymentMethodId}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionPaymentMethodRetrieveQueryKey = (paymentMethodId: string) => {
  return [`/subscription/payment_method/${paymentMethodId}`] as const;
};

export const getSubscriptionPaymentMethodRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
  TError = ErrorType<unknown>,
>(
  paymentMethodId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSubscriptionPaymentMethodRetrieveQueryKey(paymentMethodId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>> = ({
    signal,
  }) => subscriptionPaymentMethodRetrieve(paymentMethodId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!paymentMethodId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionPaymentMethodRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>
>;
export type SubscriptionPaymentMethodRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionPaymentMethodRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
  TError = ErrorType<unknown>,
>(
  paymentMethodId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentMethodRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
  TError = ErrorType<unknown>,
>(
  paymentMethodId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionPaymentMethodRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
  TError = ErrorType<unknown>,
>(
  paymentMethodId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionPaymentMethodRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>,
  TError = ErrorType<unknown>,
>(
  paymentMethodId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionPaymentMethodRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionPaymentMethodRetrieveQueryOptions(paymentMethodId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 구독 카드 변경
 */
export const subscriptionPaymentMethodPartialUpdate = (
  paymentMethodId: string,
  patchedPaymentMethodUpdate: BodyType<PatchedPaymentMethodUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PaymentMethod>(
    {
      url: `/subscription/payment_method/${paymentMethodId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedPaymentMethodUpdate,
    },
    options,
  );
};

export const getSubscriptionPaymentMethodPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { paymentMethodId: string; data: BodyType<PatchedPaymentMethodUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionPaymentMethodPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionPaymentMethodPartialUpdate>>,
    { paymentMethodId: string; data: BodyType<PatchedPaymentMethodUpdate> }
  > = (props) => {
    const { paymentMethodId, data } = props ?? {};

    return subscriptionPaymentMethodPartialUpdate(paymentMethodId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { paymentMethodId: string; data: BodyType<PatchedPaymentMethodUpdate> },
    TContext
  >;
};

export type SubscriptionPaymentMethodPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPaymentMethodPartialUpdate>>
>;
export type SubscriptionPaymentMethodPartialUpdateMutationBody =
  BodyType<PatchedPaymentMethodUpdate>;
export type SubscriptionPaymentMethodPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 구독 카드 변경
 */
export const useSubscriptionPaymentMethodPartialUpdate = <
  TData = Awaited<ReturnType<typeof subscriptionPaymentMethodPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { paymentMethodId: string; data: BodyType<PatchedPaymentMethodUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { paymentMethodId: string; data: BodyType<PatchedPaymentMethodUpdate> },
  TContext
> => {
  const mutationOptions = getSubscriptionPaymentMethodPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionProductsList = (
  params?: SubscriptionProductsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionProduct[]>(
    { url: `/subscription/products`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionProductsListQueryKey = (params?: SubscriptionProductsListParams) => {
  return [`/subscription/products`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionProductsListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionProductsList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionProductsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionProductsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionProductsList>>> = ({
    signal,
  }) => subscriptionProductsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionProductsList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionProductsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionProductsList>>
>;
export type SubscriptionProductsListQueryError = ErrorType<unknown>;

export function useSubscriptionProductsList<
  TData = Awaited<ReturnType<typeof subscriptionProductsList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionProductsListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionProductsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionProductsList<
  TData = Awaited<ReturnType<typeof subscriptionProductsList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionProductsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionProductsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionProductsList<
  TData = Awaited<ReturnType<typeof subscriptionProductsList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionProductsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionProductsList<
  TData = Awaited<ReturnType<typeof subscriptionProductsList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionProductsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionProductsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionProductsCreate = (
  subscriptionProduct: BodyType<NonReadonly<SubscriptionProduct>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionProduct>(
    {
      url: `/subscription/products`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subscriptionProduct,
      signal,
    },
    options,
  );
};

export const getSubscriptionProductsCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionProductsCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SubscriptionProduct>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionProductsCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionProductsCreate>>,
    { data: BodyType<NonReadonly<SubscriptionProduct>> }
  > = (props) => {
    const { data } = props ?? {};

    return subscriptionProductsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SubscriptionProduct>> },
    TContext
  >;
};

export type SubscriptionProductsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionProductsCreate>>
>;
export type SubscriptionProductsCreateMutationBody = BodyType<NonReadonly<SubscriptionProduct>>;
export type SubscriptionProductsCreateMutationError = ErrorType<unknown>;

export const useSubscriptionProductsCreate = <
  TData = Awaited<ReturnType<typeof subscriptionProductsCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SubscriptionProduct>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<NonReadonly<SubscriptionProduct>> },
  TContext
> => {
  const mutationOptions = getSubscriptionProductsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionProductsRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionProduct>(
    { url: `/subscription/products/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionProductsRetrieveQueryKey = (id: number) => {
  return [`/subscription/products/${id}`] as const;
};

export const getSubscriptionProductsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionProductsRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionProductsRetrieve>>> = ({
    signal,
  }) => subscriptionProductsRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionProductsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionProductsRetrieve>>
>;
export type SubscriptionProductsRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionProductsRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionProductsRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionProductsRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionProductsRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionProductsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionProductsRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionProductsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionProductsPartialUpdate = (
  id: number,
  patchedSubscriptionProduct: BodyType<NonReadonly<PatchedSubscriptionProduct>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SubscriptionProduct>(
    {
      url: `/subscription/products/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSubscriptionProduct,
    },
    options,
  );
};

export const getSubscriptionProductsPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionProductsPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubscriptionProduct>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionProductsPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionProductsPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedSubscriptionProduct>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return subscriptionProductsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubscriptionProduct>> },
    TContext
  >;
};

export type SubscriptionProductsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionProductsPartialUpdate>>
>;
export type SubscriptionProductsPartialUpdateMutationBody = BodyType<
  NonReadonly<PatchedSubscriptionProduct>
>;
export type SubscriptionProductsPartialUpdateMutationError = ErrorType<unknown>;

export const useSubscriptionProductsPartialUpdate = <
  TData = Awaited<ReturnType<typeof subscriptionProductsPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubscriptionProduct>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSubscriptionProduct>> },
  TContext
> => {
  const mutationOptions = getSubscriptionProductsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionProductsDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/subscription/products/${id}`, method: 'DELETE' }, options);
};

export const getSubscriptionProductsDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionProductsDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionProductsDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionProductsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return subscriptionProductsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type SubscriptionProductsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionProductsDestroy>>
>;

export type SubscriptionProductsDestroyMutationError = ErrorType<unknown>;

export const useSubscriptionProductsDestroy = <
  TData = Awaited<ReturnType<typeof subscriptionProductsDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getSubscriptionProductsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionQuestionBankList = (
  params?: SubscriptionQuestionBankListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedSubscriptionQBList>(
    { url: `/subscription/question_bank`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionQuestionBankListQueryKey = (
  params?: SubscriptionQuestionBankListParams,
) => {
  return [`/subscription/question_bank`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionQuestionBankListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionQuestionBankListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionQuestionBankList>>> = ({
    signal,
  }) => subscriptionQuestionBankList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionQuestionBankListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionQuestionBankList>>
>;
export type SubscriptionQuestionBankListQueryError = ErrorType<unknown>;

export function useSubscriptionQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionQuestionBankListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionQuestionBankList<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionQuestionBankListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionQuestionBankListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionQuestionBankRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionQB>(
    { url: `/subscription/question_bank/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionQuestionBankRetrieveQueryKey = (id: number) => {
  return [`/subscription/question_bank/${id}`] as const;
};

export const getSubscriptionQuestionBankRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionQuestionBankRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>> = ({
    signal,
  }) => subscriptionQuestionBankRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionQuestionBankRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>
>;
export type SubscriptionQuestionBankRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionQuestionBankRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionQuestionBankRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionQuestionBankRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionRequestCancelList = (
  params?: SubscriptionRequestCancelListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedSubscriptionCancellationRequestList>(
    { url: `/subscription/request_cancel`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionRequestCancelListQueryKey = (
  params?: SubscriptionRequestCancelListParams,
) => {
  return [`/subscription/request_cancel`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionRequestCancelListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionRequestCancelListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionRequestCancelListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionRequestCancelList>>> = ({
    signal,
  }) => subscriptionRequestCancelList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionRequestCancelListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionRequestCancelList>>
>;
export type SubscriptionRequestCancelListQueryError = ErrorType<unknown>;

export function useSubscriptionRequestCancelList<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionRequestCancelListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionRequestCancelList<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionRequestCancelListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionRequestCancelList<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionRequestCancelListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionRequestCancelList<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionRequestCancelListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionRequestCancelListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionRequestCancelCreate = (
  subscriptionCancellationRequest: BodyType<NonReadonly<SubscriptionCancellationRequest>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionCancellationRequest>(
    {
      url: `/subscription/request_cancel`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subscriptionCancellationRequest,
      signal,
    },
    options,
  );
};

export const getSubscriptionRequestCancelCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SubscriptionCancellationRequest>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionRequestCancelCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionRequestCancelCreate>>,
    { data: BodyType<NonReadonly<SubscriptionCancellationRequest>> }
  > = (props) => {
    const { data } = props ?? {};

    return subscriptionRequestCancelCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SubscriptionCancellationRequest>> },
    TContext
  >;
};

export type SubscriptionRequestCancelCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionRequestCancelCreate>>
>;
export type SubscriptionRequestCancelCreateMutationBody = BodyType<
  NonReadonly<SubscriptionCancellationRequest>
>;
export type SubscriptionRequestCancelCreateMutationError = ErrorType<unknown>;

export const useSubscriptionRequestCancelCreate = <
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<SubscriptionCancellationRequest>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<NonReadonly<SubscriptionCancellationRequest>> },
  TContext
> => {
  const mutationOptions = getSubscriptionRequestCancelCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionRequestCancelRetrieve = (
  subscriptionId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionCancellationRequest>(
    { url: `/subscription/request_cancel/${subscriptionId}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionRequestCancelRetrieveQueryKey = (subscriptionId: string) => {
  return [`/subscription/request_cancel/${subscriptionId}`] as const;
};

export const getSubscriptionRequestCancelRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
  TError = ErrorType<unknown>,
>(
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSubscriptionRequestCancelRetrieveQueryKey(subscriptionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>> = ({
    signal,
  }) => subscriptionRequestCancelRetrieve(subscriptionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!subscriptionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionRequestCancelRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>
>;
export type SubscriptionRequestCancelRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionRequestCancelRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
  TError = ErrorType<unknown>,
>(
  subscriptionId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionRequestCancelRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
  TError = ErrorType<unknown>,
>(
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionRequestCancelRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
  TError = ErrorType<unknown>,
>(
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionRequestCancelRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>,
  TError = ErrorType<unknown>,
>(
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionRequestCancelRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionRequestCancelRetrieveQueryOptions(subscriptionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionRequestCancelPartialUpdate = (
  subscriptionId: string,
  patchedSubscriptionCancellationRequestUpdate: BodyType<PatchedSubscriptionCancellationRequestUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SubscriptionCancellationRequest>(
    {
      url: `/subscription/request_cancel/${subscriptionId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSubscriptionCancellationRequestUpdate,
    },
    options,
  );
};

export const getSubscriptionRequestCancelPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { subscriptionId: string; data: BodyType<PatchedSubscriptionCancellationRequestUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionRequestCancelPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionRequestCancelPartialUpdate>>,
    { subscriptionId: string; data: BodyType<PatchedSubscriptionCancellationRequestUpdate> }
  > = (props) => {
    const { subscriptionId, data } = props ?? {};

    return subscriptionRequestCancelPartialUpdate(subscriptionId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { subscriptionId: string; data: BodyType<PatchedSubscriptionCancellationRequestUpdate> },
    TContext
  >;
};

export type SubscriptionRequestCancelPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionRequestCancelPartialUpdate>>
>;
export type SubscriptionRequestCancelPartialUpdateMutationBody =
  BodyType<PatchedSubscriptionCancellationRequestUpdate>;
export type SubscriptionRequestCancelPartialUpdateMutationError = ErrorType<unknown>;

export const useSubscriptionRequestCancelPartialUpdate = <
  TData = Awaited<ReturnType<typeof subscriptionRequestCancelPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { subscriptionId: string; data: BodyType<PatchedSubscriptionCancellationRequestUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { subscriptionId: string; data: BodyType<PatchedSubscriptionCancellationRequestUpdate> },
  TContext
> => {
  const mutationOptions = getSubscriptionRequestCancelPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary external_id의 경우 QB는 course_id, T는 recommend_id
 */
export const subscriptionSessionCreate = (
  session: BodyType<Session>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CheckoutResponse>(
    {
      url: `/subscription/session`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: session,
      signal,
    },
    options,
  );
};

export const getSubscriptionSessionCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof subscriptionSessionCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<Session> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['subscriptionSessionCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionSessionCreate>>,
    { data: BodyType<Session> }
  > = (props) => {
    const { data } = props ?? {};

    return subscriptionSessionCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<Session> },
    TContext
  >;
};

export type SubscriptionSessionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionSessionCreate>>
>;
export type SubscriptionSessionCreateMutationBody = BodyType<Session>;
export type SubscriptionSessionCreateMutationError = ErrorType<unknown>;

/**
 * @summary external_id의 경우 QB는 course_id, T는 recommend_id
 */
export const useSubscriptionSessionCreate = <
  TData = Awaited<ReturnType<typeof subscriptionSessionCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<Session> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<Session> }, TContext> => {
  const mutationOptions = getSubscriptionSessionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionTutoringList = (
  params?: SubscriptionTutoringListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedSubscriptionTutoringList>(
    { url: `/subscription/tutoring`, method: 'GET', params, signal },
    options,
  );
};

export const getSubscriptionTutoringListQueryKey = (params?: SubscriptionTutoringListParams) => {
  return [`/subscription/tutoring`, ...(params ? [params] : [])] as const;
};

export const getSubscriptionTutoringListQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionTutoringListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionTutoringList>>> = ({
    signal,
  }) => subscriptionTutoringList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionTutoringList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionTutoringListQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionTutoringList>>
>;
export type SubscriptionTutoringListQueryError = ErrorType<unknown>;

export function useSubscriptionTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | SubscriptionTutoringListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionTutoringList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionTutoringList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionTutoringList<
  TData = Awaited<ReturnType<typeof subscriptionTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: SubscriptionTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionTutoringListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const subscriptionTutoringRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<SubscriptionTutoring>(
    { url: `/subscription/tutoring/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSubscriptionTutoringRetrieveQueryKey = (id: number) => {
  return [`/subscription/tutoring/${id}`] as const;
};

export const getSubscriptionTutoringRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubscriptionTutoringRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>> = ({
    signal,
  }) => subscriptionTutoringRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type SubscriptionTutoringRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>
>;
export type SubscriptionTutoringRetrieveQueryError = ErrorType<unknown>;

export function useSubscriptionTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useSubscriptionTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useSubscriptionTutoringRetrieve<
  TData = Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof subscriptionTutoringRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getSubscriptionTutoringRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 세션 예약 리스트
 */
export const tutoringBookingList = (
  params?: TutoringBookingListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutoringBookingList>(
    { url: `/tutoring/booking`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringBookingListQueryKey = (params?: TutoringBookingListParams) => {
  return [`/tutoring/booking`, ...(params ? [params] : [])] as const;
};

export const getTutoringBookingListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringBookingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringBookingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringBookingListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringBookingList>>> = ({ signal }) =>
    tutoringBookingList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringBookingList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringBookingListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringBookingList>>
>;
export type TutoringBookingListQueryError = ErrorType<unknown>;

export function useTutoringBookingList<
  TData = Awaited<ReturnType<typeof tutoringBookingList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringBookingListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringBookingList<
  TData = Awaited<ReturnType<typeof tutoringBookingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringBookingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringBookingList<
  TData = Awaited<ReturnType<typeof tutoringBookingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringBookingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터링 세션 예약 리스트
 */

export function useTutoringBookingList<
  TData = Awaited<ReturnType<typeof tutoringBookingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringBookingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringBookingListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 세션 예약
 */
export const tutoringBookingCreate = (
  tutoringBookingCreate: BodyType<NonReadonly<TutoringBookingCreate>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringBookingCreate>(
    {
      url: `/tutoring/booking`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tutoringBookingCreate,
      signal,
    },
    options,
  );
};

export const getTutoringBookingCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringBookingCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TutoringBookingCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringBookingCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringBookingCreate>>,
    { data: BodyType<NonReadonly<TutoringBookingCreate>> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringBookingCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TutoringBookingCreate>> },
    TContext
  >;
};

export type TutoringBookingCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringBookingCreate>>
>;
export type TutoringBookingCreateMutationBody = BodyType<NonReadonly<TutoringBookingCreate>>;
export type TutoringBookingCreateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터링 세션 예약
 */
export const useTutoringBookingCreate = <
  TData = Awaited<ReturnType<typeof tutoringBookingCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TutoringBookingCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<NonReadonly<TutoringBookingCreate>> },
  TContext
> => {
  const mutationOptions = getTutoringBookingCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tutoringBookingRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringBooking>(
    { url: `/tutoring/booking/${id}`, method: 'GET', signal },
    options,
  );
};

export const getTutoringBookingRetrieveQueryKey = (id: number) => {
  return [`/tutoring/booking/${id}`] as const;
};

export const getTutoringBookingRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringBookingRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringBookingRetrieve>>> = ({
    signal,
  }) => tutoringBookingRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringBookingRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringBookingRetrieve>>
>;
export type TutoringBookingRetrieveQueryError = ErrorType<unknown>;

export function useTutoringBookingRetrieve<
  TData = Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringBookingRetrieve<
  TData = Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringBookingRetrieve<
  TData = Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringBookingRetrieve<
  TData = Awaited<ReturnType<typeof tutoringBookingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringBookingRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringBookingRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 세션 관련 업데이트
 */
export const tutoringBookingPartialUpdate = (
  id: number,
  patchedTutoringBookingUpdate: BodyType<PatchedTutoringBookingUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TutoringBookingUpdate>(
    {
      url: `/tutoring/booking/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutoringBookingUpdate,
    },
    options,
  );
};

export const getTutoringBookingPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringBookingPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTutoringBookingUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringBookingPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringBookingPartialUpdate>>,
    { id: number; data: BodyType<PatchedTutoringBookingUpdate> }
  > = (props) => {
    const { id, data } = props ?? {};

    return tutoringBookingPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTutoringBookingUpdate> },
    TContext
  >;
};

export type TutoringBookingPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringBookingPartialUpdate>>
>;
export type TutoringBookingPartialUpdateMutationBody = BodyType<PatchedTutoringBookingUpdate>;
export type TutoringBookingPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터링 세션 관련 업데이트
 */
export const useTutoringBookingPartialUpdate = <
  TData = Awaited<ReturnType<typeof tutoringBookingPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTutoringBookingUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedTutoringBookingUpdate> },
  TContext
> => {
  const mutationOptions = getTutoringBookingPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tutoringCommentList = (
  params?: TutoringCommentListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutoringCommentList>(
    { url: `/tutoring/comment`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringCommentListQueryKey = (params?: TutoringCommentListParams) => {
  return [`/tutoring/comment`, ...(params ? [params] : [])] as const;
};

export const getTutoringCommentListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringCommentList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringCommentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringCommentListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringCommentList>>> = ({ signal }) =>
    tutoringCommentList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringCommentList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringCommentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCommentList>>
>;
export type TutoringCommentListQueryError = ErrorType<unknown>;

export function useTutoringCommentList<
  TData = Awaited<ReturnType<typeof tutoringCommentList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringCommentListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCommentList<
  TData = Awaited<ReturnType<typeof tutoringCommentList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringCommentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCommentList<
  TData = Awaited<ReturnType<typeof tutoringCommentList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringCommentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringCommentList<
  TData = Awaited<ReturnType<typeof tutoringCommentList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringCommentListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringCommentListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const tutoringCommentCreate = (
  tutoringComment: BodyType<NonReadonly<TutoringComment>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringComment>(
    {
      url: `/tutoring/comment`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tutoringComment,
      signal,
    },
    options,
  );
};

export const getTutoringCommentCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringCommentCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TutoringComment>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringCommentCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringCommentCreate>>,
    { data: BodyType<NonReadonly<TutoringComment>> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringCommentCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TutoringComment>> },
    TContext
  >;
};

export type TutoringCommentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCommentCreate>>
>;
export type TutoringCommentCreateMutationBody = BodyType<NonReadonly<TutoringComment>>;
export type TutoringCommentCreateMutationError = ErrorType<unknown>;

export const useTutoringCommentCreate = <
  TData = Awaited<ReturnType<typeof tutoringCommentCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<TutoringComment>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<NonReadonly<TutoringComment>> },
  TContext
> => {
  const mutationOptions = getTutoringCommentCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tutoringCommentRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringComment>(
    { url: `/tutoring/comment/${id}`, method: 'GET', signal },
    options,
  );
};

export const getTutoringCommentRetrieveQueryKey = (id: number) => {
  return [`/tutoring/comment/${id}`] as const;
};

export const getTutoringCommentRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringCommentRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringCommentRetrieve>>> = ({
    signal,
  }) => tutoringCommentRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringCommentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCommentRetrieve>>
>;
export type TutoringCommentRetrieveQueryError = ErrorType<unknown>;

export function useTutoringCommentRetrieve<
  TData = Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCommentRetrieve<
  TData = Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCommentRetrieve<
  TData = Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringCommentRetrieve<
  TData = Awaited<ReturnType<typeof tutoringCommentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringCommentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringCommentRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const tutoringCommentPartialUpdate = (
  id: number,
  patchedTutoringComment: BodyType<NonReadonly<PatchedTutoringComment>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TutoringComment>(
    {
      url: `/tutoring/comment/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutoringComment,
    },
    options,
  );
};

export const getTutoringCommentPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringCommentPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTutoringComment>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringCommentPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringCommentPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedTutoringComment>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return tutoringCommentPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTutoringComment>> },
    TContext
  >;
};

export type TutoringCommentPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCommentPartialUpdate>>
>;
export type TutoringCommentPartialUpdateMutationBody = BodyType<
  NonReadonly<PatchedTutoringComment>
>;
export type TutoringCommentPartialUpdateMutationError = ErrorType<unknown>;

export const useTutoringCommentPartialUpdate = <
  TData = Awaited<ReturnType<typeof tutoringCommentPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTutoringComment>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedTutoringComment>> },
  TContext
> => {
  const mutationOptions = getTutoringCommentPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tutoringCommentDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/tutoring/comment/${id}`, method: 'DELETE' }, options);
};

export const getTutoringCommentDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringCommentDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringCommentDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringCommentDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return tutoringCommentDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type TutoringCommentDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCommentDestroy>>
>;

export type TutoringCommentDestroyMutationError = ErrorType<unknown>;

export const useTutoringCommentDestroy = <
  TData = Awaited<ReturnType<typeof tutoringCommentDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getTutoringCommentDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tutoringCourseSelectedCoursesList = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CourseTutoring[]>(
    { url: `/tutoring/course/selected-courses`, method: 'GET', signal },
    options,
  );
};

export const getTutoringCourseSelectedCoursesListQueryKey = () => {
  return [`/tutoring/course/selected-courses`] as const;
};

export const getTutoringCourseSelectedCoursesListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringCourseSelectedCoursesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>> = ({
    signal,
  }) => tutoringCourseSelectedCoursesList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringCourseSelectedCoursesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>
>;
export type TutoringCourseSelectedCoursesListQueryError = ErrorType<unknown>;

export function useTutoringCourseSelectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCourseSelectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCourseSelectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringCourseSelectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseSelectedCoursesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringCourseSelectedCoursesListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const tutoringCourseUnselectedCoursesList = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CourseTutoring[]>(
    { url: `/tutoring/course/unselected-courses`, method: 'GET', signal },
    options,
  );
};

export const getTutoringCourseUnselectedCoursesListQueryKey = () => {
  return [`/tutoring/course/unselected-courses`] as const;
};

export const getTutoringCourseUnselectedCoursesListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringCourseUnselectedCoursesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>> = ({
    signal,
  }) => tutoringCourseUnselectedCoursesList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringCourseUnselectedCoursesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>
>;
export type TutoringCourseUnselectedCoursesListQueryError = ErrorType<unknown>;

export function useTutoringCourseUnselectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCourseUnselectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringCourseUnselectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringCourseUnselectedCoursesList<
  TData = Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringCourseUnselectedCoursesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringCourseUnselectedCoursesListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Coach / Master / Specialist 가격 정보 불러오기
 */
export const tutoringPriceRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringPrice>({ url: `/tutoring/price`, method: 'GET', signal }, options);
};

export const getTutoringPriceRetrieveQueryKey = () => {
  return [`/tutoring/price`] as const;
};

export const getTutoringPriceRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringPriceRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringPriceRetrieveQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringPriceRetrieve>>> = ({ signal }) =>
    tutoringPriceRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringPriceRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringPriceRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringPriceRetrieve>>
>;
export type TutoringPriceRetrieveQueryError = ErrorType<unknown>;

export function useTutoringPriceRetrieve<
  TData = Awaited<ReturnType<typeof tutoringPriceRetrieve>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringPriceRetrieve<
  TData = Awaited<ReturnType<typeof tutoringPriceRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringPriceRetrieve<
  TData = Awaited<ReturnType<typeof tutoringPriceRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Coach / Master / Specialist 가격 정보 불러오기
 */

export function useTutoringPriceRetrieve<
  TData = Awaited<ReturnType<typeof tutoringPriceRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof tutoringPriceRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringPriceRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Coach / Master / Specialist 가격 정보 수정
 */
export const tutoringPricePartialUpdate = (
  patchedTutoringPrice: BodyType<PatchedTutoringPrice>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TutoringPrice>(
    {
      url: `/tutoring/price`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutoringPrice,
    },
    options,
  );
};

export const getTutoringPricePartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringPricePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<PatchedTutoringPrice> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringPricePartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringPricePartialUpdate>>,
    { data: BodyType<PatchedTutoringPrice> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringPricePartialUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<PatchedTutoringPrice> },
    TContext
  >;
};

export type TutoringPricePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringPricePartialUpdate>>
>;
export type TutoringPricePartialUpdateMutationBody = BodyType<PatchedTutoringPrice>;
export type TutoringPricePartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary Coach / Master / Specialist 가격 정보 수정
 */
export const useTutoringPricePartialUpdate = <
  TData = Awaited<ReturnType<typeof tutoringPricePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<PatchedTutoringPrice> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<PatchedTutoringPrice> }, TContext> => {
  const mutationOptions = getTutoringPricePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터 추천 리스트
 */
export const tutoringRecommendList = (
  params?: TutoringRecommendListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedRecommendTutorsReadList>(
    { url: `/tutoring/recommend`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringRecommendListQueryKey = (params?: TutoringRecommendListParams) => {
  return [`/tutoring/recommend`, ...(params ? [params] : [])] as const;
};

export const getTutoringRecommendListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRecommendList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRecommendListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRecommendList>>> = ({ signal }) =>
    tutoringRecommendList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRecommendList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringRecommendListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRecommendList>>
>;
export type TutoringRecommendListQueryError = ErrorType<unknown>;

export function useTutoringRecommendList<
  TData = Awaited<ReturnType<typeof tutoringRecommendList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringRecommendListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringRecommendList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRecommendList<
  TData = Awaited<ReturnType<typeof tutoringRecommendList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRecommendList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRecommendList<
  TData = Awaited<ReturnType<typeof tutoringRecommendList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 추천 리스트
 */

export function useTutoringRecommendList<
  TData = Awaited<ReturnType<typeof tutoringRecommendList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringRecommendListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 추천 리스트 생성
 */
export const tutoringRecommendCreate = (
  recommendTutorsCreate: BodyType<RecommendTutorsCreate>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RecommendTutorsRead>(
    {
      url: `/tutoring/recommend`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: recommendTutorsCreate,
      signal,
    },
    options,
  );
};

export const getTutoringRecommendCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringRecommendCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<RecommendTutorsCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringRecommendCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringRecommendCreate>>,
    { data: BodyType<RecommendTutorsCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringRecommendCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<RecommendTutorsCreate> },
    TContext
  >;
};

export type TutoringRecommendCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRecommendCreate>>
>;
export type TutoringRecommendCreateMutationBody = BodyType<RecommendTutorsCreate>;
export type TutoringRecommendCreateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 추천 리스트 생성
 */
export const useTutoringRecommendCreate = <
  TData = Awaited<ReturnType<typeof tutoringRecommendCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<RecommendTutorsCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<RecommendTutorsCreate> }, TContext> => {
  const mutationOptions = getTutoringRecommendCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터 추천 객체 반환
 */
export const tutoringRecommendRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RecommendTutorsRead>(
    { url: `/tutoring/recommend/${id}`, method: 'GET', signal },
    options,
  );
};

export const getTutoringRecommendRetrieveQueryKey = (id: number) => {
  return [`/tutoring/recommend/${id}`] as const;
};

export const getTutoringRecommendRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRecommendRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRecommendRetrieve>>> = ({
    signal,
  }) => tutoringRecommendRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringRecommendRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRecommendRetrieve>>
>;
export type TutoringRecommendRetrieveQueryError = ErrorType<unknown>;

export function useTutoringRecommendRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRecommendRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRecommendRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 추천 객체 반환
 */

export function useTutoringRecommendRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRecommendRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringRecommendRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 추천 리스트 상태값 변경
 */
export const tutoringRecommendPartialUpdate = (
  id: number,
  patchedRecommendTutorsUpdate: BodyType<PatchedRecommendTutorsUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RecommendTutorsRead>(
    {
      url: `/tutoring/recommend/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedRecommendTutorsUpdate,
    },
    options,
  );
};

export const getTutoringRecommendPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringRecommendPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedRecommendTutorsUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringRecommendPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringRecommendPartialUpdate>>,
    { id: number; data: BodyType<PatchedRecommendTutorsUpdate> }
  > = (props) => {
    const { id, data } = props ?? {};

    return tutoringRecommendPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedRecommendTutorsUpdate> },
    TContext
  >;
};

export type TutoringRecommendPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRecommendPartialUpdate>>
>;
export type TutoringRecommendPartialUpdateMutationBody = BodyType<PatchedRecommendTutorsUpdate>;
export type TutoringRecommendPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 추천 리스트 상태값 변경
 */
export const useTutoringRecommendPartialUpdate = <
  TData = Awaited<ReturnType<typeof tutoringRecommendPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedRecommendTutorsUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedRecommendTutorsUpdate> },
  TContext
> => {
  const mutationOptions = getTutoringRecommendPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary request와 course 기준으로 튜터 추천 그룹화
 */
export const tutoringRecommendGroupByCourseList = (
  params?: TutoringRecommendGroupByCourseListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedRecommendTutorsGroupedByCourseList>(
    { url: `/tutoring/recommend/group-by-course`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringRecommendGroupByCourseListQueryKey = (
  params?: TutoringRecommendGroupByCourseListParams,
) => {
  return [`/tutoring/recommend/group-by-course`, ...(params ? [params] : [])] as const;
};

export const getTutoringRecommendGroupByCourseListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRecommendGroupByCourseListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>> = ({
    signal,
  }) => tutoringRecommendGroupByCourseList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringRecommendGroupByCourseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>
>;
export type TutoringRecommendGroupByCourseListQueryError = ErrorType<unknown>;

export function useTutoringRecommendGroupByCourseList<
  TData = Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringRecommendGroupByCourseListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRecommendGroupByCourseList<
  TData = Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRecommendGroupByCourseList<
  TData = Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary request와 course 기준으로 튜터 추천 그룹화
 */

export function useTutoringRecommendGroupByCourseList<
  TData = Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRecommendGroupByCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRecommendGroupByCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringRecommendGroupByCourseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 신청 요청 리스트
 */
export const tutoringRequestList = (
  params?: TutoringRequestListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedRequestTutoringReadList>(
    { url: `/tutoring/request`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringRequestListQueryKey = (params?: TutoringRequestListParams) => {
  return [`/tutoring/request`, ...(params ? [params] : [])] as const;
};

export const getTutoringRequestListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRequestListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRequestList>>> = ({ signal }) =>
    tutoringRequestList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRequestList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringRequestListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestList>>
>;
export type TutoringRequestListQueryError = ErrorType<unknown>;

export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringRequestListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터링 신청 요청 리스트
 */

export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringRequestListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 신청서 생성
 */
export const tutoringRequestCreate = (
  requestTutoringCreate: BodyType<NonReadonly<RequestTutoringCreate>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RequestTutoringCreate>(
    {
      url: `/tutoring/request`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestTutoringCreate,
      signal,
    },
    options,
  );
};

export const getTutoringRequestCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringRequestCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringRequestCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringRequestCreate>>,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringRequestCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> },
    TContext
  >;
};

export type TutoringRequestCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestCreate>>
>;
export type TutoringRequestCreateMutationBody = BodyType<NonReadonly<RequestTutoringCreate>>;
export type TutoringRequestCreateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터링 신청서 생성
 */
export const useTutoringRequestCreate = <
  TData = Awaited<ReturnType<typeof tutoringRequestCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BodyType<NonReadonly<RequestTutoringCreate>> },
  TContext
> => {
  const mutationOptions = getTutoringRequestCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터링 신청서 상세 정보
 */
export const tutoringRequestRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RequestTutoringRead>(
    { url: `/tutoring/request/${id}`, method: 'GET', signal },
    options,
  );
};

export const getTutoringRequestRetrieveQueryKey = (id: number) => {
  return [`/tutoring/request/${id}`] as const;
};

export const getTutoringRequestRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRequestRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRequestRetrieve>>> = ({
    signal,
  }) => tutoringRequestRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringRequestRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestRetrieve>>
>;
export type TutoringRequestRetrieveQueryError = ErrorType<unknown>;

export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터링 신청서 상세 정보
 */

export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringRequestRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 요청 진행 상태 업데이트
 */
export const tutoringRequestPartialUpdate = (
  id: number,
  patchedRequestTutoringUpdate: BodyType<PatchedRequestTutoringUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RequestTutoringRead>(
    {
      url: `/tutoring/request/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedRequestTutoringUpdate,
    },
    options,
  );
};

export const getTutoringRequestPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedRequestTutoringUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringRequestPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
    { id: number; data: BodyType<PatchedRequestTutoringUpdate> }
  > = (props) => {
    const { id, data } = props ?? {};

    return tutoringRequestPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedRequestTutoringUpdate> },
    TContext
  >;
};

export type TutoringRequestPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>
>;
export type TutoringRequestPartialUpdateMutationBody = BodyType<PatchedRequestTutoringUpdate>;
export type TutoringRequestPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터링 요청 진행 상태 업데이트
 */
export const useTutoringRequestPartialUpdate = <
  TData = Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedRequestTutoringUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedRequestTutoringUpdate> },
  TContext
> => {
  const mutationOptions = getTutoringRequestPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터 별 튜터링 시간
 */
export const tutoringTimeRetrieve = (
  params: TutoringTimeRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringTime>(
    { url: `/tutoring/time`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringTimeRetrieveQueryKey = (params: TutoringTimeRetrieveParams) => {
  return [`/tutoring/time`, ...(params ? [params] : [])] as const;
};

export const getTutoringTimeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTimeRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTimeRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringTimeRetrieveQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringTimeRetrieve>>> = ({ signal }) =>
    tutoringTimeRetrieve(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTimeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTimeRetrieve>>
>;
export type TutoringTimeRetrieveQueryError = ErrorType<unknown>;

export function useTutoringTimeRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTimeRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTimeRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringTimeRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTimeRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTimeRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTimeRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTimeRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTimeRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTimeRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 별 튜터링 시간
 */

export function useTutoringTimeRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTimeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTimeRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTimeRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTimeRetrieveQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 리스트
 */
export const tutoringTutoringList = (
  params?: TutoringTutoringListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutoringListList>(
    { url: `/tutoring/tutoring`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringTutoringListQueryKey = (params?: TutoringTutoringListParams) => {
  return [`/tutoring/tutoring`, ...(params ? [params] : [])] as const;
};

export const getTutoringTutoringListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringTutoringListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringTutoringList>>> = ({ signal }) =>
    tutoringTutoringList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTutoringList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTutoringListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringList>>
>;
export type TutoringTutoringListQueryError = ErrorType<unknown>;

export function useTutoringTutoringList<
  TData = Awaited<ReturnType<typeof tutoringTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringTutoringListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringTutoringList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringList<
  TData = Awaited<ReturnType<typeof tutoringTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringList<
  TData = Awaited<ReturnType<typeof tutoringTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터링 리스트
 */

export function useTutoringTutoringList<
  TData = Awaited<ReturnType<typeof tutoringTutoringList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTutoringListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 갱신
 */
export const tutoringTutoringCreate = (
  tutoringCreate: BodyType<TutoringCreate>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringCreate>(
    {
      url: `/tutoring/tutoring`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tutoringCreate,
      signal,
    },
    options,
  );
};

export const getTutoringTutoringCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<TutoringCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringTutoringCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringTutoringCreate>>,
    { data: BodyType<TutoringCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringTutoringCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<TutoringCreate> },
    TContext
  >;
};

export type TutoringTutoringCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringCreate>>
>;
export type TutoringTutoringCreateMutationBody = BodyType<TutoringCreate>;
export type TutoringTutoringCreateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터링 갱신
 */
export const useTutoringTutoringCreate = <
  TData = Awaited<ReturnType<typeof tutoringTutoringCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<TutoringCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<TutoringCreate> }, TContext> => {
  const mutationOptions = getTutoringTutoringCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터링 상세 정보
 */
export const tutoringTutoringRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringList>(
    { url: `/tutoring/tutoring/${id}`, method: 'GET', signal },
    options,
  );
};

export const getTutoringTutoringRetrieveQueryKey = (id: number) => {
  return [`/tutoring/tutoring/${id}`] as const;
};

export const getTutoringTutoringRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringTutoringRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringTutoringRetrieve>>> = ({
    signal,
  }) => tutoringTutoringRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTutoringRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringRetrieve>>
>;
export type TutoringTutoringRetrieveQueryError = ErrorType<unknown>;

export function useTutoringTutoringRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터링 상세 정보
 */

export function useTutoringTutoringRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTutoringRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터링 업데이트
 */
export const tutoringTutoringPartialUpdate = (
  id: number,
  patchedTutoring: BodyType<NonReadonly<PatchedTutoring>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TutoringList>(
    {
      url: `/tutoring/tutoring/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutoring,
    },
    options,
  );
};

export const getTutoringTutoringPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTutoring>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['tutoringTutoringPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringTutoringPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedTutoring>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return tutoringTutoringPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTutoring>> },
    TContext
  >;
};

export type TutoringTutoringPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringPartialUpdate>>
>;
export type TutoringTutoringPartialUpdateMutationBody = BodyType<NonReadonly<PatchedTutoring>>;
export type TutoringTutoringPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터링 업데이트
 */
export const useTutoringTutoringPartialUpdate = <
  TData = Awaited<ReturnType<typeof tutoringTutoringPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTutoring>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedTutoring>> },
  TContext
> => {
  const mutationOptions = getTutoringTutoringPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터링 히스토리
 */
export const tutoringTutoringHistoryList = (
  id: number,
  params?: TutoringTutoringHistoryListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringHistory[]>(
    { url: `/tutoring/tutoring/${id}/history`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringTutoringHistoryListQueryKey = (
  id: number,
  params?: TutoringTutoringHistoryListParams,
) => {
  return [`/tutoring/tutoring/${id}/history`, ...(params ? [params] : [])] as const;
};

export const getTutoringTutoringHistoryListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: TutoringTutoringHistoryListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringHistoryList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringTutoringHistoryListQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringTutoringHistoryList>>> = ({
    signal,
  }) => tutoringTutoringHistoryList(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTutoringHistoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringHistoryList>>
>;
export type TutoringTutoringHistoryListQueryError = ErrorType<unknown>;

export function useTutoringTutoringHistoryList<
  TData = Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: undefined | TutoringTutoringHistoryListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringHistoryList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringHistoryList<
  TData = Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: TutoringTutoringHistoryListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringHistoryList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringHistoryList<
  TData = Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: TutoringTutoringHistoryListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringHistoryList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터링 히스토리
 */

export function useTutoringTutoringHistoryList<
  TData = Awaited<ReturnType<typeof tutoringTutoringHistoryList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: TutoringTutoringHistoryListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringHistoryList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTutoringHistoryListQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const tutoringTutoringGroupedByUserList = (
  params?: TutoringTutoringGroupedByUserListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutoringGroupedDataList>(
    { url: `/tutoring/tutoring/grouped_by_user`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringTutoringGroupedByUserListQueryKey = (
  params?: TutoringTutoringGroupedByUserListParams,
) => {
  return [`/tutoring/tutoring/grouped_by_user`, ...(params ? [params] : [])] as const;
};

export const getTutoringTutoringGroupedByUserListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringGroupedByUserListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringTutoringGroupedByUserListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>> = ({
    signal,
  }) => tutoringTutoringGroupedByUserList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTutoringGroupedByUserListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>
>;
export type TutoringTutoringGroupedByUserListQueryError = ErrorType<unknown>;

export function useTutoringTutoringGroupedByUserList<
  TData = Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringTutoringGroupedByUserListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringGroupedByUserList<
  TData = Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringGroupedByUserListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringGroupedByUserList<
  TData = Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringGroupedByUserListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringTutoringGroupedByUserList<
  TData = Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringGroupedByUserListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringGroupedByUserList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTutoringGroupedByUserListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 특정 튜터(user_id)에 배정된 학생 수를 반환합니다.
 * @summary 튜터별 학생 배정 수 조회
 */
export const tutoringTutoringTutorStudentCountRetrieve = (
  params: TutoringTutoringTutorStudentCountRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutoringTutoringTutorStudentCountRetrieve200>(
    { url: `/tutoring/tutoring/tutor_student_count`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringTutoringTutorStudentCountRetrieveQueryKey = (
  params: TutoringTutoringTutorStudentCountRetrieveParams,
) => {
  return [`/tutoring/tutoring/tutor_student_count`, ...(params ? [params] : [])] as const;
};

export const getTutoringTutoringTutorStudentCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTutoringTutorStudentCountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTutoringTutoringTutorStudentCountRetrieveQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>
  > = ({ signal }) => tutoringTutoringTutorStudentCountRetrieve(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTutoringTutorStudentCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>
>;
export type TutoringTutoringTutorStudentCountRetrieveQueryError = ErrorType<unknown>;

export function useTutoringTutoringTutorStudentCountRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTutoringTutorStudentCountRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringTutorStudentCountRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTutoringTutorStudentCountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringTutorStudentCountRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTutoringTutorStudentCountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터별 학생 배정 수 조회
 */

export function useTutoringTutoringTutorStudentCountRetrieve<
  TData = Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: TutoringTutoringTutorStudentCountRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof tutoringTutoringTutorStudentCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTutoringTutorStudentCountRetrieveQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const tutoringTutoringUpcomingList = (
  params?: TutoringTutoringUpcomingListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutoringListList>(
    { url: `/tutoring/tutoring/upcoming`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringTutoringUpcomingListQueryKey = (
  params?: TutoringTutoringUpcomingListParams,
) => {
  return [`/tutoring/tutoring/upcoming`, ...(params ? [params] : [])] as const;
};

export const getTutoringTutoringUpcomingListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringUpcomingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringTutoringUpcomingListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>> = ({
    signal,
  }) => tutoringTutoringUpcomingList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type TutoringTutoringUpcomingListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>
>;
export type TutoringTutoringUpcomingListQueryError = ErrorType<unknown>;

export function useTutoringTutoringUpcomingList<
  TData = Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringTutoringUpcomingListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringUpcomingList<
  TData = Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringUpcomingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useTutoringTutoringUpcomingList<
  TData = Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringUpcomingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useTutoringTutoringUpcomingList<
  TData = Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringTutoringUpcomingListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringTutoringUpcomingList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getTutoringTutoringUpcomingListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const usersStudentList = (
  params?: UsersStudentListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedStudentList>(
    { url: `/users/student`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersStudentListQueryKey = (params?: UsersStudentListParams) => {
  return [`/users/student`, ...(params ? [params] : [])] as const;
};

export const getUsersStudentListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersStudentListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersStudentList>>> = ({ signal }) =>
    usersStudentList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersStudentList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersStudentListQueryResult = NonNullable<Awaited<ReturnType<typeof usersStudentList>>>;
export type UsersStudentListQueryError = ErrorType<unknown>;

export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | UsersStudentListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersStudentListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 특정 학생 프로필 불러오기
 */
export const usersStudentRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Student>({ url: `/users/student/${id}`, method: 'GET', signal }, options);
};

export const getUsersStudentRetrieveQueryKey = (id: number) => {
  return [`/users/student/${id}`] as const;
};

export const getUsersStudentRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersStudentRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersStudentRetrieve>>> = ({ signal }) =>
    usersStudentRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersStudentRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersStudentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersStudentRetrieve>>
>;
export type UsersStudentRetrieveQueryError = ErrorType<unknown>;

export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersStudentRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 특정 학생 프로필 불러오기
 */

export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersStudentRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 학생 프로필 수정
 */
export const usersStudentPartialUpdate = (
  id: number,
  patchedStudent: BodyType<NonReadonly<PatchedStudent>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Student>(
    {
      url: `/users/student/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedStudent,
    },
    options,
  );
};

export const getUsersStudentPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['usersStudentPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return usersStudentPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
    TContext
  >;
};

export type UsersStudentPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersStudentPartialUpdate>>
>;
export type UsersStudentPartialUpdateMutationBody = BodyType<NonReadonly<PatchedStudent>>;
export type UsersStudentPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 학생 프로필 수정
 */
export const useUsersStudentPartialUpdate = <
  TData = Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
  TContext
> => {
  const mutationOptions = getUsersStudentPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 학생 (본인) 프로필 확인
 */
export const usersStudentMeRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Student>({ url: `/users/student/me`, method: 'GET', signal }, options);
};

export const getUsersStudentMeRetrieveQueryKey = () => {
  return [`/users/student/me`] as const;
};

export const getUsersStudentMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersStudentMeRetrieveQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersStudentMeRetrieve>>> = ({ signal }) =>
    usersStudentMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersStudentMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersStudentMeRetrieve>>
>;
export type UsersStudentMeRetrieveQueryError = ErrorType<unknown>;

export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 학생 (본인) 프로필 확인
 */

export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersStudentMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Staff가 관리하는 모든 튜터를 조회합니다.
 * @summary 튜터 목록 조회
 */
export const usersTutorList = (
  params?: UsersTutorListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutorList>(
    { url: `/users/tutor`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersTutorListQueryKey = (params?: UsersTutorListParams) => {
  return [`/users/tutor`, ...(params ? [params] : [])] as const;
};

export const getUsersTutorListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorList>>> = ({ signal }) =>
    usersTutorList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersTutorListQueryResult = NonNullable<Awaited<ReturnType<typeof usersTutorList>>>;
export type UsersTutorListQueryError = ErrorType<unknown>;

export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | UsersTutorListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 목록 조회
 */

export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersTutorListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Staff가 새로운 Tutor 계정을 생성합니다.
 * @summary 튜터 생성
 */
export const usersTutorCreate = (
  tutorCreate: BodyType<TutorCreate>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Tutor>(
    {
      url: `/users/tutor`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tutorCreate,
      signal,
    },
    options,
  );
};

export const getUsersTutorCreateMutationOptions = <
  TData = Awaited<ReturnType<typeof usersTutorCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<TutorCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['usersTutorCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorCreate>>,
    { data: BodyType<TutorCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return usersTutorCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<TutorCreate> },
    TContext
  >;
};

export type UsersTutorCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorCreate>>
>;
export type UsersTutorCreateMutationBody = BodyType<TutorCreate>;
export type UsersTutorCreateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 생성
 */
export const useUsersTutorCreate = <
  TData = Awaited<ReturnType<typeof usersTutorCreate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<TutorCreate> }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { data: BodyType<TutorCreate> }, TContext> => {
  const mutationOptions = getUsersTutorCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usersTutorRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Tutor>({ url: `/users/tutor/${id}`, method: 'GET', signal }, options);
};

export const getUsersTutorRetrieveQueryKey = (id: number) => {
  return [`/users/tutor/${id}`] as const;
};

export const getUsersTutorRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorRetrieve>>> = ({ signal }) =>
    usersTutorRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersTutorRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorRetrieve>>
>;
export type UsersTutorRetrieveQueryError = ErrorType<unknown>;

export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersTutorRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Staff가 Tutor의 정보를 수정합니다.
 * @summary 튜터 수정
 */
export const usersTutorPartialUpdate = (
  id: number,
  patchedTutorUpdate: BodyType<PatchedTutorUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Tutor>(
    {
      url: `/users/tutor/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutorUpdate,
    },
    options,
  );
};

export const getUsersTutorPartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTutorUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['usersTutorPartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
    { id: number; data: BodyType<PatchedTutorUpdate> }
  > = (props) => {
    const { id, data } = props ?? {};

    return usersTutorPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTutorUpdate> },
    TContext
  >;
};

export type UsersTutorPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorPartialUpdate>>
>;
export type UsersTutorPartialUpdateMutationBody = BodyType<PatchedTutorUpdate>;
export type UsersTutorPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 수정
 */
export const useUsersTutorPartialUpdate = <
  TData = Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { id: number; data: BodyType<PatchedTutorUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  { id: number; data: BodyType<PatchedTutorUpdate> },
  TContext
> => {
  const mutationOptions = getUsersTutorPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Staff가 Tutor 계정을 삭제합니다.
 * @summary 튜터 삭제
 */
export const usersTutorDestroy = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/users/tutor/${id}`, method: 'DELETE' }, options);
};

export const getUsersTutorDestroyMutationOptions = <
  TData = Awaited<ReturnType<typeof usersTutorDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['usersTutorDestroy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return usersTutorDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { id: number },
    TContext
  >;
};

export type UsersTutorDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorDestroy>>
>;

export type UsersTutorDestroyMutationError = ErrorType<unknown>;

export const useUsersTutorDestroy = <
  TData = Awaited<ReturnType<typeof usersTutorDestroy>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<TData, TError, { id: number }, TContext> => {
  const mutationOptions = getUsersTutorDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 특정 유저 ID에 해당하는 모든 Booking 리스트를 반환합니다.
 * @summary 특정 튜터의 Booking 리스트 조회
 */
export const usersTutorBookingsList = (
  params: UsersTutorBookingsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedUserAuthDetailList>(
    { url: `/users/tutor/bookings`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersTutorBookingsListQueryKey = (params: UsersTutorBookingsListParams) => {
  return [`/users/tutor/bookings`, ...(params ? [params] : [])] as const;
};

export const getUsersTutorBookingsListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorBookingsList>>,
  TError = ErrorType<unknown>,
>(
  params: UsersTutorBookingsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorBookingsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorBookingsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorBookingsList>>> = ({ signal }) =>
    usersTutorBookingsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorBookingsList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersTutorBookingsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorBookingsList>>
>;
export type UsersTutorBookingsListQueryError = ErrorType<unknown>;

export function useUsersTutorBookingsList<
  TData = Awaited<ReturnType<typeof usersTutorBookingsList>>,
  TError = ErrorType<unknown>,
>(
  params: UsersTutorBookingsListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorBookingsList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorBookingsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorBookingsList<
  TData = Awaited<ReturnType<typeof usersTutorBookingsList>>,
  TError = ErrorType<unknown>,
>(
  params: UsersTutorBookingsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorBookingsList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorBookingsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorBookingsList<
  TData = Awaited<ReturnType<typeof usersTutorBookingsList>>,
  TError = ErrorType<unknown>,
>(
  params: UsersTutorBookingsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorBookingsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 특정 튜터의 Booking 리스트 조회
 */

export function useUsersTutorBookingsList<
  TData = Awaited<ReturnType<typeof usersTutorBookingsList>>,
  TError = ErrorType<unknown>,
>(
  params: UsersTutorBookingsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorBookingsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersTutorBookingsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 검색
 */
export const usersTutorProfileList = (
  params?: UsersTutorProfileListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutorProfileReadList>(
    { url: `/users/tutor_profile`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersTutorProfileListQueryKey = (params?: UsersTutorProfileListParams) => {
  return [`/users/tutor_profile`, ...(params ? [params] : [])] as const;
};

export const getUsersTutorProfileListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorProfileListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorProfileList>>> = ({ signal }) =>
    usersTutorProfileList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorProfileList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersTutorProfileListQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfileList>>
>;
export type UsersTutorProfileListQueryError = ErrorType<unknown>;

export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | UsersTutorProfileListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorProfileList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 검색
 */

export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersTutorProfileListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 프로필 가져오기
 */
export const usersTutorProfileRetrieve = (
  userId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutorProfileRead>(
    { url: `/users/tutor_profile/${userId}`, method: 'GET', signal },
    options,
  );
};

export const getUsersTutorProfileRetrieveQueryKey = (userId: number) => {
  return [`/users/tutor_profile/${userId}`] as const;
};

export const getUsersTutorProfileRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorProfileRetrieveQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>> = ({
    signal,
  }) => usersTutorProfileRetrieve(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersTutorProfileRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfileRetrieve>>
>;
export type UsersTutorProfileRetrieveQueryError = ErrorType<unknown>;

export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 프로필 가져오기
 */

export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersTutorProfileRetrieveQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 프로필 부분 업데이트
 */
export const usersTutorProfilePartialUpdate = (
  userId: number,
  usersTutorProfilePartialUpdateBody:
    | BodyType<PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo>
    | UsersTutorProfilePartialUpdateBodyTwo,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TutorProfileRead>(
    {
      url: `/users/tutor_profile/${userId}`,
      method: 'PATCH',
      data: usersTutorProfilePartialUpdateBody,
    },
    options,
  );
};

export const getUsersTutorProfilePartialUpdateMutationOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      userId: number;
      data: BodyType<PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationKey = ['usersTutorProfilePartialUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
    {
      userId: number;
      data: BodyType<PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo>;
    }
  > = (props) => {
    const { userId, data } = props ?? {};

    return usersTutorProfilePartialUpdate(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      userId: number;
      data: BodyType<PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo>;
    },
    TContext
  >;
};

export type UsersTutorProfilePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>
>;
export type UsersTutorProfilePartialUpdateMutationBody = BodyType<
  PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo
>;
export type UsersTutorProfilePartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 프로필 부분 업데이트
 */
export const useUsersTutorProfilePartialUpdate = <
  TData = Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      userId: number;
      data: BodyType<PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo>;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    userId: number;
    data: BodyType<PatchedTutorProfileUpdate | UsersTutorProfilePartialUpdateBodyTwo>;
  },
  TContext
> => {
  const mutationOptions = getUsersTutorProfilePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터 타입 별 프로필 갯수
 */
export const usersTutorProfileCountRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutorProfileCount>(
    { url: `/users/tutor_profile/count`, method: 'GET', signal },
    options,
  );
};

export const getUsersTutorProfileCountRetrieveQueryKey = () => {
  return [`/users/tutor_profile/count`] as const;
};

export const getUsersTutorProfileCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorProfileCountRetrieveQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>> = ({
    signal,
  }) => usersTutorProfileCountRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type UsersTutorProfileCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>
>;
export type UsersTutorProfileCountRetrieveQueryError = ErrorType<unknown>;

export function useUsersTutorProfileCountRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorProfileCountRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useUsersTutorProfileCountRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary 튜터 타입 별 프로필 갯수
 */

export function useUsersTutorProfileCountRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileCountRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getUsersTutorProfileCountRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
