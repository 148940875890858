import { QuestionSubjectOverview as OrvalQuestionSubjectOverview } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import { CourseRead, toCourseRead } from './course-read';
import { TopicCountWithStatus, toTopicCount } from './topic-count-with-status';

export const QuestionSubjectOverview = zod.object({
  id: zod.number(),
  name: zod.string(),
  courseType: CourseRead,
  topics: zod.array(TopicCountWithStatus),
});

export type QuestionSubjectOverview = zod.infer<typeof QuestionSubjectOverview>;

export const toQuestionSubjectOverview = (
  statusCount: OrvalQuestionSubjectOverview,
): QuestionSubjectOverview => {
  let result;

  const topics = statusCount.topics.map(toTopicCount);
  const courseType = toCourseRead(statusCount.course_type);

  try {
    result = QuestionSubjectOverview.parse({
      id: statusCount.id,
      name: statusCount.name,
      courseType,
      topics,
    });
  } catch (e) {
    console.log('Error parsing question subject overview', e);
    throw e;
  }

  return result;
};
