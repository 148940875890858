/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

export type SubscriptionSubscriptionsListSubscriptionType =
  (typeof SubscriptionSubscriptionsListSubscriptionType)[keyof typeof SubscriptionSubscriptionsListSubscriptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionSubscriptionsListSubscriptionType = {
  QUESTION_BANK: 'QUESTION_BANK',
  TUTORING: 'TUTORING',
} as const;
