import { PaymentMethod as OrvalPaymentMethod } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const PaymentMethod = zod.object({
  brand: zod.string(),
  expMonth: zod.string(),
  expYear: zod.string(),
  last4: zod.string(),
  paymentMethodId: zod.string(),
  subscriptionId: zod.string(),
});

export type PaymentMethod = zod.infer<typeof PaymentMethod>;

export const toPaymentMethod = (qna: OrvalPaymentMethod): PaymentMethod => {
  let result;
  try {
    result = PaymentMethod.parse({
      subscriptionId: qna.subscription_id,
      paymentMethodId: qna.payment_method_id,
      brand: qna.brand,
      last4: qna.last4,
      expMonth: qna.exp_month,
      expYear: qna.exp_year,
    });
  } catch (e) {
    console.log('Error parsing payment method', e);
    throw e;
  }

  return result;
};
