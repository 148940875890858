import { cva } from 'class-variance-authority';

export const textFieldStyle = cva(
  [
    'grow w-full flex justify-between items-center gap-3 px-[14px] py-[11px] bg-bg-white !border-s border-border-inverseTertiary rounded-s !text-b1 text-content-inverseSecondary',
    'hover:text-content-inversePrimary',
    'has-[.text-field:focus]:text-content-inversePrimary has-[.text-field:focus]:!border-border-primary has-[.text-field:focus]:shadow-[0_0_0_4px_#FFEAD5]',
    'has-[.text-field:disabled]:text-content-inverseSecondary has-[.text-field:disabled]:bg-bg-inverseTertiary has-[.text-field:disabled]:border-border-inverseTertiary',
  ],
  {
    variants: {
      error: {
        true: ['border-border-error', 'has-[.text-field:focus]:shadow-[0_0_0_4px_#FEE4E2]'],
      },
    },
  },
);
