import {
  SubscriptionProductsListParams as OrvalSubscriptionProductsListParams,
  SubscriptionProductsListSubscriptionType,
} from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const SubscriptionProductsListParams = zod.object({
  subscriptionType: zod.nativeEnum(SubscriptionProductsListSubscriptionType).optional(),
});

export type SubscriptionProductsListParams = zod.infer<typeof SubscriptionProductsListParams>;

export const toSubscriptionProductsListParams = (
  course: SubscriptionProductsListParams,
): OrvalSubscriptionProductsListParams => {
  const parsed = SubscriptionProductsListParams.parse(course);

  return {
    subscription_type: parsed.subscriptionType,
  };
};
