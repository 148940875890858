import {
  SubscriptionProduct as OrvalSubscriptionProduct,
  IntervalEnum,
  SubscriptionTypeEnum,
} from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const SubscriptionProduct = zod.object({
  description: zod.string().max(255).nullable().optional(),
  id: zod.number().optional(),
  interval: zod.nativeEnum(IntervalEnum).optional(),
  intervalCount: zod.number().optional(),
  name: zod.string().optional(),
  stripePriceId: zod.string().nullable().optional(),
  stripeProductId: zod.string().optional(),
  subscriptionType: zod.nativeEnum(SubscriptionTypeEnum).optional(),
  unitAmount: zod.number().optional(),
});

export type SubscriptionProduct = zod.infer<typeof SubscriptionProduct>;

export const toSubscriptionProduct = (product: OrvalSubscriptionProduct): SubscriptionProduct => {
  let result;
  try {
    result = SubscriptionProduct.parse({
      description: product.description,
      id: product.id,
      interval: product.interval,
      intervalCount: product.interval_count,
      name: product.name,
      stripePriceId: product.stripe_price_id,
      stripeProductId: product.stripe_product_id,
      subscriptionType: product.subscription_type,
      unitAmount: product.unit_amount,
    });
  } catch (e) {
    console.log('Error parsing subscription product', e);
    throw e;
  }

  return result;
};
