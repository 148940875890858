/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `STUDENT` - Student
 * `TUTOR` - Tutor
 * `STAFF` - Staff
 */
export type TutorRoleEnum = (typeof TutorRoleEnum)[keyof typeof TutorRoleEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutorRoleEnum = {
  STUDENT: 'STUDENT',
  TUTOR: 'TUTOR',
  STAFF: 'STAFF',
} as const;
