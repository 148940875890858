import {
  PatchedScheduleWrite,
  toPatchedScheduleWriteParams,
} from 'entity-convert/entity/patched-schedule-write';
import {
  PatchedTimeTrackingWrite,
  toPatchedTimeTrackingWriteParams,
} from 'entity-convert/entity/patched-time-tracking-write';
import { toScheduleRead } from 'entity-convert/entity/schedule-read';
import { ScheduleWrite, toScheduleWriteParams } from 'entity-convert/entity/schedule-write';
import { toTimeTrackingRead } from 'entity-convert/entity/time-tracking-read';
import {
  TimeTrackingWrite,
  toTimeTrackingWriteParams,
} from 'entity-convert/entity/time-tracking-write';
import {
  useScheduleScheduleCreate,
  useScheduleScheduleDestroy,
  useScheduleSchedulePartialUpdate,
  useScheduleTimeTrackingCreate,
  useScheduleTimeTrackingDestroy,
  useScheduleTimeTrackingPartialUpdate,
} from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';

import { useCustomMutation } from '@/operation/mutate';

export const usePatchTimeTracking = () => {
  return useCustomMutation(
    useScheduleTimeTrackingPartialUpdate(),
    toTimeTrackingRead,
    ({ id, data }: { id: number; data: PatchedTimeTrackingWrite }) => ({
      id,
      data: toPatchedTimeTrackingWriteParams(data),
    }),
  );
};

export const usePostTimeTracking = () => {
  return useCustomMutation(
    useScheduleTimeTrackingCreate(),
    toTimeTrackingRead,
    (data: TimeTrackingWrite) => ({
      data: toTimeTrackingWriteParams(data),
    }),
  );
};

export const useDeleteTimeTracking = () => {
  return useCustomMutation(
    useScheduleTimeTrackingDestroy(),
    () => {},
    (id: number) => ({ id }),
  );
};

export const usePatchSchedule = () => {
  return useCustomMutation(
    useScheduleSchedulePartialUpdate(),
    toScheduleRead,
    ({ id, data }: { id: number; data: PatchedScheduleWrite }) => ({
      id,
      data: toPatchedScheduleWriteParams(data),
    }),
  );
};

export const usePostSchedule = () => {
  return useCustomMutation(useScheduleScheduleCreate(), toScheduleRead, (data: ScheduleWrite) => ({
    data: toScheduleWriteParams(data),
  }));
};

export const useDeleteSchedule = () => {
  return useCustomMutation(
    useScheduleScheduleDestroy(),
    () => {},
    (id: number) => ({ id }),
  );
};
