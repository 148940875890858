/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `processing` - Processing
 * `accepted` - Accepted
 * `rejected` - Rejected
 */
export type StatusE8cEnum = (typeof StatusE8cEnum)[keyof typeof StatusE8cEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusE8cEnum = {
  processing: 'processing',
  accepted: 'accepted',
  rejected: 'rejected',
} as const;
