/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `EASY` - Easy
 * `MEDIUM` - Medium
 * `HARD` - Hard
 */
export type DifficultyEnum = (typeof DifficultyEnum)[keyof typeof DifficultyEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DifficultyEnum = {
  EASY: 'EASY',
  MEDIUM: 'MEDIUM',
  HARD: 'HARD',
} as const;
