/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `succeeded` - Succeeded
 * `pending` - Pending
 * `failed` - Failed
 * `requested` - Requested
 */
export type Status0f5Enum = (typeof Status0f5Enum)[keyof typeof Status0f5Enum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status0f5Enum = {
  succeeded: 'succeeded',
  pending: 'pending',
  failed: 'failed',
  requested: 'requested',
} as const;
