/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.1
 */

/**
 * * `Monday` - Monday
 * `Tuesday` - Tuesday
 * `Wednesday` - Wednesday
 * `Thursday` - Thursday
 * `Friday` - Friday
 * `Saturday` - Saturday
 * `Sunday` - Sunday
 */
export type DayOfWeekEnum = (typeof DayOfWeekEnum)[keyof typeof DayOfWeekEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DayOfWeekEnum = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
} as const;
